@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

.euroCurrency {
  background-color: var(--bgMain);

  .headerMainEx {
    position: fixed;
    top: 0;
    background-color: transparent;

    .header {
      max-width: 1364px;
      width: 100%;
      margin: 0 auto;

      .lghtLogo {
        display: none;
      }

      .drkLogo {
        opacity: unset !important;
      }

      .navigationLink li a:hover {
        color: $baseblue !important;
      }

      .menu .item .text {
        color: #fff !important;

        &:hover {
          color: #fff !important;
        }
      }

      .topLeft .r-nav li a:hover {
        color: $baseblue !important;
      }

      .topLeft .loginSec li a:hover {
        color: $baseblue;
      }

      .dropNav .menu .item .text {
        color: #000 !important;
      }

      .dropNav .menu .item {
        &:hover {
          .text {
            color: #fff !important;
          }
        }
      }

      .dropNav {
        .text {
          color: #a5a5ab !important;
        }
      }

      .topLeft .ui.simple.dropdown:hover .text,
      .topLeft .ui.simple.dropdown:hover .icon {
        color: $baseblue;
      }
    }
  }

  .container {
    max-width: 1364px;
    width: 100%;
    margin: auto;
  }

  .euroMultiCurrency {
    background-repeat: no-repeat;
    background-size: cover;
    // background-position: top 150px left -150px, top right;
    // background-image: url("../../Assets/Images/bannerGroupCoin.png");
    background-image: url("../../Assets/Images/Bannerbg.png");

    // min-height: 805px;
    // padding-bottom: 60px;
    // height: 100%;
    height: 600px;
    // padding: 200px 0 50px 0;
    position: relative;
    // z-index: 1;
    border-bottom: 1px solid var(--bannerBorder);
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: "";
      top: 0;
      background-image: radial-gradient(closest-side at 50% 50%,
          rgba(0, 189, 242, 0.8) 0%,
          rgba(6, 18, 34, 0) 100%);
      position: absolute;
      top: 0;
      // background-repeat: no-repeat;
      // background-size: cover;
      width: 340px;
      height: auto;
      z-index: -1;
      background-position: center; // z-index: 1;
    }

    // @media (max-width: 991px) {
    //   padding-top: 50px;
    // }

    @media (max-width: 991px) {
      min-height: auto !important;
      padding-top: 20px;
      height: auto;
    }

    img {
      max-height: 100%;
      max-width: 100%;
    }

    h2 {
      font-weight: 590;
      @include fluid-type(320px, 1920px, 30px, 54px);
    }

    &__Everything {
      display: flex;
      justify-content: center;
      // padding-left: 20px;
      // padding-right: 20px;
      align-items: center;

      @media (max-width: 1399px) {
        padding: 30px;
      }

      @media (max-width: 991px) {
        flex-direction: column;
        padding: 45px 15px 0px 15px;
      }

      &__left {
        width: 50%;

        @media (max-width: 991px) {
          width: 100%;
        }

        .get {
          a {
            font-size: var(--mediumParaGraph);
          }
        }

        // .get {
        //   svg>g>g {
        //     path {
        //       fill: var(--headerText) !important;
        //     }
        //   }
        // }

        .buysell {
          font-weight: 400;
          @include fluid-type(320px, 1920px, 17px, 20px);
          margin-top: 60px;

          @media (max-width: 991px) {
            margin-top: 50px;
          }
        }

        .sell {
          color: #cf304a;
        }

        .buy {
          color: #00dd82;
        }

        .blueTxt {
          color: #00bef2;
        }

        h2 {
          @include fluid-type(320px, 1920px, 26px, 58px);
          color: #fff;
          margin-bottom: 16px;
          opacity: 1;
          line-height: 63px;
          margin-top: 20px;

          @media (max-width: 1199px) {
            margin-bottom: 10px;
          }

          @media (max-width: 991px) {
            max-width: 100%;
            margin-top: 10px;
            line-height: 40px;
          }

          @media (max-width: 767px) {
            line-height: 35px;
          }
        }

        p {
          color: #fff;
          opacity: 1;
          @include fluid-type(320px, 1920px, 14px, 18px);
          // margin-top: 23px;

          @media (max-width: 991px) {
            max-width: 100%;
            margin-top: 15px;
          }

          @media (max-width: 767px) {}
        }

        a {
          max-width: 180px;
          width: 100%;
          padding: 17px 10px;
          background: var(--mainColor);
          border-radius: 4px;
          opacity: 1;
          // border: 1px solid $white;
          color: var(--btnColor);
          font-weight: 600;
          margin-top: 30px;
          display: flex;
          justify-content: center;
          gap: 8px;
          align-items: center;

          @media (max-width: 1199px) {
            padding: 13px 30px;
            white-space: nowrap;
          }

          @media (max-width: 767px) {
            padding: 13px 20px;
          }

          span {
            line-height: 10px;
          }

          svg>g>g>path {
            fill: var(--btnColor) !important;
            stroke: var(--btnColor) !important;
          }
        }
      }

      &__right {
        // max-width: 619px;
        margin: auto;
        // width: 50%;

        @media (max-width: 991px) {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .fundADDImg {
          width: 100%;
          max-width: 800px;

          @media (max-width: 991px) {
            max-width: 500px;
          }

          @media (max-width: 1199px) {
            padding-top: 30px;

            @media (max-width: 991px) {
              padding-top: 0;
              padding-bottom: 40px;
            }
          }

          @media (max-width: 767px) {
            padding-top: 20px;
            padding-bottom: 0px;
          }
        }
      }
    }
  }

  .cryptoChain {
    // margin-top: -194px;
    padding-bottom: 50px;

    @media (max-width: 991px) {
      margin-top: 0px;
    }

    // @media (max-width: 575px) {
    //   margin-top: 50px;
    // }

    &__innerCryptoChain {
      padding: 20px;
      display: flex;
      gap: 25px;
      height: 100%;
      align-items: center;
      flex-direction: column;
      padding-left: 20px;
      background-color: var(--cardlanding);
      // box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.09);
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

      // &_imgOuter {
      //   background-color: #00bdf21a;
      //   border: 1px solid #00bef2;
      //   border-radius: 10px;
      //   width: 70px;
      //   height: 70px;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   @media (max-width: 567px) {
      //     width: 50px;
      //     height: 50px;
      //   }
      // }

      .titledetails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      h3 {
        color: var(--headerText);
        @include fluid-type(320px, 1920px, 16px, 18px);
        margin: 0;
        font-weight: 500;
        line-height: 18px;
        font-family: "F37 Incise", sans-serif;
          word-spacing: normal !important;
        word-spacing: normal;
      }

      p {
        @include fluid-type(320px, 1920px, 14px, 16px);
        color: var(--smalp);
        margin: 0;
        margin-top: 15px;
      }
    }
  }

  .aboutEmperorcoin {
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
      flex-direction: column;
      row-gap: 0px !important;
      // margin: 40px 0;
    }

    &__aboutImg {
      position: relative;
      display: flex;

      img {
        margin: auto;
        z-index: 1;
      }

      .lottieAntimation {
        position: absolute;
        top: 0;
      }
    }

    &__aboutContact {
      h5 {
        color: $baseblue;
        text-transform: uppercase;
        opacity: 0.1;
        @include fluid-type(320px, 1920px, 50px, 100px);
        margin: 0;
      }

      // h2 {
      //   color: #000000;
      //   text-transform: capitalize;
      //   opacity: 1;
      //   @include fluid-type(320px, 1920px, 16px, 18px);
      //   margin-top: -55px;
      // }

      // h4 {
      //   color: $baseblue;
      //   opacity: 1;
      //   @include fluid-type(320px, 1920px, 22px, 44px);
      //   margin: 0;
      // }

      p {
        color: $darktext;
        @include fluid-type(320px, 1920px, 14px, 16px);
        max-width: 682px;
        width: 100%;
        padding-top: 20px;
        margin: 0;

        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .tradeAny {
    background-image: var(--TradeAnywhere);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0;

    @media (max-width: 991px) {
      padding: 30px 0;
    }

    // &_imgBlock {
    //   max-width: 611px;
    //   margin: auto;
    // }

    &_img {
      text-align: center;

      img {
        width: 100%;
        max-width: 557px;

        @media (max-width: 991px) {
          margin-top: 50px;
        }
      }
    }

    h3,
    p {
      color: var(--smalp);
    }

    .links {
      margin-top: 36px;
      display: flex;
      gap: 16px;
    }
  }

  .logoWhite {
    margin-bottom: 25px;

    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }

  .servicesProvide {
    padding: 60px 0;
    background-color: var(--bgMain);

    @media (max-width: 767px) {
      padding: 30px 0;
    }

    .commmonHeading {
      text-align: center;
      padding-bottom: 48px;

      @media (max-width: 767px) {
        padding-bottom: 20px;
      }

      .serviceheading {
        color: var(--headerText);
        word-spacing: normal;
        font-family: "F37 Incise", sans-serif;
          word-spacing: normal !important;
        font-weight: 600;
      }
    }

    // &__serviceHeading {

    //   h2 {
    //     color: $baseblue;
    //     text-transform: uppercase;
    //     opacity: 0.1;
    //     @include fluid-type(320px, 1920px, 50px, 100px);
    //     margin: 0;
    //   }

    //   h3 {
    //     color: $baseblue;
    //     opacity: 1;
    //     @include fluid-type(320px, 1920px, 22px, 44px);
    //     margin: 0;
    //     margin-top: -80px;
    //   }

    //   p {
    //     color: #000000;
    //     @include fluid-type(320px, 1920px, 14px, 16px);
    //     padding-top: 20px;
    //     margin: 0;
    //   }
    // }

    // &__globalSec {
    //   display: flex;
    //   justify-content: center;
    //   gap: 149px;

    //   @media (max-width: 991px) {
    //     gap: 0px;
    //   }

    //   @media (max-width: 767px) {
    //     flex-wrap: wrap;
    //     gap: 10px;
    //   }
    // }

    &__globalSecInner {
      background-color: var(--bgMain);
      border: 1px solid var(--sidebarbrdr);
      opacity: 1;
      width: 100%;
      height: 100%;
      // height: 349px;
      padding: 25px 25px 40px 25px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      // gap: 15px;
      // transition: 0.5s ease-in-out;

      @media (max-width: 767px) {
        max-width: 100%;
        padding: 15px;
        height: auto;
        gap: 0;
      }

      &:hover {
        // box-shadow: 0px 0px 20px #0000001a;
        // border-radius: 4px;
        // opacity: 1;
        background-color: var(--cardHover);
      }

      // .imgDiv {
      //   background-image: linear-gradient(
      //     180deg,
      //     rgba(0, 190, 242, 0.2) 0%,
      //     rgba(0, 141, 180, 0) 100%
      //   );
      //   border-radius: 50%;
      //   opacity: 1;
      //   width: 92px;
      //   height: 92px;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   @media (max-width: 767px) {
      //     width: 60px;
      //     height: 60px;
      //   }

      //   // img {
      //   //   width: 80px;
      //   //   height: 80px;
      //   // }
      // }

      h4 {
        color: var(--headerText);
        font-weight: bold;
        opacity: 1;
        margin-top: 23px;
        padding-bottom: 11px;
        @include fluid-type(320px, 1920px, 16px, 20px);
      }

      p {
        color: var(--smalp);
        @include fluid-type(320px, 1920px, 12px, 14px);
      }
    }
  }
}

.PopularCrypto {
  padding: 100px 0;

  // background-image: url("../../Assets/Images/bgpopular.svg");
  // min-height: 961px;
  @media (max-width: 991px) {
    padding: 30px 0;
    background-repeat: no-repeat;
    min-height: 484px;
  }

  @media (max-width: 590px) {
    background-image: unset;
    // background: $portfoliobg;
  }

  .popularheading {
    text-align: center;
    padding-bottom: 45px;
  }

  h2 {
    padding-bottom: 40px;
  }
}

.commmonHeading {
  h2 {
    color: $baseblue;
    text-transform: uppercase;
    opacity: 0.1;
    @include fluid-type(320px, 1920px, 50px, 100px);
    margin: 0;
    font-weight: 700;
  }

  h3 {
    color: $darktext;
    opacity: 1;
    @include fluid-type(320px, 1920px, 22px, 36px);
    margin: 0;
    font-weight: 600;
    line-height: 41px;

    span {
      color: $baseblue;
    }
  }

  p {
    color: #000000;
    @include fluid-type(320px, 1920px, 14px, 16px);
    padding-top: 20px;
    margin: 0;
    width: 100%;
  }
}

.imgMap {
  @media (max-width: 991px) {
    display: none;
  }
}

.scrollHeader {
  .euroCurrency {
    .headerMainEx {
      background-color: #17121d;
    }
  }
}

.heading-blue-44 {
  @include fluid-type(320px, 1920px, 24px, 44px);
}

/*-------------footer--------*/
.footerKazix {
  background-image: var(--footerBGImage);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 42px;
  padding-bottom: 30px;

  @media (max-width: 767px) {
    padding-top: 30px;
  }

  &__kazixfooter {
    display: flex;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 30px;

    @media (max-width: 767px) {
      padding-bottom: 15px;
    }

    .legal {
      color: var(--headerText);
      @include fluid-type(320px, 1920px, 12px, 14px);
      padding-bottom: 29px;
      padding-top: 20px;
      font-weight: 500;

      @media (max-width: 991px) {
        padding-bottom: 12px;
        padding-top: 12px;
      }
    }

    &__legal {
      a {
        color: var(--smalp);
        @include fluid-type(320px, 1920px, 12px, 14px);
        margin-bottom: 12px;
        display: inline-block;
        // white-space: nowrap;
        font-weight: 500;

        @media (max-width: 991px) {
          margin-bottom: 10px;
        }

        &:hover {
          color: var(--headerText);
        }
      }
    }

    // .subscribe {
    //   color: $white;
    //   @include fluid-type(320px, 1920px, 14px, 16px);
    //   padding-bottom: 20px;
    //   padding-top: 18px;
    // }

    h4 {
      color: var(--btntext);
      @include fluid-type(320px, 1920px, 14px, 17px);
      padding-bottom: 21px;
    }

    .images {
      display: flex;
      gap: 18px;
    }

    ul {
      li {
        .MuiLink-underlineHover:hover {
          text-decoration: none;
        }

        a {
          @include fluid-type(320px, 1920px, 12px, 15px);
          line-height: 29.5px;
          font-weight: 400;
          color: #000000;
        }
      }
    }

    &__kazix {
      img {
        margin-bottom: 25px;

        @media (max-width: 767px) {
          margin-bottom: 15px;
        }
      }

      h4 {
        @include fluid-type(320px, 1920px, 12px, 16px);
        color: var(--headerText);
        padding: 0;
        padding-top: 25px;
      }

      .lorem {
        @include fluid-type(320px, 1920px, 12px, 16px);
        text-align: justify;
        color: var(--smalp);
        max-width: 387px;
      }
    }

    &__company {
      display: flex;
      justify-content: center;

      @media (max-width: 767px) {
        h3 {
          margin-top: 20px;
        }
      }
    }

    >div {
      @media (max-width: 767px) {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    &_midsec {
      display: inline-flex;
      width: min(60%, 448px);

      a {
        color: #000000;
        margin-bottom: 25px;
        display: inline-block;

        @media (max-width: 991px) {
          margin-bottom: 10px;
        }
      }

      &__block {
        flex-basis: 50%;

        &_sec {
          max-width: 126px;
          margin-left: auto;
        }
      }
    }
  }

  .copyRight {
    &__inner {
      display: flex;
      // border-bottom: 1px solid #c0cbcf;
      flex-direction: column-reverse;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
      padding: 20px 0px 33px 0px;

      @media (max-width: 767px) {
        padding: 10px 0px 10px 0px;
      }

      @media (max-width: 500px) {
        justify-content: center;
        gap: 20px;
        flex-flow: wrap-reverse;
      }

      p {
        color: var(--smalp);
        @include fluid-type(320px, 1920px, 10px, 12px);
      }

      &_links {
        display: flex;
        gap: 10px;

        @media (max-width: 767px) {
          gap: 30px;
        }

        a {
          svg {
            path {
              fill: var(--headerText);
            }
          }
        }
      }
    }
  }

  .right {
    align-items: baseline;
    display: inline-flex;
    margin-left: auto;
    width: 100%;
  }
}

.hidden {
  visibility: hidden;

  @media (max-width: 767px) {
    display: none;
  }
}

/*-----header-------*/
header {
  // background-color: #0101014d;
  // opacity: 1;
  // position: fixed;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  transition: 0.3s all;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    @media (max-width: 767px) {
      padding: 10px 0;
    }

    &_links {

      a,
      button {
        color: var(--headerText);
        width: 130px;
        padding: 5px 15px;
        border: 1px solid $white;
        background-color: transparent;
        // border: none;
        border-radius: 8px;
        height: 40px;
        margin-left: 16px;
        font-weight: 500;

        &:hover {
          background: $bgblue;
          font-weight: 500;
          border: 1px solid transparent linear-gradient(20deg,
              rgba(57, 39, 101, 0.7098039216) 0%,
              #00bef2 100%) 0% 0% no-repeat padding-box;
          color: var(--headerText);
          opacity: 0.8;
        }

        &.blueBtn {
          background-color: $baseblue;
          border-radius: 30px;
          padding: 15px 30px;
          color: white;

          &:hover,
          &:active,
          &:focus {
            color: black;
          }
        }
      }
    }

    .navbarfull {
      @media (max-width: 991px) {
        display: none;
      }
    }

    .responsiveSidebar {
      button {
        border-color: #014cec;
        background: #014cec;
      }

      @media (min-width: 992px) {
        display: none;
      }

      .sidebarHeader {
        a {
          padding: 100px;
          display: block !important;
          width: 100%;
        }
      }
    }
  }
}

/*-------ecosystem-sec-----*/
.service {
  text-align: center;
  padding: 100px 0;
  // background-image: url("../../Assets/Images/servicesbg.svg");

  @media (max-width: 991px) {
    padding: 30px 0;
  }

  &__commmonHeading {
    margin-bottom: 100px;

    p {
      color: white;
    }
  }

  .commmonHeading {
    h3+p {
      color: white;
      padding-bottom: 100px;
    }
  }

  .benefithead {
    display: flex;
    align-items: flex-end;
    margin-bottom: 49px;
    gap: 15px;
    // flex-wrap: wrap;
    justify-content: center;

    h3 {
      line-height: 35px;
      min-width: 139px;

      @media (max-width: 590px) {
        line-height: 24px;
      }
    }

    img {
      max-width: 563px;

      @media (max-width: 1399px) {
        display: none;
      }
    }

    .secimg {
      transform: scaleY(-1);
      rotate: 180deg;
    }
  }
}

.joincom {
  padding: 161px 0;

  @media (max-width: 991px) {
    padding: 30px 0;
  }

  .join {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // background-color: ;
    background-image: linear-gradient(180deg, #00bef233 0%, #ffffff00 100%),
      url("../../Assets/Images/Community.png");
    background-size: cover;
    background-position: top right;
    border-radius: 4px;
    box-shadow: 0px 3px 16px #00000017;
    // height: 500px;
    background-repeat: no-repeat;
    padding: 64px 20px;

    @media (max-width: 991px) {
      background: transparent linear-gradient(120deg, #00bef2 0%, #392765 100%) 0% 0% no-repeat padding-box;
    }

    h5 {
      text-align: center;
      @include fluid-type(320px, 1920px, 16px, 28px);
      color: $white;
      font-weight: bold;
    }

    p {
      text-align: center;
      color: $white;
      margin-top: 16px;
      @include fluid-type(320px, 1920px, 14px, 16px);
    }

    .social {
      display: flex;
      justify-content: center;
      gap: 12px;
      margin-top: 36px;

      .socialicon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: #00bef2;
        border-radius: 8px;
      }
    }
  }
}

.toptrader {
  background-image: linear-gradient(118deg,
      rgba(0, 190, 242, 0.15) 0%,
      rgba(0, 141, 180, 0.15) 41%,
      rgba(0, 83, 96, 0.15) 100%);
  // background-image: url("../../Assets/Images/servicebg.png");
  padding: 100px 0;
  // margin-bottom: 49px;
  background-size: cover;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  h3 {
    color: $white;
    text-align: center;
    padding-bottom: 49px;
  }

  .viewmore {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    a {
      color: white;
      max-width: 148px;
      width: 100%;
      height: 50px;
      border-radius: 12px;
      background-image: linear-gradient(122deg,
          rgba(0, 190, 242, 1) 0%,
          rgba(0, 141, 180, 1) 47%,
          rgba(0, 83, 96, 1) 100%);
      display: flex;
      @include fluid-type(320px, 1920px, 14px, 16px);
      justify-content: center;
      align-items: center;
      line-height: 10px;
      gap: 10px;

      &:hover {
        opacity: 0.8;
      }
    }

    svg {
      fill: $baseblue;
    }
  }

  .bravo {
    display: flex;
    justify-content: center;
    gap: 23px;
    // background-image: linear-gradient(180deg, rgba(0, 190, 242, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

    @media (max-width: 991px) {
      flex-wrap: wrap;
    }

    .bravofirst {
      background-image: linear-gradient(180deg,
          rgba(0, 190, 242, 0.2) 0%,
          rgba(255, 255, 255, 0) 100%),
        url("../../Assets/Images/graph.svg");
      background-position: right top, bottom right;
      background-repeat: no-repeat;
      box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.09);
      border-radius: 4px;
      padding: 36px;
      max-width: 468px;
      width: 100%;
      // opacity: 0.3;
      min-height: 460px;
      transition: 0.3s all;

      h4 {
        margin-top: 17px;
        color: var(--btntext);
        font-weight: 600;
        @include fluid-type(320px, 1920px, 14px, 20px);
      }

      ul {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-left: 0;
        margin-bottom: 0;

        li {
          @include fluid-type(320px, 1920px, 14px, 16px);
          font-weight: 600;
          list-style: none;

          &:first-child {
            color: rgba(255, 255, 255, 0.7);
            @include fluid-type(320px, 1920px, 14px, 16px);
          }

          &:nth-child(2) {
            color: white;
            font-weight: 600;
            @include fluid-type(320px, 1920px, 18px, 24px);
          }
        }
      }

      p {
        @include fluid-type(320px, 1920px, 12px, 14px);
        font-weight: 600;
        display: flex;
        align-items: center;
        color: white;
        gap: 5px;
      }

      &:hover {
        opacity: 1;
        transform: scale(1.1);
        margin: 0 20px;
      }
    }
  }
}

.mb24 {
  margin-bottom: 22px;
}

/*--------ecoCard-------*/
.ecocardGroup {
  background-image: linear-gradient(107deg,
      #00bef2 0%,
      #008db4 41%,
      #005360 100%);
  border-radius: 4px;

  &_row {
    &_col {
      &:last-child {
        .ecoCard {
          border: none;
        }
      }
    }
  }

  .ecoCard {
    text-align: center;
    padding: 36px 30px;
    height: 100%;
    min-height: 287px;
    border-radius: 4px;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-image: linear-gradient(to bottom,
        rgba(0, 0, 0, 0),
        rgba(255, 255, 255, 1),
        rgba(0, 0, 0, 0)) 1 100%;

    @media (max-width: 575px) {
      border: none;
    }

    &_imgouter {
      border: 6px solid rgba(6, 18, 32, 1);
      width: 100px;
      height: 100px;
      margin-top: -100px;
      border-radius: 12px;
      background-image: linear-gradient(151deg,
          #00bef2 0%,
          #008db4 41%,
          #005360 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -100px auto 30px auto;

      @media (max-width: 575px) {
        margin: 20px auto 20px auto;
      }
    }

    h4 {
      color: var(--btntext);

      @include fluid-type(320px, 1920px, 14px, 24px);
      font-weight: 600;
      color: $white;
      padding: 16px 0 12px 0;
      line-height: 22px;
    }

    p {
      color: $white;
      @include fluid-type(320px, 1920px, 12px, 15px);
    }
  }
}

.landingImgLogo {
  width: 100px;

  @media (max-width: 480px) {
    max-width: 75px;
    width: 100%;
  }
}

.build {
  font-family: "BL Arctic", sans-serif;
  font-weight: 500;
}

.withGems {
  font-family: "F37 Incise", sans-serif;
    word-spacing: normal !important;
  word-spacing: normal;
  font-weight: 600;
}

// ..........................................

.numberSec {
  display: flex;
  gap: 50px;

  h4 {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--headerText);
    @include fluid-type(320px, 1920px, 12px, 14px);
    font-weight: 400;

    svg {
      path {
        fill: var(--headerText);
      }
    }
  }
}