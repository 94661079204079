@import "../../../../../Assets/Styles/_mixins.scss";
@import "../../../../../Assets/Styles/_vars.scss";

.addTableItems {
  thead {
    th {
      color: $grayLightTxt !important;

      &::before {
        display: none;
      }

      p {
        color: $grayLightTxt;
      }
    }
  }

  p,
  h3 {
    margin: 0px;
    color: var(--headerText);
    font-size: 14px;
  }

  h4 {
    color: var(--headerText);
    font-size: 14px;
  }

  p {
    color: var(--headerText);
    font-weight: 600;
    font-size: 14px;
  }

  span {
    color: $lightgreen;
  }

  .offline {
    p {
      color: $reddata;
    }
  }

  &__onlineStatus {
    p {
      color: $baseblue;
    }
  }

  &__ToNavigate {
    text-align: center;
    margin: auto;
    margin-left: 15px;
    width: 16px;

    .trashicon {
      height: 15px !important;
    }
  }

  &__checkWithusdt {
    display: flex;
  }

  .viewnevigation {
    margin-left: 15px;
    color: #848e9c;
    font-weight: bold;
    border: 0px;
    background-color: transparent;
    cursor: pointer;
  }
}
