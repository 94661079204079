@import "../../../../../Assets/Styles/_vars.scss";
@import "../../../../../Assets/Styles/_mixins.scss";

.beforeAppealing {
  &__Des {
    font-weight: 700;
    text-align: left !important;
    @include fluid-type(320px, 1920px, 18px, 22px);
    color: var(--light-text);
    margin-bottom: 15px !important;
  }
}

.selectPay {
  &__Des {
    font-weight: 700;
    text-align: center;
    @include fluid-type(320px, 1920px, 18px, 22px);
    color: var(--light-text);
  }

  &__Tips {
    li {
      border: 0 !important;
      border-bottom: 1px solid var(--borderclr) !important;

      p {
        @include fluid-type(320px, 1920px, 12px, 16px);
        font-weight: 600;
      }
    }

    .paymentIcon {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      padding-bottom: 14px;

      .fa-angle-right {
        color: var(--headerText);
        font-weight: 600;
      }
    }
  }

  .imageandPay {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.countryPhone {
  display: flex;
  align-self: center;
  gap: 20px;
  padding-top: 10px;

  .countryCod {
    max-width: 20%;
    width: 100%;
  }

  .phoneno {
    max-width: 80% !important;
    width: 100%;
  }

  .input_textimg input {
    margin-top: 0 !important;
  }

  .input_textimg {
    margin-bottom: 20px;
    width: 100% !important;
  }
}

.labelData {
  color: var(--headerText);
}

.optionalDiv {
  color: var(--headerText);
  margin-bottom: 0;
}

.abcFiler {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  list-style: none;
  padding-left: 0;
}

.abcFiler li {
  color: #00a79e;
  font-weight: bold;
  box-shadow: none;
  cursor: pointer;
  font-size: 12px;

  span {
    padding: 1px 4px;
    user-select: none;
  }
}

.customFilterAphabets {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 575px) {
    justify-content: center;
    gap: 20px;
  }

  .paymentMethod {
    color: var(--light-text) !important;
  }

  input {
    border-radius: 8px;
    background-color: var(--homeCard);
    border: 1px solid var(--borderclr);
    color: var(--light-text) !important;
    padding: 6px 10px;
    outline: none;
  }
}

.abcFiler .active {
  background: #00a79e;
  color: #fff;
}

.beforeAppealing__Tips.selectPay__Tips {
  height: 400px;
  overflow: auto;
  margin-top: 20px;

  .noRec {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0 !important;

    p {
      text-align: center;
    }
  }

  i {
    display: none;
  }
}

.bankTransfer__input .ant-form-item {
  margin-bottom: 0;
}

.bankTransfer__input {
  margin: 10px 0;

  span {
    color: var(--headerText);
  }
}

.bankTransfer__buttons {
  gap: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.theme-dark .customPayLab label {
  color: #fff;
}

.customPayLab label {
  // text-transform: capitalize;
}

.theme-dark {
  .noRec {
    color: #fff;
  }

  .bankTransfer h2 {
    color: #fff;
    text-transform: capitalize;

  }
}