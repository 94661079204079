.multiSelect {
  .ant-select-multiple {
    &::after {
      position: absolute;
      content: "";
      height: 40px;
      width: 40px;
      visibility: visible;
    }

    .ant-select-selection-item {
      background-color: var(--hoverWhite) !important;
    }
  }

  .ant-select-selector {
    padding: 8px 11px !important;
    border-radius: 8px !important;
    background-color: var(--homeCard) !important;
    border: 1px solid;
    border-color: var(--borderclr) !important;

    &:hover {
      border-color: #1ea0ab !important;
    }
  }

  .ant-select-selection-overflow-item {
    display: flex;
    flex-wrap: wrap;
  }
}