@import "../../../../../Assets/Styles/_vars.scss";
@import "../../../../../Assets/Styles/_mixins.scss";

.addTable {
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 0px 2px var(--borderclr);
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 30px;

  &__content {
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--tabBgLayout);
      padding: 10px 20px;
      border-radius: 5px 5px 0px 0px;

      @media (max-width: 479px) {
        flex-direction: column;
      }

      .leftContent {
        h2 {
          border-left: 5px solid $baseblue;
          line-height: 18px;
          padding-left: 5px;
          margin-bottom: 0px;
          color: var(--headerText);
          @include fluid-type(320px, 1920px, 14px, 18px);
        }

        @media (max-width: 479px) {
          margin-bottom: 20px;
        }
      }

      .editDelete {
        button {
          border: 1px solid $baseblue;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
          background-color: $baseblue;
          color: $white;
          padding: 8px 15px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

          &:nth-child(2) {
            background-color: #f3f3f3;
            border-color: #f3f3f3;
            color: #000;
          }

          @media (max-width: 479px) {
            margin-bottom: 10px;
            width: 100%;
          }
        }
      }
    }

    &__lists {
      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-left: 0;

        li {
          list-style: none;
          display: flex;
          width: 100%;
          gap: 20px;

          p {
            @include fluid-type(320px, 1920px, 12px, 14px);
            margin-bottom: 5px;
            color: var(--smalp);
            width: 35%;
            white-space: nowrap;
          }

          h4 {
            color: var(--headerText);
            @include fluid-type(320px, 1920px, 14px, 16px);
            word-wrap: break-word;
          }
        }
      }

      .active_smsGoogle {
        display: flex;
        justify-content: space-around;
        position: relative;

        p {
          margin-bottom: 1em;
          width: 25%;
          text-align: center;
          cursor: pointer;
          color: var(--headerText);
        }

        .activeCls {
          position: relative;
          color: var(--headerText);

          &::after {
            position: absolute;
            content: "";
            background-color: $baseblue;
            width: 100%;
            height: 3px;
            left: 0;
            top: 20px;
          }
        }
      }

      .submit_btn {
        margin-top: 20px;
      }

      .otp_Google {
        position: relative;

        &__enterCode {
          position: absolute;
          right: 10px;
          cursor: pointer;
          color: var(--smalp);
        }
      }
    }

    &__Acoundholder {
      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 10px 25px;
        width: 100%;

        li {
          list-style: none;
          margin-right: 10px;

          p {
            @include fluid-type(320px, 1920px, 12px, 14px);
            margin-bottom: 5px;
            color: var(--smalp);
            width: 35%;
            white-space: nowrap;
          }

          h4 {
            color: var(--headerText);
            @include fluid-type(320px, 1920px, 14px, 16px);
            word-wrap: break-word;
          }
        }
      }
    }
  }
}

.confirmBtnCls {
  display: flex;
  gap: 30px;
}

.noCurser {
  cursor: auto !important;
}

.detailsinActive {
  h3 {
    margin-bottom: 10px;
  }

  .active_smsGoogle {
    display: flex;
    justify-content: space-around;
    position: relative;

    p {
      width: 25%;
      text-align: center;
      cursor: pointer;
      color: var(--headerText);
    }

    .activeCls {
      position: relative;

      &::after {
        position: absolute;
        content: "";
        background-color: $yellowtbdark;
        width: 100%;
        height: 3px;
        left: 0;
        top: 20px;
      }
    }
  }

  .otp_Google {
    margin: 20px 0px;
  }

  .otp_Google__enterCode {
    position: absolute;
    right: 12px;
    // bottom: 90px;
    color: var(--whiteblack);
    cursor: pointer;
  }
}

.showBigImage {
  cursor: pointer;
  height: 30px;
  object-fit: cover;
  width: 100%;
  max-width: 40px;
}

.disMethod {
  font-size: 14px;
  margin-left: 10px;
}

.diBtn {
  pointer-events: none;
  background-color: #ccc !important;
  border-color: #ccc !important;
}
