@import "../../../../Assets/Styles/_mixins.scss";
@import "../../../../Assets/Styles/_vars.scss";

.chatSec {
  display: flex;
  gap: 50px;
  padding-top: 50px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  @media (max-width: 1490px) {
    flex-direction: column;
    justify-content: center;
  }

  .progressBar {
    padding-top: 20px;
    max-width: 891px;
    width: 100%;
    padding: 30px 60px;

    @media (max-width: 1490px) {
      margin: auto;
    }

    @media (max-width: 1600px) {
      padding: 20px;
    }

    @media (max-width: 575px) {
      padding: 20px;
    }
  }

  .removePdChild {
    .apealpanding {
      padding-top: 0px;

      h3 {
        margin-bottom: 10px !important;
      }
    }

    .appealClose {
      display: flex;
      gap: 12px;

      h3 {
        color: var(--whiteblack);
      }
    }
  }

  .apeal__histry {
    .ant-collapse {
      @media (max-width: 500px) {
        padding: 0 !important;
      }
    }

    .ant-collapse-icon-position-start {
      .ant-collapse-expand-icon {
        .anicon {
          svg {
            color: var(--whiteblack) !important;
          }
        }
      }
    }

    .ant-collapse {
      background: var(--homeCard) !important;
    }

    .label__butonTimer {
      display: flex;
      gap: 20px;
      margin: 0px;
      padding-top: 30px;

      button {
        p {
          margin: 0px;
        }
      }

      .ancrType {
        color: $yellowtbdark;
        cursor: pointer;
        @include fluid-type(320px, 1920px, 14px, 18px);

        &:hover {
          color: $CommonButton;
        }
      }
    }

    .img_apeal__histry {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      img {
        height: 75px;
        width: 75px;
        object-fit: contain;
        cursor: pointer;
      }
    }

    .ant-collapse-header-text {
      width: 100%;

      .heading_apealstatus {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          color: var(--whiteblack);
        }

        .bluetext {
          color: $baseblue;
          font-weight: 700;
          margin-right: 6px;
          font-size: 20px;
          display: inline;
        }

        .appeal-status {
          p {
            color: white;
            padding: 4px;
            font-size: 13px;
            margin: 0px;
            border-radius: 4px;
          }
        }
      }
    }

    .ant-collapse {
      padding: 20px;
      max-height: 500px;
      overflow-y: scroll;
      border-radius: 10px;
    }

    .ant-collapse-header-text {
      font-weight: 600;
      @include fluid-type(320px, 1920px, 14px, 16px);
      color: var(headerText);
    }

    .initialName {
      display: flex;
      align-items: center;

      .firstletter {
        font-size: 22px;
        background-color: #1ea0ab;
        border-radius: 30px;
        width: 40px;
        height: 40px;
        color: #fff;
        display: flex;
        margin: 0px;
        justify-content: center;
        margin-right: 12px;
        align-items: center;
      }

      img {
        font-size: 22px;
        color: #fff;
        display: flex;
        margin: 0px;
        justify-content: center;
        margin-right: 12px;
        align-items: center;
      }

      .whitelogo {
        height: 30px;
      }

      .darklogoApeal {
        height: 20px;
        width: 95px;
      }

      p {
        margin: 0px;
        font-weight: 600;
        @include fluid-type(320px, 1920px, 12px, 16px);
      }
    }

    .ant-collapse-item {
      margin-bottom: 20px;
    }

    .resondesc {
      color: var(--whiteblack);

      p {
        margin: 0px;
        word-break: break-all;
      }
    }

    .dateapeal {
      margin: 0px;
      padding: 0px 20px;
      color: var(--whiteblack);
    }

    button {
      max-width: 300px;
    }

    svg {
      color: var(--whiteblack);
    }

    .ant-collapse-content-box {
      p {
        color: var(--whiteblack);
      }
    }
  }

  .apealpanding {
    padding-top: 30px;
    color: var(--whiteblack);

    h3 {
      color: var(--whiteblack);
    }

    button {
      max-width: 300px;
      margin-bottom: 30px;

      .label_timer {
        display: flex;
        align-items: center;
        margin: 0px;

        div {
          margin-left: 2px;
        }
      }
    }

    .timercunter {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      p {
        margin: 0px;
      }
    }

    .btnuploaddocumnent {
      border: 1px solid;
      max-width: 150px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;
      margin: 20px 0px;
      padding: 5px 10px;
    }

    ol {
      line-height: 28px;
      padding-left: 15px;

      li {
        @include fluid-type(320px, 1920px, 12px, 14px);
      }
    }

    .yellowtext {
      margin-top: 10px;
      color: $yellowtbdark;
    }
  }

  .paidhistrytxt {
    margin: 10px 0px 10px 0px;
    color: var(--whiteblack);
    @include fluid-type(320px, 1920px, 12px, 14px);
  }

  .cancelalertopen {
    cursor: pointer;
  }

  .apBtns {
    margin-top: 20px;

    button {
      padding: 4px 15px 4px 0px;
      background: transparent;
      box-shadow: none;
      border: 1px solid $lightgreytext;
      border-radius: 50px;
      cursor: pointer;

      img {
        margin-left: 5px;
      }
    }
  }

  .negBtn {
    color: red;
  }

  .conBtn {
    color: green;
    margin-left: 20px;
  }

  #disThisBtn {
    cursor: not-allowed;
  }

  .mrtop {
    margin-top: 15px;
  }

  .mtbtom {
    margin-bottom: 15px;
  }
}

.paymentIn_history {
  .paidhistrytxt {
    margin-top: 15px;
  }

  .paymentMethod {
    border: 1px solid var(--borderclr);
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .leftbordert {
      padding-left: 5px;
      border-left: 4px solid $yellowDarkBg;
      // line-height: 14px;
      margin-bottom: 0px;
      margin-left: 10px;
    }

    .rdioupipayment {
      max-width: 291px;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: var(--settingCard);
      border-right: 1px solid $lightgreytext;

      @media (max-width: 767px) {
        width: 100%;
        max-width: unset;
      }

      p {
        color: var(--whiteblack);
      }
    }

    .upiInfo {
      width: 100%;
      padding: 23px;

      ul {
        list-style: none;
        padding: 0px;
      }

      P {
        margin: 0px;
        color: $texttimerinner;
        @include fluid-type(320px, 1920px, 12px, 12px);
      }

      .balemyicon {
        display: flex;
        align-items: center;

        svg {
          height: 10px;
          width: 10px;
          margin-left: 5px;
          cursor: pointer;
        }
      }

      h4 {
        @include fluid-type(320px, 1920px, 12px, 14px);
        color: $texttimerheader;
        margin-right: 10px;
      }
    }
  }
}

.makeCenter {
  align-items: center;
  color: var(--whiteblack);

  .progress__Second {
    button {
      margin-bottom: 0px;
    }
  }

  .ancrType {
    margin-bottom: 0px;
  }
}

.cstmArea {
  margin-bottom: 35px;

  .text_AreaCustom {
    margin-top: 0px;
  }

  p {
    text-align: right;
  }

  .appeal-images.pdf {
    border: 1px solid #d07d7d;
  }
}

.ancrType.true {
  pointer-events: none;
}

.pendingStatus {
  display: flex;
  justify-content: flex-end;

  p {
    background: red;
    border-radius: 4px;
    color: #fff;
    font-size: 13px;
    margin-bottom: 8px;
    padding: 4px 10px;
  }
}

.cusSupportSec {
  padding: 0 20px;
}

.activeClass {
  display: block;
}

.unactiveClass {
  display: none;
}

.faIcon.fa.fa-sort-down {
  position: relative;
  bottom: 3px;
  margin-left: 3px;
}

.faIcon.fa.fa-sort-up {
  position: relative;
  top: 3px;
  margin-left: 3px;
}

.cusSupportSec h4 {
  cursor: pointer;
}

.customerSImg {
  margin-right: 7px;
  position: relative;
  bottom: 2px;
}

.res-cancel,
.res-unresolved {
  background: red !important;
}

.res-resolved {
  background: Green !important;
}

.res-pending,
.res-closed {
  background: #1ea0ab !important;
}

.ancrType a {
  color: #014cec !important;
}

.resoInitialName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--whiteblack);
  margin-bottom: 15px;
}

.tokenResodesc {
  p {
    @include fluid-type(320px, 1920px, 12px, 14px);
    font-weight: 600;
  }
}

.heading_apealstatus.extra-messages {
  padding: 20px;
  border-bottom: 1px solid #00000011;

  .extra-messages .dateapeal {
    text-align: center;
    padding: 15px 0 !important;
  }

  .autoMess {
    background: var(--homeCard);
    color: var(--whiteblack);
    padding: 10px 10px;
  }

  .dateapeal {
    margin: 5px 0px;
    padding: 0px 0px;
  }
}

.extraDate {
  margin-top: 20px;
}

.timer_appeal {
  display: flex;
  gap: 10px;
}

.chatSec .apeal__histry button {
  max-width: 220px;
}

.consensesDiv {
  margin-top: 15px;

  p {
    color: red;
    margin-bottom: 10px;

    img {
      margin-right: 3px;
    }
  }

  .btn_group {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.theme-light {
  .whitelogo {
    display: none !important;
  }

  .darklogoApeal {
    display: block !important;
  }
}

.theme-dark {
  .darklogoApeal {
    display: none !important;
  }
}

.apeal__histry .ant-collapse-header {
  padding-bottom: 0px !important;
}
