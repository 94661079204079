@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=BL+Arctic&display=swap");
@import "./Components/Assets/Styles/_mixins.scss";
@import "./Components/Assets/Styles/_vars.scss";

@import "./Components/Pages/P2P/Pages/Components/PaymentMethods/SelectPayment.scss";
@import "./Components/Pages/P2P/Pages/Components/paymentStatus/BuySellLocally.scss";
@import "./Components/Pages/P2P/Pages/Appeal/appeal.scss";
@import "./Components/Pages/P2P/Pages/Buysellorderinfo/Buysellorderinfo.scss";
@import "./Components/Pages/P2P/Pages/Components/tablelanding/tablelanding.scss";
@import "./Components/Pages/P2P/Pages/MarketUpdate/Market.scss";
@import "./Components/Pages/P2P/Pages/Paymentmethod/index.scss";
@import "./Components/Pages/P2P/Pages/Components/tableitems.scss";
@import "./Components/Pages/P2P/Pages/Components/collapseitems/collapseitems.scss";
@import "./Components/Pages/P2P/Pages/Components/MultipleSelect/MultipleSelect.scss";
@import "./Components/Pages/P2P/ReportModal/ReportModal.scss";
@import "./Components/Pages/P2P/Pages/MarketUpdate/ProfileCompletion.scss";
@import "./Components/Pages/P2P/Pages/Components/PaymentMethodCard/PaymentMethodCard.scss";
@import "./Components/Pages/P2P/Pages/Components/MultipleSelect/MultipleSelect.scss";
@import "./Components/Pages/P2P/Pages/Appeal/appeal.scss";
@import "./Components/Common/Identityverify/style.module.scss";
@import "./Components/Pages/P2P/Pages/Components/howtoworks/Howtoworks.scss";
@import "./Components/Pages/P2P/Pages/Components/Orders/FiatOrder.scss";
@import "./Components/Pages/P2P/Pages/Components/MyaddTable/MyaddTable.scss";

@font-face {
  font-family: "F37 Incise";
  src: url("./Components/Assets/helvetica-now-display/F37Incise-Medium.ttf") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BL Arctic";
  src: url("./Components/Assets/helvetica-now-display/BL Arctic.otf") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.theme-dark {
  --bguseravitr: #e2f1f8;
  --bordertbl: #122429;
  --headtable: #0b1b20;
  --dashtoggle: #30f1b6;
  --privacybackground: url("./Components/Assets/Images/privacydarkbg.png");
  --privacyboldText: #d1dadd;
  --privacyText: #939fa3;
  --privacyborder: #2d4850;
  --clrbtngreenWhite: #021c24;
  --privacyHeader: #08151933;
  --clrbtnredWhite: #ffffff;
  --btnredact: #fc4f52;
  --btnbgclr: #30f1b6;
  --brderleft: #2b3e43;
  --bgingput: #0a2931;
  --inputplceholder: #d1dbdf;
  --text-newclr: rgba(147, 159, 163, 1);
  --sendRecev: rgba(10, 41, 49, 1);
  --bglight: rgba(43, 62, 67, 1);
  --shadowslider: 0px 0px 7.78px 1.56px rgba(255, 255, 255, 0.25);
  --colorshadow: rgba(209, 218, 221, 1);
  --lightdarkbtn: rgba(48, 241, 182, 1);
  --colirbtndiff: rgba(209, 218, 221, 1);
  --colirrevdiff: rgba(2, 23, 29, 1);
  --btnthmnew: rgba(4, 35, 44, 1);
  --btndiffrent: rgb(10, 41, 49);
  --inputcolors: rgba(255, 255, 255, 0.7);
  --exchngtitle: rgba(2, 28, 36, 1);
  --inputtext: rgba(255, 255, 255, 0.7);
  --dark-text: #292929;
  --light-text: #f9f8f8;
  --dark-background: #0f2237;
  --light-background: #586f7c;
  --accent: #b8dbd9;
  --tabBtnBg: #0a2931;
  --themebgbtn: transparent linear-gradient(113deg, #00bef2 0%, #008db4 47%, #005360 100%) 0% 0% no-repeat padding-box;
  --button-border: #b8dbd9;
  --main-bg: #030303;
  --sidebar-bg: #021c244d;
  --selcted-Tab: transparent linear-gradient(108deg, #00bef2 0%, #008db4 32%, #045168 100%) 0% 0% no-repeat padding-box;
  --headerText: #d1dadd;
  --headerText1: #ffffff;
  --accordionHeaderbg: #192733;
  --sidebariconbg: #fff;
  --bordergreen: #1ea0ab;
  --themegraybtn: #1f2638;
  --tradebg: #021c24;
  --btntext: #ffffff;
  --emptytext: #ffffff;
  --settingCard: #18384e;
  --setingCardBg: #021c24;
  --bordercolor: #ffffff33;
  --homeCard: #111624;
  --whiteblack: #fff;
  --smalp: #939fa3;
  --bgusdtcard: #18384e;
  --gridCard: #021c24;
  --collpasedata: #062129;
  --accordcontent: #141c2b;
  --cardBackground: #021c24;
  --textColor-80839E: #838990;
  --chartCardBG: #111624;
  --hoverWhite: #f3f2f636;
  --shadowWhite: 0 3px 6px -4px rgba(26, 25, 25, 0.12),
    0 6px 16px 0 rgba(145, 133, 133, 0.08),
    0 9px 28px 8px rgba(176, 169, 169, 0.05);
  --shadowifram: 0 3px 6px -4px rgba(135, 131, 131, 0.12),
    0 6px 16px 0 rgba(145, 133, 133, 0.08),
    0 9px 28px 8px rgba(237, 232, 232, 0.1);
  --modalBg: rgba(2, 24, 31, 1);
  --cardbgDCEAEF: #18384e;
  --sidebarborder: #4d4c4c4f;
  --tabletext: #fcfcfc;
  --bankcardBg: rgba(31, 30, 30, 0.5);
  --searchinputclr: #1f2638;
  --borderclr: #ffffff33;
  --sidebarbrdr: #2b3e43;
  --pricevolumeBG: #18384e;
  --cardsec: #1f2638;
  --shadow: 0px 0px 12px #ffffff12;
  --innerCard: #0a2931;
  --innerCardBorder: #486d87;
  --currencyInput: #18384e;
  --createAccount: #0a2931;
  --accountText: #ffffff;
  --loginBg: #061220;
  --cardBorder: rgba(0, 190, 242, 0);
  --seetingCardBg: #0f2237;
  --tabBgLayout: #134058;
  --footerBGImage: url("./Components/Assets/Images/FooterBgDark.png");
  $bordredImg: #dbdbdb;
  --custmgren: #1ea0ab;
  --greytabitme: #8f8f8f;
  --btnBaseColor: #0b0e11;
  --texttimerinner: #848e9c;
  --lightgreytexting: #b4b4b4;
  --workBg: transparent linear-gradient(180deg, #00bef233 0%, #ffffff00 100%) 0% 0% no-repeat padding-box;
  --accordBg: #18384e;
  --borderclr: #ffffff33;
  --tabBgLayout: #134058;
  --modalbg: rgba(2, 24, 31, 1);
  --blueLinearBg: #1f2638;
  --resetBtn: #134058;
  --resetBtnBorder: #b8dbd969;
  --modalBg: rgba(2, 24, 31, 1);
  --faqupdatebg: transparent linear-gradient(179deg, #00bef233 0%, #ffffff00 100%) 0% 0% no-repeat padding-box;
  --bgMain: #081519;
  --whiteblack: #000;
  --itemselected: #30f1b60d;
  --itemselectedBorder: #30f1b6;
  --mainColor: #30f1b6;
  --sidebarColor: #d1dadd;
  --tableHead: #0a2931;
  --tabHover: #0a2931;
  --daysBg: #04232c;
  --tabMainBg: #021c24;
  --tdColor: #d1dadd;
  --btnColor: #021c24;
  --profileBG: #0a2931;
  --portfolioText: #d1dadd;
  --textsubTitle: #939fa3;
  --tabsBg: #04232c;
  --tabsBgActive: #0a2931;
  --tabsBgActiveColor: #30f1b6;
  --inputColor: #465e65;
  --referralBg: #021c24;
  --referralList: #0a2931;
  --datePickerBg: #0a2931;
  --imgBg: #052832;
  --landingWhite: #ffffff;
  --cardlanding: #0a2327;
  --bannerBorder: #2a4044;
  --OurHistoryBg: url("./Components/Assets/Images/OurHistoryBg.png");
  --cardHover: #091a1f;
  --TradeAnywhere: url("./Components/Assets/Images/TradeAnywhereDark .png");
  --Header-sc: #141c2b;
  --borderYellow: #fcd44f;
  --yellowFadeBg: #fcd44f05;
  --transactionstabsBg: #04232c;
  --Searchicon: #d1dbdf;
  --darkLight: #fff;
  --progressLine: #072a34;
  --privacybg: #081519;
  --tbbgdark: #09181d;
  --inputnew: #092832;
  --bgaboutsec: url("./Components/Assets/Images/bgtransparent.png");
  --brd_diff: #142125;
  --uniqesec: rgba(9, 24, 29, 0.97);
  --dropdownbg: #0a2931;
  --arrowright: url("./Components/Assets/Images/calenderarrowlight.png");
  --paginationleftdisable: url("./Components/Assets/Images/paginationleft.png");
  --paginationactive: url("./Components/Assets/Images/darkthemearrow.png");
  --feeSub: #1a1e1b;
}

.theme-light {
  --bguseravitr: #77868b;
  --bordertbl: #77868b;
  --headtable: #e6f0f5;
  --dashtoggle: #329879;
  --privacybackground: url("./Components/Assets/Images/privacylightbg.png");
  --privacyboldText: #021c24;
  --privacyText: #3b4a50;
  --privacyborder: #8eaab7;
  --privacyHeader: #e2e9ec33;
  --privacybg: #cdd7da;
  --clrbtngreenWhite: #329879;
  --clrbtnredWhite: #fc4f52;
  --btnredact: #fc4f524d;
  --btnbgclr: #30f1b64d;
  --brderleft: #c0cbcf;
  --bgingput: #d1dbdf;
  --inputplceholder: #77868b;
  --text-newclr: var(--Secondary-Text-Color, rgba(119, 134, 139, 1));
  --sendRecev: rgba(236, 245, 249, 1);
  --bglight: rgba(236, 245, 249, 1);
  --shadowslider: 0px 0px 7.78px 1.56px rgba(2, 23, 29, 0.25);
  --colorshadow: rgba(2, 23, 29, 1);
  --lightdarkbtn: rgb(50, 152, 121);
  --colirbtndiff: rgba(2, 23, 29, 1);
  --colirrevdiff: rgba(209, 218, 221, 1);
  --btnthmnew: rgba(225, 233, 236, 1);
  --btndiffrent: rgba(209, 219, 223, 1);
  --inputcolors: gba(2, 23, 29, 0.7);
  --exchngtitle: rgba(236, 245, 249, 1);
  --inputtext: #000;
  --dark-text: #5e4b56;
  --light-text: #5e4b56;
  --btntext: #2a688b;
  --homeCard: #fff;
  --hoverWhite: #f3f2f6;
  --accordcontent: #141c2b;
  --tabBtnBg: #30f1b64d;
  --bordergreen: #1ea0ab;
  --themebgbtn: linear-gradient(166deg,
      rgba(43, 100, 136, 1) 0%,
      rgba(30, 160, 171, 1) 100%);
  --themegraybtn: #f1eff5;
  --bordercolor: #d7d4e0;
  --dark-background: transparent linear-gradient(180deg, #ffffff 0%, #dff0f000 100%) 0% 0% no-repeat padding-box;
  --light-background: #eddcd2;
  --sidebariconbg: transparent linear-gradient(156deg, #2a688b 0%, #00bef2 100%) 0% 0% no-repeat padding-box;
  --accent: #dbe7e4;
  --button-border: #5e4b56;
  --bgusdtcard: #fbfbfb;
  --main-bg: linear-gradient(72deg,
      rgba(254, 254, 254, 1) 25%,
      rgba(219, 238, 235, 1) 100%);
  --sidebar-bg: #ffffff4d;
  --selcted-Tab: #fff;
  --headerText: #02171d;
  --headerText1: #00bef2;
  --settingCard: #f6f5f9;
  --setingCardBg: transparent linear-gradient(180deg, #fdfcff 0%, #ffffffba 100%) 0% 0% no-repeat padding-box;
  --homeCard: #fefdfe;
  --tradebg: #ecf5f9;
  --whiteblack: #000;
  --smalp: #77868b;
  --gridCard: #ecf5f9;
  --collpasedata: #f5fefe;
  --cardBackground: #ecf5f9;
  --chartCardBG: #f0eff4;
  --textColor-80839E: #80839c;
  --shadowWhite: 0px 3px 7px #00000017;
  --shadowifram: 0px 3px 7px #00000017;
  --modalBg: rgba(226, 233, 236, 1);
  --cardbgDCEAEF: #dceaef;
  --tabletext: #1b1c1e;
  --bankcardBg: rgba(243, 243, 243, 0.5);
  --searchinputclr: #deebf0;
  --borderclr: #d7d4e000;
  --sidebarbrdr: #2b3e4330;
  --accordionHeaderbg: #192733;
  --pricevolumeBG: #b5e2e4;
  --cardsec: transparent linear-gradient(180deg, #fdfcff 0%, #ffffffba 100%) 0% 0% no-repeat;
  --shadow: 0px 0px 12px #00000012;
  --innerCard: #e6eef2;
  --innerCardBorder: #f3f3f3;
  --currencyInput: #fbfbfb;
  --createAccount: transparent linear-gradient(116deg, #edecef 0%, #ffffff73 48%, #edecef 100%) 0% 0% no-repeat padding-box;
  --createAccount: #f5fefe;
  --accountText: #707070;
  --cardBorder: #00bef2;
  --bgMain: #e2e9ec;
  --footerBGImage: url("./Components/Assets/Images/FooterBgWhite.png");
  --whiteblack: #fff;
  --itemselected: #3298790d;
  --itemselectedBorder: #329879;
  --mainColor: #329879;
  --sidebarColor: #02171d;
  --tableHead: #ffffff;
  --tabHover: #c1ece3;
  --daysBg: #fff;
  --tabMainBg: #ecf5f9;
  --tdColor: #042325;
  --btnColor: #fff;
  --profileBG: #ffffff;
  --portfolioText: #02171d;
  --textsubTitle: #77868b;
  --tabsBg: #e1e9ec;
  --tabsBgActive: #c1ece3;
  --tabsBgActiveColor: #329879;
  --inputColor: #77868b;
  --referralBg: #f5fefe;
  --referralList: #d1dbdf;
  --datePickerBg: #ecf5f9;
  --imgBg: #ffffff;
  --landingWhite: #02171d;
  --cardlanding: #d9e7e8;
  --smalp: #77868b;
  --bannerBorder: #dbe5e7;
  --OurHistoryBg: url("./Components/Assets/Images/OurHistorylight.png");
  --cardHover: #dae1e4;
  --TradeAnywhere: url("./Components/Assets/Images/TradeAnywhereLight.png");
  --Header-sc: #cbdfe0;
  --borderYellow: #f7931a;
  --yellowFadeBg: #f7931a05;
  --transactionstabsBg: #d1dbdf;
  --Searchicon: #02171d;
  --progressLine: #d6dfe3;
  --darkLight: #000;
  --tbbgdark: #ecf5f9;
  --inputnew: #d1dbdf;
  --bgaboutsec: url("./Components/Assets/Images/lightbgsection.png");
  --brd_diff: #c1cbce;
  --uniqesec: rgba(205, 215, 218, 0.97);
  --dropdownbg: #cfd9de;
  --arrowright: url("./Components/Assets/Images/calenderarrowdark.png");
  --paginationleftdisable: url("./Components/Assets/Images/paginationleft.png");
  --paginationactive: url("./Components/Assets/Images/lightthemearrow.png");
  --feeSub: #e3dbd1;
}

:root {
  // Typography //
  --headingOne: 32px;
  --heading28: 28px;
  --headingTwo: 22px;
  --headingThree: 24px;
  --headingFour: 20px;
  --largeParaGraph: 18px;
  --largeParaGraph16: 16px;
  --regularParagraph: 15px;
  --mediumParaGraph: 14px;
  --smallParagraph: 13px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  :root {
    --headingOne: 28px;
    --heading28: 24px;
    --headingTwo: 20px;
    --headingThree: 22px;
    --headingFour: 18px;
    --largeParaGraph: 16px;
    --largeParaGraph16: 15px;
    --regularParagraph: 14px;
    --mediumParaGraph: 13px;
    --smallParagraph: 12px;
  }
}

@media screen and (max-width: 480px) {
  :root {
    --headingOne: 24px;
    --heading28: 20px;
    --headingTwo: 18px;
    --headingThree: 20px;
    --headingFour: 16px;
    --largeParaGraph: 14px;
    --largeParaGraph16: 13px;
    --regularParagraph: 13px;
    --mediumParaGraph: 12px;
    --smallParagraph: 11px;
  }
}

body {
  margin: 0;
  box-sizing: border-box;
  background-color: $white;
  // font-family: $baseFont2 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: $baseFontRegular;
  --primaryGreen: #329879;
}

body {
  * {

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }
  }
}

#root {
  height: 100%;
  // min-height: 100vh;
  // display: flex;
  // flex-direction: column;
}

img {
  max-width: 100%;
}

#launcher {
  @media screen and (max-width: 767px) {
    bottom: 64px !important;
    box-shadow: var(--shadowslider);
  }
}

.theme-dark {

  .sidebarIcons,
  .ant-menu-item-selected,
  .ant-menu-item {
    &:hover {
      svg {
        path {
          fill: $baseblue;
        }
      }
    }
  }

  .ant-menu-item-selected,
  .ant-menu-item-selected:hover {
    .sidebarIcons {
      path {
        fill: $baseblue !important;
      }
    }
  }

  background-color: #181a20;
}

.theme-light {
  .unfiedSec {
    svg path {
      fill: #000;
    }
  }
}

input:focus {
  box-shadow: unset !important;
}

.ant-picker-focused {
  box-shadow: none !important;
}

// .theme-light{
#tv_chart_container {
  // padding-left: 21px;
  margin-left: 10px;
  // border-left: 1px solid #d7d4e0;

  @media (max-width: 767px) {
    padding-left: 0;
  }
}

// }
.lightTheme {
  background-color: #fafafa;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row;
  height: 100vh;
}

// .ant-layout {
//   background: var(--dark-background) !important;
// }

.ant-layout-content {
  display: flex;
  flex-direction: column;
  // background: var(--sidebar-bg);
  margin: 0 !important;
  // overflow-y: auto;

  .flexAuto {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 55px;

  @media (max-width: 991px) {
    padding: 0 20px;
  }

  @media (max-width: 676px) {
    padding: 0 15px;
  }
}

// Sidebar table css common_________________________

.ant-table {
  background-color: var(--tabMainBg) !important;
  height: 100%;
  overflow: auto;
  border-radius: 4px !important;

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-tbody>tr>td {
    @include fluid-type(320px, 1920px, 12px, 15px);
  }

  .ant-table-thead>tr>th {
    font-weight: 600;
    color: var(--smalp);
  }
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;

  tr th {
    border-bottom: 0 !important;
    white-space: nowrap;
  }

  @include fluid-type(320px, 1920px, 10px, 14px);
  border-bottom: 0 !important;
  white-space: nowrap;
}

td {
  background-color: transparent;

  border-bottom: 0 !important;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: transparent;
}

.ant-empty {
  .ant-empty-description {
    color: var(--emptytext);
  }
}

// Common csss----------------------End

//sidebar css starts here //

.ant-layout-sider {
  // border-right: 1px solid red;
  // box-shadow: 5px 0 3px -3px var(--sidebarbrdr);

  border-right: 1px solid var(--sidebarbrdr);

  &-trigger {
    color: $darktext;
    border: 1px solid;
  }
}

.ant-menu {
  &-item {
    padding-left: 30px !important;
    margin-top: 0 !important;

    &-icon {
      display: flex !important;
      height: 100% !important;
      align-items: center !important;
      padding-left: 4px;
    }

    &-only-child {
      position: relative;
      padding-left: 80px !important;

      &::before {
        content: "";
        position: absolute;
        left: 60px;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        border-radius: 100%;
      }
    }
  }

  &-submenu {
    &-title {
      margin-top: 0 !important;
      padding-left: 30px !important;
    }
  }
}

.ant-menu-submenu-popup {
  li {
    padding-left: 30px !important;

    &::before {
      content: unset;
    }
  }
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item {
  padding: 0 calc(50% - 30px) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

// / Firefox /
input[type="number"] {
  -moz-appearance: textfield !important;
}

//scrollbar {
::-webkit-scrollbar-track {
  border-radius: 0px;
  // background-color: $scrollbarTrack;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 3px;
  height: 20px;
}

.scrollBar:hover::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
}

table td,
th {
  white-space: nowrap;
}

.timerDesign {
  color: var(--headerText);

  span {
    color: var(--headerText);
    background: #1ea0ab;
    padding: 4px 3px;
    margin: 0px 5px;
    border-radius: 2px;
    font-weight: 600;
    color: #fff;
  }
}

// Commonly used Css//
// .height100vh {
// }

.headingCommon {
  @include fluid-type(320px, 1920px, 20px, 30px);
  font-weight: 500;
  margin-top: 30px;
  color: var(--headerText);
  line-height: 33px;
  font-family: "F37 Incise", sans-serif;
  word-spacing: normal !important;
  word-spacing: normal;

  // @media (max-width: 991px) {
  //   word-spacing: -5px;
  // }
}

.otpFields {
  >div {
    // margin-top: 10px;

    @media (max-width: 767px) {
      justify-content: center;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--mainColor) !important;
    }
  }

  >div:first-child input {
    border: 0;
    border-radius: 16px;
    height: 60px !important;
    width: 67px !important;
    background-color: var(--innerCard) !important;
    margin: 0 10px 10px 0px !important;
    border-radius: 4px;
    color: var(--inputtext);

    @media (max-width: 767px) {
      height: 40px !important;
      width: 45px !important;
    }

    @media (max-width: 420px) {
      height: 30px !important;
      width: 35px !important;
    }

    &:focus-visible {
      outline: 1px solid $baseblue;
      border: none;
    }
  }
}

.theme-dark {
  .mainLayout {
    background-image: url("./Components/Assets/Images/mainBlackBg.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.theme-light {
  .mainLayout {
    background-image: url("./Components/Assets/Images/mainWhiteBg.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.mainLayout {
  min-height: 100vh;
  // height: 100%;

  @media (max-width: 767px) {
    min-height: unset;
  }

  .ant-layout-content {
    margin: 0 !important;
    padding: 0 !important;
    // padding-bottom: 65px !important;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ant-layout-header {
    background-color: #fff;
  }

  .ant-layout-sider {
    background: var(--sidebar-bg);
    // background: transparent;
    // border-right: 1px solid var(--sidebarborder);
    max-width: 252px !important;
    min-width: 252px !important;
    width: 252px !important;
    z-index: 99;
  }

  .logo {
    cursor: pointer;
    height: 76px;
    padding: 25px 27px 0px 25px;
    top: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 10px;

    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      img {
        width: 60px;
      }
    }

    a,
    span {
      svg {
        path {
          fill: var(--headerText);
        }
      }
    }
  }

  .ant-layout-header {
    height: 86px !important;
    border-bottom: 1px solid $bordergrey;
  }

  .ant-layout-sider-collapsed {
    max-width: 85px !important;
    min-width: 85px !important;
    width: 85px !important;

    @media (max-width: 767px) {
      max-width: 80px !important;
      min-width: 80px !important;
      width: 80px !important;
    }

    .ant-menu {
      padding-right: 0px;

      @media (max-width: 767px) {
        padding-right: 0;
      }
    }
  }

  .ant-layout-sider-children {
    overflow-y: scroll;
  }

  .ant-menu {
    // padding: 0 24px;
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    gap: 4px;

    svg {
      path {
        fill: var(--sidebarColor);
      }

      rect {
        fill: var(--sidebarColor) !important;
      }
    }

    &.collapsed {
      transition: 0.3s all;
      width: 100% !important;
      .ant-menu-item-active // &:hover,

      // &:focus-visible,
      // &:focus
        {
        background: var(--itemselected) !important;
      }

      .ant-menu-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 767px) {
          justify-content: center;
        }

        .ant-menu-title-content {
          display: none;
        }
      }
    }

    .ant-menu-item-selected {
      background: var(--itemselected) !important;
      border-left: 2px solid var(--itemselectedBorder) !important;
      position: relative;

      .ant-menu-title-content {
        color: var(--mainColor) !important;
      }

      // margin-left: 8px !important;

      .ant-menu-item-icon {
        // background: var(--sidebariconbg);
        // border-radius: 12px;
        // justify-content: center;
        // padding-left: 0;

        // &:hover {
        //   background: var(--sidebariconbg);
        // }
      }

      &.ant-tooltip-open {
        // display: none;
      }

      .ant-menu-item-icon {
        svg {
          path {
            fill: var(--mainColor) !important;
          }

          rect {
            fill: var(--mainColor) !important;
          }
        }
      }
    }

    .ant-menu-item {
      font-weight: 500;
      @include fluid-type(320px, 1920px, 12px, 16px);
      padding-left: 25px !important;
      height: 50px;
      border-left: 2px solid transparent;
      margin-bottom: 0px !important;
      // gap: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 767px) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 !important;

        span {
          display: none;
        }
      }

      .ant-menu-item-icon {
        width: 32px;
        height: 32px !important;
      }

      &:hover {
        background: var(--itemselected) !important;
        // border-radius: 12px;
        color: var(--mainColor);
        border-left: 2px solid var(--itemselectedBorder) !important;

        .ant-menu-title-content {
          color: var(--mainColor) !important;
        }

        .ant-menu-item-icon {
          svg {
            path {
              fill: var(--mainColor);
            }

            rect {
              fill: var(--mainColor) !important;
            }
          }
        }
      }

      .ant-menu-title-content {
        color: var(--sidebarColor);
        @include fluid-type(320px, 1920px, 12px, 16px);
        margin-left: 12px;
      }
    }

    .ant-menu-item-active {
      background-color: $white;
    }
  }

  .fa {
    content: "\f105";
    color: $borderdarkgrey;
  }
}

.ant-card-body {
  padding: 0 !important;
}

.customModalStyle {
  .ant-modal-content {
    border-radius: 4px;
    width: 100%;
    margin: auto;
    background: var(--modalBg);
    box-shadow: 0px 0px 12px #00000012;
    border-radius: 4px;
    opacity: 1;
    padding: 50px;

    @media (max-width: 767px) {
      padding: 50px 20px;
    }
  }

  .ant-modal-header {
    background-color: transparent;
    border-bottom: none;
    padding: 0;
    text-align: left;
  }

  .ant-modal-body {
    padding: 0px;
  }
}

.copydataIcon {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  background-color: #1258ee;
  color: #fff;
  box-shadow: inset 0px 0px 24px white;
  border-radius: 10px;
  pointer-events: all;
  transition: all 0.1s linear;
  opacity: 1;

  &:empty {
    opacity: 0;
    pointer-events: none;
  }
}

// btngroups

.btngroup {
  display: flex;
  gap: 15px;
  flex-direction: row-reverse;

  button {
    height: 48px !important;
  }

  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    .leftIcon {
      svg {
        margin-top: 5px;

        path {
          fill: var(--btnColor);
        }
      }
    }

    .ant-btn.btn_custom {
      // height: 40px !important;
      width: 145px;
      padding: 10px 10px;
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-weight: 600;

      @media (max-width: 1449px) {
        width: 100%;
      }

      span {
        @include fluid-type(320px, 1920px, 14px, 16px);
      }
    }

    .btnCustom.ant-btn.lytgaryBtn {
      @include fluid-type(320px, 1920px, 14px, 16px);
      height: 40px;
    }
  }
}

.icons {
  &.padding0 {
    button {
      padding: 0 !important;
    }
  }

  .btnCustom.ant-btn.lytgaryBtn {
    height: 40px;
    width: 40px;

    @media (max-width: 767px) {
      height: 30px !important;
      width: 30px !important;
    }
  }

  .ant-modal-close {
    top: 0;
    right: 0;
  }

  &.TableModalStyle {
    .ant-modal-header {
      border-bottom: none;

      .blue {
        color: $baseblue;
      }
    }

    .ant-modal-close {
      background-color: $white;
      border-radius: 50%;
      top: -44px;
      right: -19px;

      .ant-modal-close-x {
        width: 30px;
        height: 30px;
        line-height: 2;
      }
    }
  }
}

// prices

.priceTable {
  cursor: pointer;

  thead>tr>th {
    background-color: var(--tableHead);

    &:first-child {
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }

    &:last-child {
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  .ant-table {
    background-color: transparent !important;

    tr td {
      background: var(--gridCard);
    }

    tr td:first-child {
      border-radius: 7px 0 0 7px;
      padding-left: 36px;

      @media (max-width: 767px) {
        padding-left: 15px;
      }

      &:hover {
        border-radius: 7px 0 0 7px;
      }
    }

    tr td:last-child {
      border-radius: 0 7px 7px 0;
      padding-left: 36px;

      &:hover {
        border-radius: 0 7px 7px 0;
      }
    }

    tr {
      &:hover {
        td {
          background: rgb(0, 133, 176) !important;
        }
      }
    }

    .ant-table-row {
      border-radius: 4px;
      opacity: 1;

      &:hover {
        border-radius: 7px;

        td {
          background: var(--gridCard) !important;
          // color: $white;
        }

        // p {
        //   color: $white;
        // }
      }
    }
  }

  table {
    border-spacing: 0 1em !important;

    // tr {
    //   &:first-child {
    //     display: none;
    //   }
    // }
  }

  .bitData {
    display: flex;
    align-items: center;
    gap: 8px;

    .ant-table-thead>tr>th {
      position: relative;
      text-align: left;
      background: #fff !important;
      color: rgba(0, 0, 0, 0.85);
    }

    p {
      margin-bottom: 2px;
      color: var(--smalp);
      @include fluid-type(320px, 1920px, 12px, 14px);
      font-weight: 500;
    }

    .coins {
      font-weight: 600;
      color: var(--headerText);
      @include fluid-type(320px, 1920px, 12px, 16px);
    }
  }

  .downarrows {
    img {
      margin-right: 4px;
    }
  }
}

// topmover
.tribe {
  &.triveTxtCenter {
    display: flex;
    align-items: center;
  }

  p {
    color: var(--smalp);
    @include fluid-type(320px, 1920px, 12px, 14px);
  }

  .topmovericon {
    font-weight: 600;
    color: var(--sidebarColor);
    @include fluid-type(320px, 1920px, 12px, 16px);
  }
}

.pricedatamover {
  text-align: right;
  color: var(--sidebarColor);
  @include fluid-type(320px, 1920px, 12px, 16px);

  .downarrows {
    white-space: nowrap;
    color: var(--mainColor);
  }

  p {
    color: var(--headerText);
    @include fluid-type(320px, 1920px, 12px, 16px);

    span {
      display: flex;
      justify-content: flex-end;
    }

    img {
      margin-right: 4px;
    }
  }

  .downicon {
    svg {
      path {
        fill: #cf0038;
      }
    }
  }

  .uppericon {
    svg {
      path {
        fill: var(--mainColor);
      }
    }
  }
}

// portfoliotable
.tableBorderNone {
  .ant-table-content {
    table {
      tbody {
        tr {
          td {
            border-bottom: none !important;
          }
        }
      }
    }
  }
}

.portfolioTable {
  width: 100%;
  min-height: 350px;
  height: 100%;

  &.cursorInitial {
    .ant-table-tbody>tr {
      cursor: initial;
    }
  }

  .bitData {
    display: flex;
    align-items: center;
    gap: 6px;

    // .bitdatainner{
    //   padding-right: 15px;
    // }
    .bitcoindata {
      display: flex;
      flex-direction: column;
    }
  }

  .ant-table-content {
    table {
      min-width: 520px;

      @media (max-width: 767px) {
        min-width: unset;
      }
    }
  }

  .ant-table-thead>tr {
    >th {
      background: var(--collpasedata) !important;
      @include fluid-type(320px, 1920px, 12px, 12px);

      &:first-child {
        padding-left: 28px;

        @media (max-width: 1199px) {
          padding-left: 20px;
        }
      }

      @media (max-width: 767px) {
        padding-right: 15px;
        padding-left: 25px;
      }
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      td {
        @include fluid-type(320px, 1920px, 12px, 12px);
      }

      td.ant-table-cell-row-hover {
        background-color: var(--hoverWhite) !important;
      }
    }
  }

  .ant-table-tbody>tr:last-child td {
    border-bottom: none !important;
  }

  .btcvalues {
    display: flex;
    flex-direction: column;
  }

  .ant-table-tbody>tr {
    cursor: pointer;

    >td {
      border-bottom: 1px solid var(--bordercolor) !important;
      color: var(--portfolioText);
      padding: 11px;
      padding-right: 25px;

      &:first-child {
        padding-left: 28px;

        @media (max-width: 1199px) {
          padding-left: 20px;
        }
      }

      @media (max-width: 767px) {
        padding-right: 15px;
        padding-left: 25px;
      }
    }
  }

  h6 {
    font-size: 12px;
  }
}

.rewardsData {
  border-bottom: 1px solid var(--bordercolor) !important;
  margin-bottom: 0 !important;
  padding: 17px 25px;

  @media (max-width: 1400px) {
    padding: 17px 10px;
  }

  @media (max-width: 991px) {
    &:last-child {
      border-bottom: none !important;
    }
  }

  .tribeData {
    @media (max-width: 480px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .pricedatamover {
    @media (max-width: 480px) {
      text-align: center;
    }
  }
}

/*card...........*/
div[class$="card"] {
  // border: 1px solid $bgderkgrey;
  border-radius: 4px;
  // box-shadow: 0px 0px 12px #00000012;

  li {
    cursor: pointer;
  }
}

/*===========header=============*/
// .responsiveSidebar {
//   display: none;
// }
.drawerLanding {
  .ant-drawer-content-wrapper {
    max-width: 378px;
    width: 100% !important;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;

    .ant-drawer-close {
      margin: 0;
    }
  }
}

.ant-drawer-title {
  h2 {
    color: $baseblue !important;
  }
}

.ant-drawer-wrapper-body {
  a {
    display: block;
    // color: $darktext;
    color: var(--light-text);
    padding: 10px 0;

    &:hover {
      color: $baseblue;
    }
  }
}

.bg-white {
  // background-color: var(--Header-sc);
  // background-image: url("./Components/Assets/Images/header.svg");
  backdrop-filter: blur(50px);
  background-color: var(--bgMain);
  opacity: 1;
}

.ant-drawer-header,
.ant-drawer-wrapper-body {
  background-color: var(--cardBackground) !important;

  button.ant-btn {
    // background-color: $baseblue !important;
    color: var(--headerText);

    &:hover {
      opacity: 0.8;
    }
  }
}

.ant-drawer-header {
  background-color: transparent !important;
  border-color: var(--smalp) !important;

  .anticon {
    color: var(--headerText) !important;
  }
}

/*======================comman-text===============*/

h3[class^="text-black-36"] {
  color: $white;
  font-weight: 600;
  @include fluid-type(320px, 1920px, 20px, 36px);
  line-height: 50px;
  font-family: Open Sans;
}

h4[class^="text-black-20"] {
  padding: 20px 28px;
  color: $darktext;
  @include fluid-type(320px, 1920px, 14px, 20px);
  font-weight: 600;
  color: var(--headerText);

  @media (max-width: 1199px) {
    padding: 22px 20px;
  }
}

h4.ant-typography,
h2[class$="blue"] {
  color: $baseblue !important;
}

h2[class^="logo"] {
  font-weight: 800;
  @include fluid-type(320px, 1920px, 18px, 36px);
}

h2[class$="white"] {
  color: $white;
  display: flex;
  align-items: center;
}

/*text........*/
p[class^="text-grey-14"] {
  color: $text-80838E;
  @include fluid-type(320px, 1920px, 12px, 14px);

  a {
    color: $baseblue;
    text-decoration: underline;
  }
}

p[class$="twelve"] {
  @include fluid-type(320px, 1920px, 10px, 12px);
}

p[class^="grey"] {
  color: $text-80838E;
}

h6[class^="black14"] {
  @include fluid-type(320px, 1920px, 12px, 14px);
  color: var(--headerText);

  span {
    color: var(--headerText);
  }
}

div[class$="card"] {
  box-shadow: var(--shadow);
  background: var(--cardBackground) 0% 0% no-repeat padding-box;
  border-radius: 4px;

  // height: 100%;
  li {
    cursor: pointer;
  }

  .borderReward {
    border-bottom: 1px solid gray !important;
  }
}

.ant-progress-inner {
  background-color: var(--progressLine) !important;
}

div[class^="outer"] {
  margin: 30px 40px 30px 50px;
  box-shadow: 0px 0px 12px #00000012;

  @media (max-width: 767px) {
    margin: 30px 20px 30px 20px;
  }
}

.select-leftLabel {
  .SelectOuter {
    display: flex;
    align-items: center;

    .label {
      width: 120px;
      color: $text-80838E;
    }

    .customSelect.ant-select {
      width: 100%;
      max-width: 154px;
      height: 40px;
      margin-bottom: 0;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 21px;
    transition: all 0.3s;
  }
}

a.ant-btn {
  padding: 0 !important;
}

/*=============================tabs-design======================*/
.ant-tabs,
.ant-tabs-top {
  .ant-tabs-nav-more {
    @media (min-width: 991px) {
      display: none !important;
    }
  }

  // .anticon {
  //   svg {
  //     color: var(--headerText) !important;
  //   }
  // }

  &.blueTabs {
    .ant-tabs-nav {
      &::before {
        width: 0;
      }

      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          background-color: var(--daysBg);
          padding: 3px;
          border-radius: 4px;
          // width: 100%;

          // @media (max-width: 991px) {
          //   width: calc(100% - 10px);
          // }

          // @media (max-width: 767px) {
          //   width: unset;
          // }

          .ant-tabs-tab {
            color: #ffffff7a;
            padding: 9px 20px;
            font-weight: 400;
            // flex-basis: 33.3%;
            @include fluid-type(320px, 1920px, 12px, 16px);
            justify-content: center;

            &.ant-tabs-tab-active {
              background: var(--tabHover);

              .ant-tabs-tab-btn {
                color: var(--mainColor);
              }
            }
          }

          .ant-tabs-ink-bar {
            background: none;
            bottom: 6px;
          }
        }
      }

      // .ant-tabs-nav-operations {
      // }
    }
  }

  &.decorationNone {
    .profileSetting {
      background: var(--tradebg);
      box-shadow: 0px 3px 16px #00000017;
      border-radius: 4px;
      // opacity: 1;
      margin-top: 20px;
    }

    .securitySettings {
      .securityInner {
        padding: 0;
      }
    }

    .ant-tabs-nav {
      background: var(--tradebg);
      box-shadow: 0px 3px 16px #00000017;
      border-radius: 4px;
      opacity: 1;
      padding: 15px 24px;

      &::before {
        display: none;
      }

      .ant-tabs-ink-bar {
        display: none;
      }

      .ant-tabs-nav-wrap {
        border-top-left-radius: unset !important;

        .ant-tabs-nav-list {
          // width: 100%
          background-color: var(--daysBg);
          padding: 3px;
          border-radius: 4px;

          .ant-tabs-tab {
            color: var(--smalp);
            padding: 13px 23px;
            font-weight: 400;
            // flex-basis: 33.3%;
            @include fluid-type(320px, 1920px, 14px, 16px);

            &.ant-tabs-tab-active {
              background: var(--tabHover);
              // border-radius: 12px 0px 0px 12px;
              opacity: 1;
              border-radius: 2px;

              .ant-tabs-tab-btn {
                color: var(--mainColor) !important;
              }
            }
          }

          .ant-tabs-ink-bar {
            background: transparent;
          }
        }
      }
    }

    // .ant-tabs-content-holder {

    // }
  }

  &.transactionshistoryTab {
    .ant-tabs-nav-wrap {
      // @media (max-width:767px) {
      //   overflow-y: hidden;
      //   overflow-x: auto !important;
      //   padding-bottom: 6px;
      // }

      .ant-tabs-nav-list {
        .ant-tabs-tab {
          padding: 18px 30px !important;
          max-width: 125px;
          @include fluid-type(320px, 1920px, 12px, 16px);

          @media (max-width: 767px) {
            padding: 15px !important;
          }

          &-active {
            border-bottom: 2px solid #014cec;
          }
        }
      }
    }
  }

  & .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          color: $text-80838E;
          font-weight: 500;
          padding: 15px 10px;

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: var(--mainColor);

              p {
                color: var(--mainColor);
              }
            }
          }

          .ant-tabs-tab-btn {
            @include fluid-type(320px, 1920px, 12px, 14px);
            font-weight: 500;
          }
        }
      }

      .ant-tabs-ink-bar {
        background: unset !important;
      }
    }
  }
}

.p2p {

  .ant-tabs,
  .ant-tabs-top {
    .ant-tabs-nav-more {
      @media (min-width: 991px) {
        display: none !important;
      }
    }

    .anticon {
      svg {
        color: var(--whiteblack) !important;
      }
    }

    &.blueTabs {
      .ant-tabs-nav {
        &::before {
          width: 0;
        }

        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            // width: 100%;

            // @media (max-width: 991px) {
            //   width: calc(100% - 10px);
            // }

            // @media (max-width: 767px) {
            //   width: unset;
            // }

            .ant-tabs-tab {
              color: #ffffff7a;
              padding: 22px 20px 16px 20px;
              font-weight: 400;
              // flex-basis: 33.3%;
              @include fluid-type(320px, 1920px, 12px, 16px);
              justify-content: center;

              &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                  color: $white;
                }
              }
            }

            .ant-tabs-ink-bar {
              background: none;
              bottom: 6px;
            }
          }
        }

        // .ant-tabs-nav-operations {
        // }
      }
    }

    &.decorationNone {
      .ant-tabs-nav {
        background: var(--setingCardBg);
        box-shadow: 0px 3px 16px #00000017;
        border-radius: 4px;
        opacity: 1;
        padding: 15px 24px;

        &::before {
          display: none;
        }

        .ant-tabs-ink-bar {
          display: none;
        }

        .ant-tabs-nav-wrap {
          border-top-left-radius: unset !important;

          .ant-tabs-nav-list {
            // width: 100%
            background-color: var(--settingCard);
            border-radius: 12px;

            .ant-tabs-tab {
              color: $text-80838E;
              padding: 13px 23px;
              font-weight: 400;
              // flex-basis: 33.3%;
              @include fluid-type(320px, 1920px, 12px, 16px);

              &.ant-tabs-tab-active {
                background: var(--themebgbtn);
                // border-radius: 12px 0px 0px 12px;
                opacity: 1;
                border-radius: 12px;

                .ant-tabs-tab-btn {
                  color: $white;
                }
              }
            }

            .ant-tabs-ink-bar {
              background: transparent;
            }
          }
        }
      }

      .ant-tabs-content-holder {
        background: var(--setingCardBg);
        box-shadow: 0px 3px 16px #00000017;
        border-radius: 4px;
        opacity: 1;
        margin-top: 20px;
      }
    }

    &.transactionshistoryTab {
      .ant-tabs-nav-wrap {
        // @media (max-width:767px) {
        //   overflow-y: hidden;
        //   overflow-x: auto !important;
        //   padding-bottom: 6px;
        // }

        .ant-tabs-nav-list {
          .ant-tabs-tab {
            padding: 18px 30px !important;
            max-width: 125px;
            @include fluid-type(320px, 1920px, 12px, 16px);

            @media (max-width: 767px) {
              padding: 15px !important;
            }

            &-active {
              border-bottom: 2px solid #014cec;
            }
          }
        }
      }
    }

    & .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            color: #fff;
            font-weight: 500;
            @include fluid-type(320px, 1920px, 12px, 14px);
            // padding: 12px 18px;

            &.ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: $white;
              }
            }
          }

          .ant-tabs-ink-bar {
            background: $baseblue !important;
          }
        }
      }
    }
  }
}

.remove-margin {
  .ant-divider-horizontal {
    margin: 0;
  }
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 !important;

  &:first-child {
    margin-left: 40px;
  }
}

.line-none {
  .ant-tabs-tab-active {
    background: var(--tabHover);

    .ant-tabs-tab-btn {
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-weight: 500;
      color: var(--mainColor) !important;
    }
  }

  .ant-tabs-tab-btn {
    @include fluid-type(320px, 1920px, 14px, 16px);
    font-weight: 500;
    color: var(--smalp);
  }

  &.ant-tabs-top>.ant-tabs-nav {
    &::before {
      border-bottom: none;
    }
  }

  .ant-tabs-ink-bar {
    background: white !important;
    bottom: 1px !important;
  }
}

.portTab {
  width: 100%;
  margin-top: -80px;

  .ant-tabs-tab {
    @media (max-width: 485px) {
      padding: 15px !important;
    }

    // @media (max-width: 380px) {
    //   padding: 22px 2px 16px 5px !important;
    // }
  }

  .ant-tabs-ink-bar {
    background: $white !important;
  }

  &.blueTabs {
    .ant-tabs-nav-wrap {
      // padding: 0 15px;
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        @include fluid-type(320px, 1920px, 14px, 16px);
        font-weight: 500;
        color: var(--mainColor) !important;
      }
    }

    .ant-tabs-tab-btn {
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-weight: 500;
      color: var(--smalp);

      &:focus {
        color: var(--light-text);
      }
    }
  }

  .ant-tabs-nav-list {
    align-items: flex-end;
  }

  .ant-tabs-nav {
    // background-image: url("./Components/Assets/Images/tabBg1.png");
    // height: 198px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px;

    @media (max-width: 380px) {
      // height: 220px;
    }

    // background: transparent linear-gradient(179deg, #2a688b 0%, #1ea0ab 100%) 0%
    //   0% no-repeat padding-box;
  }

  .ant-tabs-nav-wrap {
    // height: 60px;
    align-self: end !important;
    // border-top: 1px solid #ffffff7a;

    opacity: 1;
  }

  // max-width: 1123px;
}

.cardOvelapNav {
  background-image: url("./Components/Assets/Images/tabBg1.png");
  height: 198px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
}

input[type="radio"] {
  accent-color: $baseblue;
  width: 17px;
  height: 17px;
}

.buySellTab {
  &.convertTab {
    .ant-tabs-nav-list {
      :first-child {
        border-radius: 20px 20px 0 0 !important;
      }
    }
  }

  .ant-tabs-nav-list {
    width: 100%;
    border-radius: 4px;

    // :first-child {
    //   border-radius: 20px 0 0 0;
    // }

    // :nth-child(2) {
    //   border-radius: 0 20px 0 0;
    // }
  }

  .ant-tabs-tab {
    flex: 50%;
    padding: 14px 45px !important;

    @media (max-width: 1420px) {
      padding: 14px 30px !important;
    }

    @media (max-width: 1200px) {
      padding: 14px 45px !important;
    }
  }

  .card {
    ul {
      li {
        border-bottom: 1px solid var(--bordercolor) !important;
      }

      &:last-child {
        li {
          border-bottom: none !important;
        }
      }
    }
  }

  .ant-tabs-tab-btn {
    @include fluid-type(320px, 1920px, 14px, 16px);
    font-weight: 600;
  }

  .ant-tabs-nav-list {
    :last-child {
      .ant-tabs-tab-active {
        background: red !important;
      }
    }
  }

  .ant-tabs-tab-active {
    color: black !important;
    background: var(--tabMainBg);
    box-shadow: 0px 0px 12px #00000012;

    .ant-tabs-tab-btn {
      // color: var(--headerText) !important;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
}

/*=======================buy-sell-conver===================*/

.sendForm {
  .ant-input-affix-wrapper {
    width: 100%;
  }

  .inputSec {
    // width: 100%;
    min-width: 155px !important;
    justify-content: center;
    flex-direction: column;

    >div {

      &:focus,
      &:active {
        box-shadow: none !important;
      }
    }

    .ant-input {
      height: 56px;
      // text-align: end;
      // width: 200px !important;
      min-width: 120px;
      text-align: right;
      @include fluid-type(320px, 1920px, 32px, 42px);
      overflow-x: auto;

      // @media (max-width: 767px) {
      //   width: 125px !important;
      // }
    }
  }

  .amountField {
    border-radius: 25px;
  }

  .ant-input-suffix {
    color: var(--mainColor);
    font-weight: 600;

    >span {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
    outline: none !important;

    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .ant-input-affix-wrapper.amountField {
    background: transparent !important;
    border: none !important;
    align-items: center;
    display: flex;

    .ant-input-suffix {
      border-left: 2px solid var(--brderleft) !important;
      padding-left: 13px;

      img {
        margin-right: 5px;
        width: 30px !important;
        height: 30px !important;
      }
    }
  }

  .card {
    background-color: var(--sendRecev);
  }
}

.inputSec {
  align-items: center;
  display: flex;
  gap: 8px;

  sup {
    font-size: 24px;
    margin: 28px 0;
    color: $baseblue;
    top: -6px;

    @media (max-width: 1199px) {
      top: -3px;
    }

    @media (max-width: 585px) {
      font-size: 19px;
      top: -2px;
    }
  }

  .ant-input {
    border: none;
    width: 135px;
    @include fluid-type(320px, 1920px, 14px, 17px);
    font-weight: 600;
    color: var(--mainColor);
    height: 30px;
    background-color: transparent;
    padding: 0px 5px !important;

    &:focus {
      outline: none;
      box-shadow: unset;
    }
  }
}

.btcvalues {
  >div {
    padding: 20px 24px;
    display: flex;
    gap: 16px;

    @media (max-width: 1780px) {
      gap: 7px;
      padding: 16px;
    }

    @media (max-width: 1660px) {
      justify-content: center;
      gap: 15px;
    }

    button {
      height: 40px !important;
      max-width: 124px;
      width: 100%;

      @media (max-width: 1600px) {
        max-width: 100px;
      }

      @media (max-width: 575px) {
        height: 35px !important;
        max-width: 100px;
      }

      span {
        @include fluid-type(320px, 1920px, 13px, 16px);
        font-family: $baseFontRegular;
        font-weight: 500;

        &:first-child {
          margin-right: 7px;
        }
      }
    }
  }
}

.wallettabs {
  padding-top: 24px;

  .SelectOuter .label,
  .inputLayout .label {
    @include fluid-type(320px, 1920px, 12px, 12px);
    color: var(--smalp) !important;
  }

  .ant-tabs-tab {
    width: auto !important;
  }

  .SelectOuter {
    background-color: transparent;

    .customSelect.ant-select {
      background-color: transparent;
      border: 1px solid var(--sidebarbrdr);
    }

    .ant-select-selection-placeholder {
      margin-top: 4px !important;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 7px !important;
  }

  .ant-tabs-nav-list,
  .ant-tabs-tab.ant-tabs-tab-active {
    width: 100%;
    background-color: transparent !important;
  }

  .ant-tabs-bottom>.ant-tabs-nav::before,
  .ant-tabs-bottom>div>.ant-tabs-nav::before,
  .ant-tabs-top>.ant-tabs-nav::before,
  .ant-tabs-top>div>.ant-tabs-nav::before {
    border-bottom: 0px;
  }

  .ant-tabs {
    .ant-tabs-tab {
      padding: 0px 20px 0 0 !important;
      display: block;

      .ant-tabs-tab-btn {
        padding-bottom: 6px;
        text-align: center;
        color: var(--smalp);
        font-family: $baseFontRegular;
        font-size: 12px !important;
      }
    }
  }

  .blueTabs {
    .ant-dropdown-trigger {
      color: var(--colirbtndiff);
    }

    // .inputLayout {
    //   // margin-top: 15px !important;

    //   &:first-child {
    //     margin-top: 0px !important;
    //   }
    // }

    .ant-tabs-tab.ant-tabs-tab-active {
      color: $baseblue !important;
    }

    .currency {
      background-color: transparent !important;
      color: var(--inputtext);
      top: 50px !important;
      font-size: 11px;

      @media (max-width: 585px) {
        top: 44px !important;
      }
    }

    .ant-select-selection-search {
      input {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .ant-input.currencyInput,
    .flex_itmsorder .input_custum {
      // border: 0.5px solid rgb(43, 62, 67) !important;
      border: 1px solid var(--sidebarbrdr);
      background-color: transparent !important;
      color: var(--inputcolors) !important;
      font-size: 12px;
      font-weight: 400;

      &:placeholder-shown,
      &::placeholder {
        color: var(--inputcolors) !important;
      }
    }
  }
}

.grey-bg {
  background-color: var(--mainColor);
  color: var(--whiteblack);
  text-align: center;
  padding: 14px 33px;
  border-radius: 25px;
  margin: 0 auto 32px auto;
  display: block;
  max-width: 150px;

  // &:hover {
  //   color: var(--whiteblack);
  // }
}

.convertIcon {
  li {
    &:last-child {
      &:after {
        content: url("./Components/Assets/Images/Exchange.svg");
        width: 32px;
        height: 32px;
        position: absolute;
        left: 13px;
        top: -15px;
        background-color: $bg-light-gray-F3F3F3;
        text-align: center;
        padding: 8px;
        border-radius: 50%;
      }
    }
  }
}

.dFlexGap20 {
  display: flex;
  gap: 30px;
}

.convertIconSpan {
  content: url("./Components/Assets/Images/Exchange.svg");
  width: 28px !important;
  height: 28px;
  position: absolute;
  left: 41px;
  top: -17px;
  background-color: $bg-light-gray-F3F3F3;
  text-align: center;
  padding: 8px;
  border-radius: 50%;
}

.transferIcon {
  transform: rotate(90deg);
}

.exchagetitle {
  padding: 0 21px;
  background-color: var(--tradebg);
  // border-bottom: 1px solid $bordergrey;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1780px) {
    padding: 16px;
  }

  h4 {
    margin: 0px;
    @include fluid-type(320px, 1920px, 12px, 14px);
    font-weight: 600;
    color: #1ea0ab;
  }
}

.orange {
  color: $red-CF304A;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.green {
  color: var(--mainColor);
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.grey {
  color: $text-80838E;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.btcvalues {
  button {
    height: 40px !important;

    @media (max-width: 575px) {
      height: 35px !important;
    }
  }
}

.grey {
  color: $text-80838E;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.green {
  color: var(--mainColor);
}

.orderBookTab {
  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: none !important;
  }
}

.ant-tabs.ant-tabs-top.marketTradetabs {
  .ant-tabs-nav {
    margin-bottom: 7px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2px solid var(--mainColor);
  }
}

.tradeHistory {

  // margin-bottom: 22px;
  .ant-table {
    background-color: transparent !important;
  }

  .marketTradetabs {
    padding: 0;

    .ant-tabs-tab {
      padding: 14px !important;
      font-size: 14px !important;
    }

    .ant-tabs-nav::before {
      border-color: $bordergrey;
    }

    .customSelect.ant-select .ant-select-item,
    .customSelect.ant-select .ant-select-selection-item {
      color: var(--samp) !important;
    }
  }

  .ant-pagination {
    display: none;
  }

  .ant-table-content {
    overflow: auto;
    height: 480px;
    // background-color: var(--tradebg);

    @media (max-width: 1366px) {
      height: 335px;
    }

    @media (max-width: 767px) {
      height: calc(100vh - 270px);
    }
  }

  .ant-table-wrapper {
    .ant-table {
      .ant-table-thead {
        margin-bottom: 10px;
        position: sticky;
        top: 0;

        .ant-table-cell {
          font-weight: 500;
          background-color: var(--exchngtitle);
          padding: 10px 22px !important;
          border-bottom: 1px solid $bordergrey !important;
          @include fluid-type(320px, 1920px, 10px, 12px);

          &:last-child {
            text-align: right;

            @media (max-width: 1700px) and (min-width: 1366px) {
              padding-left: 5px !important;
            }
          }

          &:nth-child(2) {
            text-align: right !important;

            @media (max-width: 1700px) and (min-width: 1366px) {
              padding-left: 0 !important;
              padding-right: 0 !important;
            }
          }

          &:nth-child(1) {
            @media (max-width: 1700px) and (min-width: 1366px) {
              padding-right: 5px !important;
            }
          }

          @media (max-width: 1780px) {
            padding: 10px 16px !important;
          }
        }
      }

      .ant-table-tbody {
        max-height: 188px;

        .ant-table-row {
          &:first-child td {
            padding-top: 14px !important;
          }

          td {
            &.ant-table-cell-row-hover {
              background-color: var(--hoverWhite);
            }

            @include fluid-type(320px, 1920px, 12px, 12px);
            padding: 0px 22px;

            @media (max-width: 1780px) {
              padding: 0px 16px !important;
            }

            &:nth-child(1) {

              // padding: 0px 0px 0px 22px;
              @media (max-width: 1700px) and (min-width: 1366px) {
                padding-right: 5px !important;
              }
            }

            &:nth-child(2) {
              text-align: right !important;
              color: var(--smalp);

              @media (max-width: 1700px) and (min-width: 1366px) {
                padding-left: 0 !important;
                padding-right: 0 !important;
              }
            }

            &:nth-child(3) {
              color: var(--smalp);
              // padding: 0px 22px 0px 22px;
              text-align: right;

              @media (max-width: 1700px) and (min-width: 1366px) {
                padding-left: 5px !important;
              }
            }
          }
        }
      }

      .ant-table-pagination {
        display: none !important;
      }
    }
  }
}

// assetTable
.fundingtable {
  border-radius: 20px !important;
  overflow: hidden;
  margin-top: 30px;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    background-color: var(--collpasedata);
    color: var(--headerText);
    border-radius: 10px;
    align-items: center;
  }
}

.assetTable {
  padding: 30px 38px 30px 50px;
  width: 100%;
  max-width: auto;
  overflow: hidden;

  @media (max-width: 1366px) {
    padding: 20px;
  }

  h4 {
    color: var(--headerText);
    @include fluid-type(320px, 1920px, 14px, 20px);
    font-weight: 600;
    padding-bottom: 10px;
  }

  p {
    color: var(--smalp);
  }

  .fundingtable {
    border-radius: 20px !important;
    overflow: hidden;
    margin-top: 30px;
  }

  .ant-table-thead>tr>th {
    background-color: var(--collpasedata);
    font-weight: 600;

    &:first-child {
      padding-left: 28px;

      @media (max-width: 991px) {
        padding-left: 20px;
      }
    }
  }

  .ant-table-tbody>tr>td {
    border-bottom: 1px solid $tableborders !important;
    color: var(--smalp);
    @include fluid-type(320px, 1920px, 12px, 14px);

    &.ant-table-cell-row-hover {
      background-color: var(--hoverWhite);
    }

    &:first-child {
      padding-left: 28px;

      @media (max-width: 991px) {
        padding-left: 20px;
      }
    }
  }

  .ant-btn-link {
    color: var(--mainColor);

    &:hover {
      color: var(--mainColor);
      opacity: 0.8;
    }
  }
}

//Drawer css//
.drawerStyle {
  .btngroup>div {
    flex-direction: column;

    @media (max-width: 991px) {
      align-items: flex-start;
    }
  }

  .btngroup {
    max-width: 180px;
    margin: auto;

    @media (max-width: 991px) {
      text-align: left;
      max-width: 100%;
    }

    @media (max-width: 991px) {
      button.ant-btn {
        background: transparent !important;
        color: var(--headerText) !important;
        padding: 0 !important;
      }

      .ant-btn.btn_custom.themebtn {
        background: transparent !important;
        color: var(--headerText) !important;
        padding: 0 !important;
      }

      .ant-btn.btn_custom {
        justify-content: left;
        padding: 0 !important;
        height: 20px !important;
      }
    }
  }
}

.mb24 {
  margin-bottom: 24px;
}

/*=============40btn===========*/
button[class^="btn40"] {
  height: 40px;
  border-radius: 25px;
  width: 120px;
  border-color: transparent;
}

.tb-grey-btn {
  color: #000;

  &:hover {
    background-color: $baseblue;
    color: $white;
  }
}

// depositModal
.depositModals {
  display: flex;
  justify-content: space-between;
  padding: 4px 20px;
  width: 100%;
  margin-bottom: 36px;

  &:hover {
    background-color: $bg-light-gray-F3F3F3;
    border-radius: 14px;
  }

  .bitassetData {
    display: flex;
    gap: 6px;

    p {
      margin-bottom: 0;
      @include fluid-type(320px, 1920px, 12px, 16px);
    }

    :first-child {
      color: $darktext;
    }

    :nth-child(2) {
      @include fluid-type(320px, 1920px, 12px, 14px);
      color: $text-80838E;
    }
  }
}

.setting {
  // text-align: center;
  // max-width: 270px;
  width: 100%;

  .ant-dropdown-menu {
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 6px 10px #00000029;
    border-radius: 4px;
    max-width: 256px;
    background: var(--profileBG);

    .ant-dropdown-menu-item {
      padding-left: 28px;
      padding-right: 28px;
      text-align: left;
      color: var(--headerText);
      @include fluid-type(320px, 1920px, 12px, 16px);

      &:nth-child(1),
      &:nth-child(2) {
        &:hover {
          background-color: transparent;
        }
      }

      &:nth-child(1) {
        .ant-dropdown-menu-title-content {
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 600;
          @include fluid-type(320px, 1920px, 14px, 20px);

          >span {
            margin-bottom: 15px;
          }

          .noNotification {
            font-weight: 500 !important;
            @include fluid-type(320px, 1920px, 12px, 16px);
          }

          button {
            background-color: transparent;
            border: 1px solid $bordergrey;
            border-radius: 18px;
            padding: 9px 18px;
            color: $text-80838E;
            margin-bottom: 16px;
          }
        }
      }

      &.ant-dropdown-menu-item-active {
        background-color: transparent;
        border-radius: 10px !important;
      }
    }
  }
}

.submitted,
.accepted,
.processing,
.pending,
.prepared {
  color: #ffb011;
}

.collected,
.completed,
.deposited,
.succeed,
.signup,
.Deposit,
.trade,
.confirming,
.sucess,
.collecting,
.fee_processing,
.fee_processed,
.fee_collecting,
.fee_collected,
.failed,
.errored,
.receive {
  color: var(--mainColor);
}

.cancelled,
.canceled,
.rejected,
.failed,
.danger {
  color: #cf0038;
}

.cursorInitial {
  cursor: initial;
}

.height_flicker {
  td {
    height: 70px !important;
  }
}

.spotTable {

  td,
  th {
    &:last-child {
      text-align: right;
    }
  }
}

.coinViewTable {
  .ant-table-content {
    height: 406px;
    overflow: auto;
  }
}

.storImgs {
  display: flex;
  align-items: center;
  gap: 8px;
}

.headerModals {
  background: var(--modalBg);
  border-radius: 20px;

  .ant-modal-content {
    background: var(--modalBg);

    border-radius: 4px;
    overflow-y: hidden;
  }

  .ant-modal-body {
    padding: 0;
  }
}

// <<<<<<<<<<<<<<<===========footer Exchange=====>>>>>>>>
.exchangeFooter {
  height: 45px;
  border: 1px solid $bordergrey;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 0px;

  p {
    margin: 0px;
    text-align: center;
    @include fluid-type(320px, 1920px, 12px, 14px);
    color: $grey-707070;
  }
}

.sendRecived.modal {
  max-height: 100vh;
  height: 100%;
  overflow-y: auto;

  .ant-tabs-tab {
    height: 60px !important;
  }
}

.sendRecived {
  background: var(--modalBg);
  box-shadow: 0px 0px 12px #00000012;
  border-radius: 4px;

  .ant-input.input_amount {
    background-color: var(--bgingput) !important;
    border: 0px !important;

    &::placeholder {
      color: var(--inputplceholder) !important;
    }
  }

  .ant-tabs-content {
    position: inherit;
  }

  .ant-tabs-nav-list {
    width: 100% !important;
  }

  .ant-tabs-tab {
    max-width: 49.5%;
    width: 100%;
  }

  .ant-tabs-tab-active {
    border-bottom: 2px solid var(--mainColor);
    background-color: transparent !important;
  }

  .ant-tabs.ant-tabs-top.blueTabs {
    .ant-tabs-nav-list {
      background-color: var(--modalBg) !important;
    }
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    display: block;
  }

  .ant-tabs-tab-btn {
    text-align: center;
    width: 100%;
    color: $text-80838E;
    font-weight: 600;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: var(--mainColor) !important;
    }
  }
}

.sendreceivetrans {
  box-shadow: none;
}

.tabsDrawer {
  margin-top: 20px;

  .ant-tabs-tab {
    padding: 0 !important;
    margin-left: 0px !important;
    background-color: transparent;

    p {
      padding: 0 6px;
      display: flex;
      @include fluid-type(320px, 1920px, 12px, 16px);

      span {
        margin-right: 3px;

        svg {
          width: 17px;
          height: 16px;
        }
      }
    }

    .ant-tabs-tab-btn {
      font-size: 12px;
    }
  }

  .ant-tabs-content-holder {
    max-height: 300px;
    overflow: auto;
  }

  .ant-tabs-nav-list {
    gap: 6px !important;
  }

  .ant-tabs-nav {
    padding-bottom: 0px;

    &::before {
      border: 0px !important;
    }
  }

  .ant-tabs-tab-active {
    border-bottom: 0px;

    .ant-tabs-tab-btn {
      color: var(--headerText1) !important;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-table-thead {
    th {
      background-color: transparent !important;
      background: transparent;
    }
  }
}

.marketcomp {
  margin: 14px 0px;
  border-radius: 37px;
  padding: 10px 20px;
  border-radius: 37px;
  background-color: var(--tradebg);

  @media (max-width: 575px) {
    padding: 8px 10px;
    border-radius: 4px;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    gap: 5px;

    li {
      white-space: nowrap;
      margin-left: 5px;
      margin-right: 5px;

      @media (max-width: 575px) {
        margin-left: 0px;
        margin-right: 0px;
      }

      &:first-child {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      canvas {
        width: 100px !important;
        display: flex;

        @media (max-width: 575px) {
          width: 70px !important;
        }
      }

      h3 {
        @include fluid-type(320px, 1920px, 10px, 14px);
        font-weight: 600;
        color: var(--btntext);
      }

      p {
        @include fluid-type(320px, 1920px, 10px, 14px);
        color: var(--smalp);
      }
    }
  }
}

// .......................stateSelect

.stateSelect {
  .SelectOuter {
    display: flex;
    align-items: center;
    height: 40px;

    label {
      margin-right: 10px;
      padding-bottom: 0;
    }

    .select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 19px;
    }

    .customSelect.ant-select {
      height: 40px;
      width: 144px;
      margin-bottom: 0;
      background-color: $bg-light-gray-F3F3F3;
      border: none;
    }

    .ant-select-selector {
      height: 40px;

      .ant-select-selection-placeholder {
        display: inline-block;
        width: 144px;
      }
    }
  }
}

// .....................search

.search {
  position: relative;
  height: 40px;

  .ant-input.input_custum {
    height: 40px;
    padding-left: 50px;
    width: 144px;
    background-color: $bg-light-gray-F3F3F3;
    border: none;
  }

  &::after {
    content: url("./Components/Assets/Images/search.svg");
    position: absolute;
    top: 21px;
    left: 18px;
  }
}

// ...........setting

.verifyBtn {
  background: var(--mainColor);
  color: var(--btnColor);
  border: none;
  border-radius: 4px;
  padding: 9px 20px;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include fluid-type(320px, 1920px, 14px, 16px);
  font-weight: 600;
  margin-left: auto;

  img {
    display: none;
  }

  &.active {
    background: var(--referralList) !important;
    color: var(--mainColor);

    img {
      display: inline-block;
      margin-right: 10px;
    }
  }
}

// loginPage tabs
.loginTab {
  .ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: 0px !important;
  }

  .ant-tabs-tab-btn {
    @include fluid-type(320px, 1920px, 16px, 18px);
  }

  .ant-tabs-tab {
    padding: 10px 40px !important;

    @media (max-width: 767px) {
      padding: 10px 30px !important;
    }
  }

  .ant-tabs-nav {
    margin: 25px 0px !important;
  }

  .ant-tabs-top {
    margin-bottom: 10px;
  }
}

.input_righttext {
  margin-bottom: 42px;

  .input_imgcontent {
    p {
      color: var(--mainColor);
      font-weight: 600;
    }
  }
}

.country-list {
  width: 100%;
  background: var(--innerCard);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  li {
    color: $white;
  }
}

.react-tel-input .country-list .search {
  background-color: var(--innerCard);
  margin-bottom: 10px;

  input {
    width: 100%;
    border: 0;
    padding-left: 30px;
    border-radius: 4px;
    height: 40px;
    background-color: #0f2237;
  }
}

.react-tel-input {
  background-color: transparent !important;
  max-width: 511px;
  width: 100%;
  position: relative;

  // @media (max-width: 575px) {
  //   margin-bottom: 80px;
  // }
  .flag-dropdown.open {
    .selected-flag {
      &::before {
        content: "";
        position: absolute;
        height: 10px;
        width: 10px;
        background: url("./Components/Assets/Images/dropicon.svg");
        background-repeat: no-repeat;
        background-size: 10px;
        top: 57%;
        left: 58px !important;
        transform: translateY(-50%);
        filter: invert(52%) sepia(16%) saturate(254%) hue-rotate(149deg) brightness(95%) contrast(87%);
        // z-index: 11;
        transform: rotate(180deg);
        top: 41%;

        @media (max-width: 575px) {
          left: 60px !important;
        }
      }
    }
  }

  .ant-input {
    height: 60px !important;
    background-color: transparent;
    border-radius: 4px !important;
    margin-left: 100px;
    width: calc(100% - 100px);

    @media (max-width: 575px) {
      width: calc(100% - 90px);
      margin-left: 90px;
    }
  }

  .selected-flag {
    color: var(--whiteblack);
    border: 0 !important;
    border-radius: 4px !important;
    width: 80px !important;
    background-color: var(--innerCard);

    @media (max-width: 575px) {
      width: 80px !important;
    }

    &::before {
      content: "";
      position: absolute;
      height: 10px;
      width: 10px;
      background: url("./Components/Assets/Images/dropicon.svg");
      background-repeat: no-repeat;
      background-size: 10px;
      top: 57%;
      left: 58px !important;
      transform: translateY(-50%);
      filter: invert(52%) sepia(16%) saturate(254%) hue-rotate(149deg) brightness(95%) contrast(87%);
      // z-index: 11;

      @media (max-width: 575px) {
        left: 60px !important;
      }
    }
  }

  .no-entries-message {
    text-align: center;
  }

  .form-control {
    padding-left: 15px !important;
    border: 0;
    background-color: var(--innerCard);
    color: var(--inputtext);
    font-weight: 400;

    &:hover {
      border-color: $baseblue;
    }
  }
}

.flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  // max-width: 120px;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  // border: 1px solid $bordergrey;
  background: transparent;

  // @media (max-width: 575px) {
  //   border: 1px solid $bordergrey;

  //   &:hover {
  //     border-color: $baseblue;
  //   }
  // }

  .country-list {
    position: relative !important;
    width: 100% !important;

    // max-width: 400px;
    .no-entries-message {
      color: var(--headerText) !important;

      span {
        color: var(--headerText) !important;
      }
    }

    .country-name {
      color: var(--headerText) !important;
    }

    .dial-code {
      color: var(--headerText) !important;
    }
  }
}

.react-tel-input .flag-dropdown.open {
  // border: 1px solid $bordergrey !important;

  &::after {
    transform: rotate(180deg);
    // top: 23px;
    right: 17px;
    top: 38%;
    // transform: translateY(-50%);
  }
}

input[type="text"] {
  padding-left: 24px;
}

.ant-picker-input {
  padding-left: 2px;
}

// order tabs
.buttonTabs {
  .ant-tabs-tab {
    font-weight: 400;
    padding: 9px 28px !important;
  }

  span {
    display: flex;
    gap: 8px;
    align-items: center;
    @include fluid-type(320px, 1920px, 14px, 16px);
  }

  .ant-tabs-tab {
    background-color: $bg-light-gray-F3F3F3;
    border-radius: 8px 8px 0px 0px;
  }

  .ant-tabs-tab-active {
    background-color: $baseblue;
    border-radius: 8px 8px 0px 0px;
  }

  .ant-tabs-nav .ant-tabs-nav-list {
    gap: 10px;
  }

  .ant-tabs-nav-list .ant-tabs-tab {
    color: $darktext !important;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    filter: brightness(0) invert(1);
  }
}

// loginPage tabs
.loginTab {
  .ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: 0px !important;
  }

  .ant-tabs-tab-btn {
    @include fluid-type(320px, 1920px, 14px, 18px);
  }

  .ant-tabs-tab {
    padding: 10px 40px !important;
  }

  .ant-tabs-nav {
    margin: 0 0px 28px !important;
  }

  .ant-tabs-top {
    margin-bottom: 15px;
  }
}

.input_righttext {
  .input_imgcontent {
    p {
      color: var(--mainColor);
      font-weight: 600;
    }
  }
}

.marketTitle {
  @include fluid-type(320px, 1920px, 12px, 12px);
  color: var(--smalp);
  padding-top: 10px;
}

.errorCls {
  color: $red !important;
  margin-bottom: 5px;
}

// .anticon-close {
//   // position: absolute;
//   // top: -30px;
//   // right: -30px;
//   // background: #fff;
//   // height: 30px;
//   // width: 30px;
//   // display: flex !important;
//   // justify-content: center;
//   // align-items: center;
//   // border-radius: 100%;
//   // display: none !important;
// }

// otp
.otp_clicked {
  color: $base-hover !important;
}

// captcha
.captchaStyle {
  margin: 15px 0px 0px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.positionedContent {
  position: absolute;
  inset: 0;
  z-index: 9;
  background: var(--cardBackground);
  border-radius: 4px;
  overflow-y: auto;
  background: transparent linear-gradient(180deg, #ffffff 0%, #e6faff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 12px #00000012;
}

.placclr {
  input {
    &::placeholder {
      color: var(--inputplceholder) !important;
    }
  }
}

// .loader_style {
//   position: fixed;
//   width: 100%;
// top: 50%;
// left: 50%;
// transform: translate(-50%, -50%);
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: rgba(0, 0, 0, 0.66);
//   z-index: 9999;

// }
.ant-spin-dot-item {
  background-color: var(--bordergreen) !important;
}

.loader-container {
  // width: 100%;
  // height: 100vh;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // position: fixed;
  // background: rgba(0, 0, 0, 0.834);
  // z-index: 10000;

  .loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 10000;

    .lottieAntimationdata {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      z-index: 999;
      position: relative;

      svg {
        max-width: 400px;
      }
    }
  }
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: initial !important;
}

.ant-modal-close-x {
  .anticon svg {
    color: var(--headerText) !important;
  }
}

.titleModal {
  text-align: center;
  @include fluid-type(320px, 1920px, 16px, 18px);
  padding-top: 15px;
  font-weight: 500;
  color: var(--headerText);
}

.text-center {
  text-align: center;
}

.curserPointer {
  cursor: pointer;
}

.coin_icon {
  width: 44px;
  height: 44px;
  border-radius: 23px;
  aspect-ratio: 1;
  box-sizing: content-box !important;
  object-fit: fill;

  @media (max-width: 991px) {
    width: 33px;
    height: 33px;
  }
}

.activeBtn {
  background-color: #18384e !important;
  color: #fff !important;
  border-radius: 5px !important;
}

.border_round {
  .ant-modal-content {
    border-radius: 4px !important;
  }
}

.height50 {
  .customSelect.ant-select {
    height: 40px;
  }

  .mb12 {
    margin-bottom: 12px;
  }
}

/***************pagination*****************/
.ant-select-dropdown {
  .ant-pagination-options {
    background-color: grey;
  }

  .ant-select-item-option-active,
  .ant-select-item-option-selected {
    background-color: var(--hoverWhite) !important;
    color: var(--light-text) !important;
  }

  .ant-select-item-option {
    &:hover {
      background-color: var(--hoverWhite) !important;
      color: var(--light-text) !important;

      .demo-option-label-item {
        color: var(--light-text) !important;
      }
    }
  }
}

.pagination {
  padding: 15px;
  text-align: center;

  .ant-pagination-item-link {
    position: relative;

    .anticon.anticon-left {
      &::after {
        transform: rotate(180deg);
      }
    }

    .anticon {
      &::after {
        background: var(--paginationactive);
        background-size: cover;
        background-repeat: no-repeat;
        content: "";
        position: absolute;
        height: 13px;
        width: 15px;
        left: 10px;
        top: 10px;
        // transform: rotate(180deg);
      }

      svg {
        display: none;
      }
    }
  }

  .ant-pagination-disabled {
    .anticon {
      position: relative;

      &::after {
        background: var(--paginationleftdisable);
        background-size: cover;
        background-repeat: no-repeat;
        content: "";
        position: absolute;
        height: 13px;
        width: 15px;
        left: -6px;
        top: -14px;
        transform: rotate(0deg) !important;
      }

      svg {
        display: none;
      }
    }

    .anticon.anticon-right {
      &::after {
        transform: rotate(180deg) !important;
        top: -11px;
      }
    }
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    background-color: transparent !important;

    .ant-select-item-option-content {
      &:hover {
        color: var(--light-text) !important;
      }
    }
  }

  .ant-select-item-option-active {
    color: var(--light-text) !important;
  }

  .ant-select-item-option-content {
    &:hover {
      color: #000 !important;
    }
  }

  .ant-pagination-options {
    .ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow {
      .ant-select-selector {
        background: var(--homeCard) !important;
        border: 1px solid var(--bordercolor) !important;

        .ant-select-selection-item {
          color: var(--light-text);
        }
      }
    }
  }

  .rg-pagination {
    .ant-pagination-item-ellipsis {
      color: var(--light-text);
    }

    .ant-pagination-next .ant-pagination-item-link,
    .ant-pagination-prev .ant-pagination-item-link {
      background-color: transparent;
      border: none;
    }

    .ant-pagination-item {
      background-color: transparent;

      a {
        color: var(--smalp) !important;
        font-family: "F37 Incise", sans-serif !important;
        word-spacing: normal !important;
        font-weight: 500;
        @include fluid-type(320px, 1920px, 13px, 15px);
      }
    }

    .ant-pagination-item-active {
      background-color: var(--tradebg);
      border-radius: 5px;

      a {
        color: var(--mainColor);
      }

      &:hover {
        color: black;
      }
    }
  }

  .ant-pagination-item-ellipsis {
    color: var(--light-text) !important;
  }
}

.ant-pagination-item {
  border: none !important;
  background-color: transparent;

  &:hover {
    a {
      color: $white;
    }
  }
}

.input_textimg.customInputWithdarw .input_amount {
  background-color: var(--collpasedata);
  padding-right: 50px;
  border: 0;
  border-radius: 8px;
  margin-bottom: 8px;
  font-size: 12px;

  @media (max-width: 380px) {
    font-size: 10px;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }

  @media (max-width: 420px) {
    padding-left: 8px !important;
    padding-right: 22px;
  }

  &::placeholder {
    color: $text-80838E;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
  }
}

.otpmodal {
  .ant-modal-body {
    padding: 30px;
  }
}

.empty_data {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px !important;
  color: #80838e;
  @include fluid-type(320px, 1920px, 14px, 16px);
  font-weight: 500;
}

.dltModal {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--headerText1);
    @include fluid-type(320px, 1920px, 14px, 20px);
  }

  p {
    color: $borderdarkgrey;
    @include fluid-type(320px, 1920px, 14px, 16px);
  }

  .dltBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;

    button {
      border-radius: 30px;
      opacity: 1;
      background-color: #f3f3f3;
      border: 0;
      color: $darktext;
      padding: 0px 40px;
      height: 40px;
      @include fluid-type(320px, 1920px, 14px, 16px);
    }

    .confrBtn {
      color: #cf0038;
    }
  }
}

button {
  cursor: pointer;
}

input.ant-input.amountField {
  background: var(--tabBtnBg) !important;
  border: 1px solid var(--sidebarborder) !important;
  color: var(--headerText);
  border-radius: 4px;
  padding: 8px 24px !important;
  width: 100%;
  @include fluid-type(320px, 1920px, 12px, 14px);
}

.swapInput input.ant-input.swapInputField {
  width: 100% !important;
  background-color: var(--innerCard) !important;
}

// Steps css------------------------

.listingSteps {
  &__itemsDetail {
    .ant-steps-item {
      height: 82px;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
      background-color: $baseblue !important;
      border: 0;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon .ant-steps-icon {
      color: $white;
    }

    // .ant-steps-item-wait .ant-steps-item-icon {
    //   // border: 0;
    // }

    .ant-steps-item-finish .ant-steps-item-icon {
      background-color: $baseblue;
      border: 0;
    }

    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
      background-color: $baseblue !important;
    }

    .ant-steps-item-tail::after {
      width: 2px !important;
    }

    .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
      color: $white;
    }

    .ant-steps-item-tail {
      padding: 33px 0 1px !important;
    }

    .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
      color: $borderdarkgrey;
      margin-top: 3px;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
      margin-top: 3px;
      color: $baseblue;
    }

    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
      @include fluid-type(320px, 1920px, 14px, 15px);
      padding: 0;
      margin-top: 5px;
    }
  }
}

.ant-progress-text {
  color: var(--portfolioText) !important;
}

.ant-progress-bg,
.ant-progress-success-bg {
  background-color: $baseblue !important;
}

// Upload Css---------------

.uploadItem {
  .ant-upload-select {
    box-shadow: 0px 0px 12px #00000012;
    border-radius: 4px;
    background-color: $white;
    width: 100%;
    padding: 34px 24px;
  }

  .ant-btn {
    border: 0;
  }
}

.cursorPointer {
  cursor: pointer;
}

.btcList {
  text-align: right;
  padding-bottom: 5px;
  border-bottom: 1px solid $bordergrey;
}

.customMarketDropdown {
  .ant-dropdown-menu {
    box-shadow: var(--shadowWhite) !important;
  }

  @media (max-width: 480px) {
    position: relative !important;
    overflow: auto !important;
  }

  .ant-dropdown-menu {
    padding: 0 !important;
    border-radius: 10px;

    &-item {
      padding: 0 !important;
      border-radius: 10px;
    }
  }
}

.open_AllOrderTab {
  .ant-tabs-nav-list {
    overflow: auto;
  }

  .viewall {
    color: var(--mainColor);
    @include fluid-type(320px, 1920px, 12px, 12px);
    font-weight: 500;
  }

  .ant-table-tbody {
    .ant-table-cell {
      color: var(--colirbtndiff) !important;
    }
  }

  .ant-table-cell {
    color: var(--smalp);
  }

  .ant-table-cell-row-hover {
    background: var(--hoverWhite) !important;
  }

  .ant-tabs-nav {
    // background-color: var(--tradebg);
  }

  .ant-tabs-tab {
    // padding: 12px 29px !important;

    @media (max-width: 480px) {
      // padding: 12px !important;
    }
  }

  .ant-tabs-tab-btn {
    p {
      @include fluid-type(320px, 1920px, 12px, 12px);
      color: var(--smalp);
    }
  }

  // .ant-tabs-content-holder {
  //   .ant-tabs-tabpane {
  //     height: 250px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //   }
  // }
}

.passPadding {
  input {
    padding-right: 110px;
  }
}

.logo-blue {
  display: inline-block;
}

// .react-tel-input .selected-flag{
//   border: 1px solid $bordergrey !important;
//   border-radius: 20px !important;
//   width: 109px !important;
// }

.walletdropexchange {
  .ant-dropdown {
    .ant-dropdown-menu {
      box-shadow: none !important;
      background-color: transparent;

      &:hover {
        box-shadow: none !important;
      }
    }
  }

  .ant-dropdown-menu-item:hover,
  :active,
  :focus,
  :focus-visible,
  :focus-within {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.noRecordFound {
  text-align: center;
  width: 100%;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background: var(--tableHead);

  p {
    color: rgba(165, 163, 163, 0.85) !important;
  }
}

.nointernet {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 200px;
    margin-bottom: 50px;
  }

  .textDiv {
    text-align: center;

    h4 {
      margin: 0px;
    }
  }
}

.mt10 {
  margin-top: 10px;
}

.stackMinFont {
  font-size: 12px !important;
  margin-top: 5px;
}

// button loading set on position

button.ant-btn {
  .ant-btn-loading-icon {
    display: none;
    position: absolute;
    z-index: 99;
    background: transparent;
    left: 0;
    right: 0;
    opacity: 0.8;
    top: 0px;
    bottom: 0;

    span {
      height: 100%;

      svg {
        display: flex;
        align-content: center;
        justify-content: center;
        height: 100%;
      }
    }
  }
}

.assetInner {
  .dataAssets {
    width: 83px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-bottom: -5px;
    @include fluid-type(320px, 1920px, 14px, 16px);
    margin-right: 6px;
    font-weight: 600;
    color: var(--headerText) !important;
  }

  .dataassetSpan {
    color: $text-80838E !important;
    font-weight: 500;
  }
}

.btnsmall1 {
  color: var(--portfolioText);
  width: fit-content;
  height: 30px;
  padding: 4px;
  min-width: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  small {
    font-size: 12px;
  }
}

.btnsmall2 {
  margin-left: 4px;
}

.moversList {
  display: flex;
  gap: 15px;
  // flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: 1648px) {
    // flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    height: 100%;
  }
}

.cursorAuto {
  cursor: auto;
}

.ant-table-thead {
  cursor: auto;
  // background-color: #fff;
}

.mrt30 {
  margin-top: 30px;
}

.customMarketDropdown.ant-dropdown {
  width: 439px !important;
}

@media (max-width: 767px) {
  .customMarketDropdown.ant-dropdown {
    width: 100% !important;
    z-index: 99 !important;
  }
}

.ant-layout {
  background: transparent !important;
}

//Responsive Layout//
@media (max-width: 767px) {
  .responsiveLayout {
    .logo {
      display: none;
    }

    .ant-layout {
      flex-direction: column !important;
      height: calc(100% - 60px);
      min-height: 100vh;

      &-content {
        width: 100% !important;
        padding-bottom: 130px !important;
      }

      &-sider {
        flex: 1 !important;
        max-width: 100% !important;
        min-width: 100% !important;
        width: 100% !important;
        background: var(--exchngtitle) !important;
        padding-top: 5px;
        padding-bottom: 5px;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 9;
        border-top: 1px solid var(--bordercolor) !important;

        .ant-menu {
          flex-direction: row !important;
          justify-content: space-between !important;
          padding-left: 0 !important;

          &-item {
            border-radius: 30px 30px 0 0 !important;
            border-bottom: 0 !important;
            border-left: 0 !important;
            width: 70px;
            position: relative;
            flex-direction: column;

            &-active,
            &-selected,
            &:hover {
              border-left: 0 !important;
              background-color: transparent !important;
            }

            &-icon {
              height: auto !important;
              padding-top: 10px;
              background-color: transparent !important;
            }
          }

          &-title-content {
            // display: none !important;
            opacity: 1 !important;
            margin: 0 !important;
            height: auto !important;

            span {
              display: block;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

.ant-table {
  tbody tr.ant-table-placeholder {
    td.ant-table-cell {
      display: revert !important;
      background-color: var(--exchngtitle);
    }
  }
}

// .style_settingPage__TLWyB {
//   .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
//     display: none;
//   }
// }

.filer {
  img {
    height: 20px;
    cursor: pointer;
  }
}

.transactionmargin.brder_table {
  @include fluid-type(320px, 1920px, 14px, 16px);

  .ant-table {
    height: 406px;
    overflow: auto;

    table {
      border: 1px solid var(--sidebarbrdr) !important;
    }

    thead {
      th {
        border-top: 1px solid var(--sidebarbrdr) !important;
        background-color: var(--tabMainBg) !important;
      }
    }
  }
}

.transactionmargin {
  margin: 25px 23px !important;
  box-shadow: unset !important;
  background: transparent !important;
}

// .filter_tabs {
//   position: absolute;
//   top: -74px;
//   right: 0px;
//   display: flex;
//   gap: 20px;
//   align-items: center;
//   z-index: 9;

//   .exportBtn {
//     background: var(--cardBackground);
//     padding: 11px 16px;
//     border-radius: 8px;

//     &:hover {
//       color: $baseblue;
//     }
//   }

//   img {
//     margin-right: 10px;
//   }

//   // @media (max-width: 1199px) {

//   // }

//   // @media (max-width: 585px) {
//   //   top: -27px;
//   // }

//   p {
//     cursor: pointer;
//     font-size: 16px;
//     font-weight: 500;
//     color: var(--headerText);

//     &:hover,
//     &.active {
//       color: var(--mainColor);
//     }
//   }

//   svg {
//     height: 22px;
//     width: 22px;
//     cursor: pointer;

//     &:hover,
//     &.active {
//       fill: #00bef2;
//     }
//   }
// }

.centerbutns {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
  gap: 10px;

  // @media (max-width: 991px) {
  //   justify-content: center;
  //   margin-top: 20px;
  // }
}

.buySellTab {
  .ant-tabs-nav::before {
    border-bottom: none !important;
  }
}

.filtrspacing {
  background: transparent var(--cardBackground) no-repeat padding-box;
  margin-bottom: 29px;
  margin-top: 14px;

  .SelectOuter {
    min-width: 150px;
  }

  &_content {
    display: flex;
    align-items: center;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
  }

  .label {
    // display: none !important;
  }

  .cstmselect_style {
    @media (max-width: 767px) {
      flex: 49.5%;
    }

    .SelectOuter {
      display: flex;
      align-items: center;
      gap: 10px;

      @media (max-width: 767px) {
        flex-wrap: wrap;
      }

      .ant-select-selection-item {
        @include fluid-type(320px, 1920px, 12px, 12px);
      }
    }

    .label {
      padding-bottom: 0px;
      @include fluid-type(320px, 1920px, 12px, 12px);
    }
  }

  .inputLayout {
    max-width: 313px;
    width: 100%;

    // margin-right: 20px;
    .inputSearchSend {
      background-color: var(--datePickerBg) !important;
      padding: 10px 30px 10px 20px !important;
      border: none !important;
      color: var(--btntext);
      border-radius: 4px !important;
    }

    .searchImg {
      right: 17px !important;
      left: unset;
      display: flex;
      align-items: center;

      svg {
        path {
          fill: var(--Searchicon) !important;
        }
      }
    }
  }
}

.labelto {
  color: #80838e;
}

.wd20 {
  width: 26% !important;
}

.wd60 {
  width: 48% !important;
}

.swapsec {
  height: auto;
  // min-height: 650px;
  padding-bottom: 10px;
}

.swapPage {
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }

  .ant-tabs-content {
    position: inherit;
  }
}

.anticon.anticon-delete {
  color: $white;
  font-size: 24px;
  font-weight: 600;
  background: $red-CF304A;
  padding: 10px;
  border-radius: 100%;
  transition: 0.3s all;

  &:hover {
    color: $red-CF304A;
    background: $white;
  }
}

.responsive {
  @media (max-width: 767px) {
    .responsiveLayout .ant-layout {
      height: 100%;
    }
  }
}

.overflowText {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.convertHisTable {
  .ant-table {
    // max-height: 450px;
    min-height: calc(100vh - 425px);
  }

  .ant-table-header {
    overflow: visible !important;
  }

  .ant-table-body {
    overflow: visible !important;

    table {
      table-layout: auto !important;
    }
  }

  .ant-table-container {

    &::after,
    &::before {
      content: none !important;
    }
  }
}

.height450Table {
  .ant-table {
    // max-height: 495px;
    max-height: calc(100vh - 425px);
  }
}

.pdFromLeft input {
  padding-left: 30px !important;
}

.flexCenter {
  display: flex;
  align-items: center;
  // justify-content: center;
}

.orderCls {
  color: var(--colirbtndiff) !important;
  width: fit-content;
  min-width: 40px;
  height: 30px;
  padding: 4px;
  border-radius: 10px;

  small {
    font-size: 100% !important;
  }
}

.maintop_swap {
  // background-color: #f3f3f3;
  border-radius: 8px;
  // margin: 20px 0px;
  // padding: 10px 10px;
  position: relative;

  .swapicon {
    text-align: right;
    display: block;
    margin: 0 0 auto auto;
    cursor: pointer;
    margin-right: 20px;
    position: absolute;
    right: 0;
    top: 55px;
    height: 27px;
    width: 27px;
  }

  .swapcurrent {
    height: 23px;
    width: 23px;
  }

  .rotate_iconTransfer {
    transform: rotate(30deg);
  }

  .transfer_swap {
    display: flex;
    // border: 1px solid var(--bordercolor);
    border-radius: 8px;
    gap: 50px;
    margin: 10px 0px;
    align-items: center;
    background: var(--collpasedata);

    &:last-child {
      margin-top: 39px;
    }

    .settheme_span {
      @include fluid-type(320px, 1920px, 14px, 16px);
      color: $text-80838E !important;
    }

    >div {
      display: flex;
      gap: 15px;
      max-width: 120px;
      width: 100%;
      padding: 10px;

      span {
        @include fluid-type(320px, 1920px, 14px, 16px);

        color: #80838e !important;
      }

      p {
        margin: 0px;
        color: #80838e;
      }
    }

    h6 {
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-weight: 600;
      color: var(--headerText);
    }
  }
}

.border_radisremove {
  .card {
    border-radius: 8px;

    span {
      color: var(--headerText);
    }
  }
}

.sendTradeBtn {
  span.leftIcon {
    svg {
      path {
        stroke: #014cec;
      }
    }
  }
}

:where(.css-1m62vyb).ant-select-single.ant-select-open .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.25);
  text-transform: capitalize;
}

.coinlistupload {
  display: inline-block;
  word-break: break-all;
  margin-right: 4px;

  @media (max-width: 480px) {
    margin-right: 12px;
  }
}

.text {
  word-break: break-word;
}

.submitapplication {
  margin-right: 30px;

  @media (max-width: 767px) {
    margin-right: 0;
  }
}

.breackSpace {
  white-space: break-spaces;
}

.settingtabs {
  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
    border-radius: 10px;
  }
}

// Existing styles remain unchanged
// Add styles for enabling scrolling on wider screens
.scroll-enabled {
  @media screen and (min-width: 728px) {
    overflow-x: scroll;
  }
}

// Add styles for disabling scrolling on smaller screens
.scroll-disabled {
  @media screen and (max-width: 727px) {
    overflow-x: hidden;
  }
}

.centerCaptcha {
  text-align: center;
  margin: 15px;

  @media (max-width: 767px) {
    margin: 15px 0px;
  }

  iframe {
    @media (max-width: 445px) {
      width: 265px !important;
    }

    @media (max-width: 365px) {
      width: 250px !important;
    }
  }
}

.marginTB {
  margin: 24px 0px;
}

.text_size {

  // margin-bottom: 10px;
  .label {
    font-size: 14px;
  }
}

.export_modal {
  .ant-modal-content {
    padding: 25px;
  }

  .centerbutns {
    margin-top: 20px;
    margin-bottom: 10px;

    button {
      width: 50%;
    }
  }

  .titleModal {
    margin-bottom: 30px !important;
  }
}

.dollarValue {
  color: var(--smalp) !important;
  margin-left: 8px;
  font-size: 12px;
}

.ant-dropdown {
  z-index: 1 !important;
}

.submissionModal {
  h2 {
    @include fluid-type(320px, 1920px, 16px, 20px);
    color: #000000;
    text-align: center;
    font-weight: 600;
  }

  p {
    @include fluid-type(320px, 1920px, 12px, 14px);
    color: #80838e;
    text-align: center;
    line-height: 22px;
    padding-top: 10px;
    padding-bottom: 25px;
  }

  .submisionBtn {
    width: 100%;
    max-width: 185px;
  }
}

.comingSoon {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--colirbtndiff);
  @include fluid-type(320px, 1920px, 24px, 30px);
  background-color: var(--exchngtitle);
  font-family: $baseFontRegular;
  font-weight: 700;
}

.stakingModal {
  .kycModal {
    padding: 0px !important;
  }
}

.buySellModal {
  .ant-modal-close {
    top: 0px !important;
    right: 0px !important;
  }
}

.createaccountsec {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#why-us {
  background-image: var(--OurHistoryBg);
  // padding: 70px 0;
  padding-top: 97px;
  background-position: center;
  background-size: cover;

  @media (max-width: 991px) {
    padding: 30px 0;
  }

  @media (max-width: 767px) {
    padding: 20px 0;
  }

  p {
    color: var(--smalp);
    line-height: 114%;
  }

  .aboutus-text {
    color: var(--mainColor);
    font-size: 16px;
    margin-bottom: 15px;
    opacity: 1 !important;

    @media (max-width: 767px) {
      margin-bottom: 6px;
    }
  }
}

.mainLayout .ant-menu {
  // .ant-menu-item {
  //   border-radius: 12px !important;
  // }

  .ant-menu-item-selected:hover {
    .ant-menu-item-icon svg {
      path {
        fill: var(--mainColor);
      }

      rect {
        fill: var(--mainColor) !important;
      }
    }
  }

  // .ant-menu-item-active {
  // background-color: $white !important;
  // }
}

.ant-carousel .slick-dots li {
  display: none !important;
}

.cryptotable {
  margin: auto;
  border-radius: 7px;
  overflow-x: auto;
  border: none;

  .ant-table-content {
    table {
      background-color: $white;

      tr {
        td {
          cursor: pointer !important;
        }
      }
    }
  }

  thead.ant-table-thead {
    border-radius: 7px;
    background: $bg-F3F2F6;
    height: 66px;
    margin-bottom: 80px !important;

    tr {
      th.ant-table-cell {
        color: $darktext;
        @include fluid-type(320px, 1920px, 12px, 14px);

        &::before {
          display: none;
        }
      }
    }
  }

  .ant-table-thead>tr>th {
    background-color: transparent;
    white-space: nowrap;

    &:first-child {
      padding-left: 3.3rem;
    }

    &::before {
      width: 0rem;
    }
  }

  .ant-table {
    background: transparent !important;
    color: $text-80838E;
    border-radius: 7px;

    table {
      padding: 5px;
      border-collapse: separate;
      border-spacing: 0 15px;
      background: transparent !important;

      thead {
        background: transparent !important;

        tr {
          background: transparent;

          th {
            background: $bg-F3F2F6;
          }

          th:first-child {
            border-top-left-radius: 7px !important;
            border-bottom-left-radius: 7px !important;
          }

          th:last-child {
            border-top-right-radius: 7px !important;
            border-bottom-right-radius: 7px !important;
          }
        }
      }

      tbody {
        background: transparent !important;

        tr {
          border-radius: 7px;
          background: transparent !important;

          td {
            background: $white;
          }

          td:first-child {
            border-radius: 7px 0 0 7px !important;
          }

          td:last-child {
            border-radius: 0 7px 7px 0 !important;
          }
        }
      }
    }

    tbody.ant-table-tbody {
      tr.ant-table-row {
        background-color: $white;
        box-shadow: 0px 0px 12px #00000017;
        border-radius: 7px;

        &.ant-table-row-level-0 {
          &:nth-child(even) {
            background: $white;
          }

          .ant-table-cell {
            img {
              border-radius: 24px;
            }
          }

          td.ant-table-cell {
            font-size: 1.6rem;
            @include fluid-type(320px, 1920px, 14px, 16px);
            font-weight: 400;
            color: $darktext;

            img {
              border-radius: 24px !important;

              @media (max-width: 991px) {
                width: 25px;
                height: 25px;
                max-width: none;
              }
            }

            &.ant-table-cell-row-hover {
              background-color: var(--hoverWhite);
            }

            &:first-child {
              padding-left: 3.3rem;
              font-weight: 600;
            }
          }
        }
      }
    }

    .graytext {
      color: $text-80838E;
      font-size: 1.6rem;
    }

    .ant-table-tbody>tr>td {
      font-size: 1.4rem;
      border: none;
      white-space: nowrap;

      img {
        height: 35px;
        width: 35px;
      }

      canvas {
        height: 30px !important;
        width: 65px !important;
      }
    }
  }
}

///////////---Trading-----/////////////

.tradingBotsHeading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: end;
  flex-flow: wrap-reverse;
  gap: 20px;

  .walletView {
    margin-bottom: 9px;
    border-radius: 32px;
    height: 50px;
    white-space: nowrap;
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
      height: 32px;
      margin-bottom: 20px;
    }

    @media (max-width: 573px) {
      width: 100%;
      margin-bottom: 0;
    }

    &__buttons {
      border-radius: 32px;
      border: 0;
      max-width: 140px;
      width: 100%;
      background-color: #18384e !important;
      @include fluid-type(320px, 1920px, 14px, 16px);
      color: $white;
      font-weight: 600;
      padding: 6px 33px;
      height: 50px;
      cursor: pointer;
      transition: 0.3s all;

      &:first-child {
        border-radius: 12px 0px 0px 12px;
      }

      &:last-child {
        border-radius: 0px 12px 12px 0px;
      }

      @media (max-width: 1366px) {
        max-width: 100%;
      }

      @media (max-width: 767px) {
        padding: 5px 20px;
      }

      @media (max-width: 575px) {
        height: 35px;
      }

      &__active,
      &:hover {
        background: transparent linear-gradient(113deg, #00bef2 0%, #008db4 47%, #005360 100%) 0% 0% no-repeat padding-box;
        color: $white !important;
      }
    }
  }

  &_card {
    @media (max-width: 573px) {
      width: 100%;
    }

    .portfolioCard {
      @media (max-width: 573px) {
        max-width: 100%;
      }
    }
  }
}

.ustdCoin {
  display: flex;
  align-items: center;

  .spotitems {
    display: flex;
    flex-direction: column;
    color: var(--smalp);

    b {
      color: var(--btntext);
    }
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 23px;
    margin-right: 8px;
    box-sizing: content-box !important;
  }
}

.baseblue {
  color: $baseblue;
}

.redFF593D {
  color: $redFF593D;
}

.green129D26 {
  color: $green129D26;
}

///////////////----------Buy Sell -------- //////////
.buySellTabs {
  border-radius: 32px;
  height: 35px;
  white-space: nowrap;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    height: 32px;
  }

  &__btns {
    border-radius: 32px;
    border: 0;
    max-width: 140px;
    width: 100%;
    font-size: 12px;

    @media (max-width: 1366px) {
      max-width: 100%;
    }

    &:first-child {
      border-radius: 8px 0 0 8px;
      font-size: 12px;
      color: $text-80838E;
      font-weight: 700;
      background-color: var(--btnthmnew);
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
      color: $text-80838E;
      background-color: var(--btnthmnew);
      font-size: 12px;
      font-weight: 700;
    }

    @include fluid-type(320px, 1920px, 14px, 16px);
    color: $darktext;
    font-weight: 500;
    padding: 6px 33px;
    height: 40px;

    @media (max-width: 767px) {
      padding: 5px 20px;
    }

    @media (max-width: 575px) {
      height: 35px;
    }

    cursor: pointer;

    &.buySellTabsactuve {
      background: var(--btnbgclr);
      color: var(--clrbtngreenWhite);
    }

    &--active {
      border-radius: auto 0px 0px auto;
      background: rgb(252, 79, 82);
      // box-shadow: 0px 0px 12px;
      color: $white !important;
    }

    .sellRed {
      background-color: $redgradbtn;
    }
  }
}

.kycContainer {
  // padding: 30px;
  // padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  // background-color: $white;
  // background-color: var(--dark-background);

  iframe {
    max-width: 650px !important;
    border-radius: 4px;
    // box-shadow: var(--shadowifram);
    box-shadow: 0 3px 6px -4px rgba(135, 131, 131, 0.12),
      0 6px 16px 0 rgba(145, 133, 133, 0.08),
      0 9px 28px 8px rgba(237, 232, 232, 0.1);
  }

  h1 {
    @include fluid-type(320px, 1920px, 20px, 30px);
    font-weight: 500;
    color: var(--light-text) !important;
    margin-bottom: 40px;
  }
}

.gridToggleView {
  display: flex;
  gap: 16px;

  .gridTogglbtns {
    cursor: pointer;
  }

  svg {
    cursor: pointer;

    ellipse,
    path {
      fill: var(--sidebarColor);
    }
  }

  .gridactive {
    ellipse {
      fill: var(--dashtoggle);
    }

    svg {
      path {
        fill: var(--dashtoggle) !important;
      }
    }
  }
}

// iframe {
//   html  body{
//     display: flex !important;
//     flex-direction: column !important;
//     align-items: center !important;
//     height: 100% !important;
//    > div {
//       display: flex !important;
//     flex-direction: column !important;
//     align-items: center !important;
//     height: 100% !important;
//     }
//   }
// }

.app-view-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .ant-tooltip-open {
//   border-color: $baseblue !important;
// }

.ant-slider-track {
  background-color: $baseblue !important;
}

.ant-slider-mark-text {
  color: var(--smalp) !important;
}

.ant-slider-handle {
  border: 2px solid $baseblue !important;
}

.dot {
  width: 6px;
  height: 6px;
  background: $baseblue;
  vertical-align: middle;
  border-radius: 4px;
  display: -webkit-inline-box;
}

.ExtraRightAction {
  .groupBtn {
    background-color: var(--themegraybtn);
    border-radius: 8px;
    padding: 6px;

    .ant-btn {
      background-color: #fcfcfe;
      color: #897ea3;
    }

    button.ant-btn-sm {
      border-radius: 8px;
      @include fluid-type(320px, 1920px, 10px, 12px);
      height: 32px;
    }
  }
}

.provider_Modal {
  .mt-20 {
    margin-top: 20px;
  }

  .ant-modal-body {
    padding: 25px;

    h3 {
      font-weight: 600;
      @include fluid-type(320px, 1920px, 16px, 20px);
      color: var(--headerText);
    }

    .modalBtn {
      button {
        display: block;
        margin: auto;
      }
    }

    .listnum {
      padding-left: 15px;
      list-style: decimal;
      margin-top: 24px;
      color: var(--headerText);

      li {
        line-height: normal;
        margin-bottom: 15px;
        color: var(--headerText);
        @include fluid-type(320px, 1920px, 14px, 16px);
      }
    }

    .listdisc {
      padding-left: 15px;
      list-style: disc;
      margin-top: 24px;

      li {
        line-height: normal;
        margin-bottom: 15px;
        color: var(--headerText);
        @include fluid-type(320px, 1920px, 12px, 14px);
      }
    }
  }

  .checkboxCustom {
    .checkboxTxt {
      color: var(--smalp) !important;
      font-weight: 600;
    }
  }

  .modalBtn {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    button {
      width: 49%;
    }
  }
}

.succesModal {
  text-align: center;

  p {
    font-weight: 600;
    @include fluid-type(320px, 1920px, 16px, 20px);
    color: var(--headerText);
  }

  button {
    width: 250px;
    margin: auto;

    margin-top: 20px;
  }

  svg {
    text-align: center;
    margin: 20px 0px;
    height: 60px;
  }
}

.comman-innerpage-padding {
  padding: 30px 38px 30px 50px;

  @media (max-width: 767px) {
    padding: 30px 15px 30px 15px;
  }
}

.theme-light {
  .referalProgram {
    background-image: url("./Components/Assets/Images/backblueLight.png");
  }
}

// ======================.Custom-table ==================
.theme-light {
  .Custom-table {
    .ant-empty {
      .ant-empty-image {
        background-image: url("./Components/Assets/Images/recordsNotFoundLight.svg");
      }
    }
  }
}

.Custom-table {
  border-radius: 4px;
  overflow: hidden;

  .ant-table-row-expand-icon-cell {
    text-align: unset;
  }

  // ===================.ant-empty ,Not Data found===========
  .ant-empty {
    .ant-empty-image {
      min-height: 285px;
      background-image: url("./Components/Assets/Images/recordsNotFound.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 115px 87px;
      position: relative;

      &::after {
        content: "No records found.";
        position: absolute;
        left: 50%;
        bottom: 25%;
        transform: translate(-50%);
        @include fluid-type(320px, 1920px, 14px, 18px);
        font-weight: 500;
        color: var(--headerText);
      }

      svg {
        display: none;
      }
    }

    .ant-empty-description {
      display: none;
    }
  }

  .portfolioTable {
    div.ant-table {
      table {
        tr {

          th,
          td {
            padding: 16px 28px;
            // border-bottom: none !important;
            /*overwrite===*/
          }
        }
      }
    }
  }
}

// ============================,Not Data found=======/

button.ant-btn-link {
  @include fluid-type(320px, 1920px, 12px, 14px);
  color: #00a79e;
  font-weight: 400;

  img {
    width: 18px;
    height: 18px;
    display: inline-block;
  }
}

// ======detailOptions======
.detailOptions {
  background-color: #2a688b0f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 28px 0 28px;
  border-radius: 8px;
  padding: 17px 24px;

  p {
    color: #897ea3;
    font-weight: 600;

    span {
      color: #2a688b;
    }

    .anticon-copy {
      svg {
        fill: #00a79e;
      }
    }
  }
}

div.ant-card {
  background-color: var(--setingCardBg);
  border-color: #00a3d0;
  border-radius: 24px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 36px 24px;
  transition: all 0.2s linear;
}

// ===============Graph-card==================
.Graph-card {
  div.ant-card {
    box-shadow: 0px 3px 16px #00000017;
    border-radius: 4px;
    padding: 24px;
    // background: var(--light-E5EDF4);

    &-bordered {
      border: 1px solid #d7d4e0;
      box-shadow: none;
    }

    .ant-card-head {
      border-bottom: none;
      padding: unset;

      .ant-card-head-title {
        @include fluid-type(320px, 1920px, 16px, 20px);
        font-weight: 600;
        color: var(--headerText);
      }

      .ant-card-extra {
        @include fluid-type(320px, 1920px, 16px, 14px);
        font-weight: 600;
        color: var(--headerText);
      }

      .ant-card-head-title,
      .ant-card-extra {
        padding: unset;
      }
    }
  }

  .ant-card-body {
    svg {
      text {
        color: var(--headerText);
        fill: var(--headerText);
      }
    }
  }
}

// =======================.custom-tag===========
.custom-tag {
  .ant-tag {
    color: #1ea0ab;
    padding: 6px 12px;
    border: unset;
    background-color: rgba(30, 160, 171, 0.1);
    @include fluid-type(320px, 1920px, 10px, 12px);
    border-radius: 8px;
    font-weight: 500;
  }

  &.white {
    h4.ant-typography {
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-weight: 600;
    }

    .ant-tag {
      color: #897ea3;
      background-color: #ffffff;
      padding: 6px 24px;
      font-weight: 500;
    }
  }
}

// <============= ADD TAG COMPONENT ========================>
.userEdit {
  display: flex;
  gap: 20px;

  @media (max-width: 1220px) {
    flex-direction: column;
  }

  .left_usericon {
    background: #18384e;
    border-radius: 4px;
    padding: 19px 50px;
    text-align: center;
    max-width: 265px;
    width: 100%;

    @media (max-width: 1220px) {
      margin: auto;
    }

    h4 {
      margin-top: 15px;
      font-weight: 600;
      color: var(--light-text);
    }

    svg {
      cursor: pointer;
      margin-left: 10px;
      height: 13px;
      width: 13px;

      // color: var(--light-text);
      path {
        fill: var(--light-text);
      }
    }
  }
}

.traderblock-Detail {
  background-color: var(--light-E5EDF4);
  border-radius: 4px;
  padding: 24px;
  box-shadow: rgba(196, 196, 196, 0.28) 0px 2px 10px 0px;

  p {
    @include fluid-type(320px, 1920px, 10px, 14px);
    font-weight: 500;
    color: var(--headerText);
    padding-top: 14px;
  }

  .detail {
    padding: 10px 0;

    .joined {
      p {
        padding-top: 0;
      }
    }

    .profitRatio {
      font-weight: 600;
      @include fluid-type(320px, 1920px, 18px, 20px);
    }

    &_investment {
      display: flex;
      align-items: center;
      gap: 200px;
      margin-bottom: 10px;

      @media (max-width: 991px) {
        gap: 20px;
        justify-content: space-between;
      }

      @media (max-width: 567px) {
        gap: 10px;
      }

      @media (max-width: 486px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    span {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      // margin-right: 15px;
      font-weight: 600;

      span {
        color: var(--headerText);
        @include fluid-type(320px, 1920px, 12px, 14px);
      }

      .amount {
        font-weight: 500;
        color: #b0b0b2 !important;
        @include fluid-type(320px, 1920px, 12px, 14px);
      }
    }
  }

  h4 {
    color: #fff !important;
  }
}

.botDetailShow {
  padding-bottom: 18px;

  .detailshow {
    padding-top: 10px;

    strong {
      color: var(--headerText);
      font-weight: 500;
      @include fluid-type(320px, 1920px, 12px, 16px);
    }

    a {
      color: var(--light-b0b0b2);
    }
  }
}

.botDetails {
  background-color: var(--tradebg);
  border-radius: 4px;
  padding: 30px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .ant-tabs-nav::before {
    border: unset !important;
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab {
    padding: 0 20px;
    color: var(--headerText);
  }

  .ant-tabs-tab-active {
    background: var(--themebgbtn) !important;
    padding: 4px 15px;
    border-radius: 4px;

    .ant-tabs-tab-btn {
      color: #ffffff !important;
    }
  }

  .ant-tabs-tab {
    &:hover {
      color: var(--headerText);
    }
  }

  .traderblock-Detail {
    background-color: var(--light-E5EDF4);
    margin-bottom: 20px;
  }

  .detail {
    border-top: 1px solid var(--bordercolor);
    padding-top: 20px;
    padding-bottom: 0;

    small {
      color: var(--headerText);
    }

    strong {
      color: var(--headerText);
    }
  }

  .ant-tabs-tab {
    padding: 5px 20px !important;
  }

  .ant-tabs-tab-btn {
    font-size: 16px !important;

    &:hover {
      // color: #fff !important;
    }
  }
}

// ====================TraderDetail-page==================

// ====================TraderDetail-page==================
.TraderDetail-page {
  .statistics {
    .ant-statistic {
      margin-bottom: 16px;

      .ant-statistic-title {
        color: var(--headerText);
        font-weight: 500;
      }

      .ant-statistic-content {
        font-weight: 600;
        color: var(--headerText);
        @include fluid-type(320px, 1920px, 18px, 24px);
      }
    }
  }

  .Cumulative {
    .curveChangeBtn {
      button.ant-btn {
        @include fluid-type(320px, 1920px, 12px, 14px);
        font-weight: 600;

        span {
          color: var(--headerText);
        }

        &.active {
          img {
            filter: invert(43%) sepia(91%) saturate(486%) hue-rotate(129deg) brightness(98%) contrast(101%);
          }
        }
      }
    }
  }
}

.Roundtable-outer {
  margin-top: 24px;
  border-radius: 4px;
  overflow: hidden;
}

.InputGroup {
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #00a79e;
    box-shadow: 0 0 0 2px rgba(30, 160, 171, 0.2);
  }

  * {
    .ant-select-focused {
      box-shadow: 0 0 0 2px rgba(30, 160, 171, 0.2);
      border-color: #00a79e;
    }
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #00a79e;
  }

  .ant-input-group.ant-input-group-compact>.ant-select:first-child>.ant-select-selector,
  .ant-input-group.ant-input-group-compact> :first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .ant-input-group.ant-input-group-compact> :last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 44px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 44px;
  }

  label {
    font-weight: 400;
    display: block;
    color: var(--headerText);
    padding-bottom: 8px;
  }

  .ant-select-selector,
  .ant-input-affix-wrapper {
    height: 44px;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: #00a79e;
    box-shadow: 0 0 0 2px rgba(30, 160, 171, 0.2);
    border-right-width: 1px;
    outline: 0;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #00a79e;
  }
}

.add_tag {
  background-color: #18384e;
  padding: 20px;
  border-radius: 4px;
  max-width: 82%;
  width: 100%;

  @media (max-width: 1220px) {
    max-width: 100%;
  }

  .heading_add {
    font-size: 14px;
    font-weight: 600;
    color: var(--light-text);
  }

  .customize_check {
    margin-top: 14px;

    .ant-checkbox {
      display: none;
      padding: 10px;
    }

    .ant-space-item {
      color: var(--headerText);
    }

    .ant-checkbox-wrapper {
      align-items: center;
      margin-bottom: 5px;
    }

    .ant-checkbox-group {
      .ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-group-item {
        background: transparent linear-gradient(92deg, #2a688b 0%, #1ea0ab 100%) 0% 0% no-repeat padding-box;
        opacity: 1;

        span {
          color: #fff;
        }
      }

      .ant-checkbox-wrapper.ant-checkbox-group-item {
        background-color: rgba(30, 160, 171, 0.1);
        height: 30px;
        border-radius: 8px;

        span {
          color: #1ea0ab;
          opacity: 1;
        }
      }
    }
  }

  .text_areaDesc {
    p {
      margin-top: 20px;
      font-weight: 600;
      font-size: 14px;
      color: var(--light-text);

      span {
        margin-left: 7px;
        cursor: pointer;
      }
    }

    textarea {
      margin-top: 7px;
      background: var(--cardsec);
      border-radius: 7px;
      max-width: 900px;
      width: 100%;
      border: 0;
      color: var(--headerText);
    }

    svg {
      path {
        fill: var(--light-text);
      }
    }
  }

  .checkItems {
    margin-top: 20px;

    p {
      @include fluid-type(320px, 1920px, 12px, 12px);
      color: var(--light-text);
    }

    .checkBoxOuter {
      display: flex;
    }

    span {
      color: var(--light-text);
    }

    .ant-checkbox-group {
      margin-top: 12px;
    }
  }
}

.labelAmount {
  margin-top: 12px;

  .ant-input-suffix {
    color: #fff;
  }
}

.tradingSec {
  .ant-card {
    min-height: 298px;

    .ant-card-body {
      margin-top: 28px;

      @media (max-width: 503px) {
        margin-top: 0;
      }
    }
  }

  .input_textimg {
    margin-top: 12px;

    .ant-input-suffix {
      color: #fff !important;
    }
  }

  p {
    color: var(--light-text);
    @include fluid-type(320px, 1920px, 14px, 16px);
  }

  .marketdrop {
    padding: 0;
    margin-top: 12px;

    input {
      border: 1px solid var(--sidebarbrdr);
      // background: var(--dark_input) !important;
    }
  }
}

.commanBgFull {
  margin-top: 90px;
}

.input_textimg {
  .ant-input-suffix {
    color: #fff !important;
  }
}

.MyAssetChart {
  .dotnut_chart {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // display: flex;
  // align-items: center;
  // justify-content: space-between;

  // @media (max-width:1249px) {
  //   flex-wrap: wrap;
  //   justify-content: center;
  //   gap: 30px;
  // }

  .canvas_height {
    text-align: center;
    // height: 300px;

    // @media (min-width:1700px) {
    //   width: 270px;
    // }

    // @media (max-width:480px) {
    //   width: 100% !important;
    // }

    // @media screen and (min-width: 1200px) and (max-width: 1550px) {
    //   height: auto !important;
    //   width: auto !important;
    // }

    >canvas {
      // margin: auto;
      // height: 301px !important;
      // width: 301px !important;

      // @media (max-width:1760px) {
      //   height: 251px !important;
      //   max-width: 251px !important;
      //   width: 100% !important;
      // }

      // @media (min-width: 1250px) and (max-width: 1550px) {
      //   height: 200px !important;
      //   max-width: 200px !important;
      //   width: 100%;
      // }
    }
  }

  .graph_text {
    max-width: 300px;
    width: 100%;

    ul {
      border: 1px solid #e1e1e1;
      padding-left: 0;
      border-radius: 4px;

      li {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e1e1e1;

        &:nth-child(2) {
          p {
            color: var(--light-text);

            &:first-child {
              position: relative;
              padding-left: 10px;

              &::after {
                content: "";
                height: 10px;
                width: 10px;
                background-color: #1e73ab;
                position: absolute;
                left: -5px;
                top: 7px;
              }
            }
          }
        }

        &:last-child {
          border: 0;

          p {
            color: var(--light-text);

            &:first-child {
              position: relative;
              padding-left: 10px;

              &::after {
                content: "";
                height: 10px;
                width: 10px;
                background-color: #59d471;
                position: absolute;
                left: -5px;
                top: 7px;
              }
            }
          }
        }

        &:first-child {
          p {
            color: var(--light-text);

            &:first-child {
              position: relative;
              padding-left: 10px;

              &::after {
                content: "";
                height: 10px;
                width: 10px;
                background-color: #1ea0ab;
                position: absolute;
                left: -5px;
                top: 7px;
              }
            }
          }
        }
      }
    }
  }
}

.Green-select {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: unset;
    background-color: rgba(30, 160, 171, 0.1);
    border-radius: 8px;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }

  span.anticon-down {
    svg {
      fill: #1ea0ab;
    }
  }

  span.ant-select-selection-item {
    color: #1ea0ab;
  }
}

.fullwidthTabs {
  .Custom-tabs .ant-tabs {
    .ant-tabs-nav {
      padding: 6px;
      background-color: #f1eff5;
      border-radius: 8px;

      .ant-tabs-nav-list {
        width: 100%;

        .ant-tabs-tab {
          width: 50%;
          align-items: center;
          justify-content: center;

          &-btn {
            text-align: center;
          }
        }
      }
    }
  }
}

.graph_assets {
  display: flex;
  gap: 26px;
  // height: auto;

  .sameheight {
    // height: 100%;
    // width: 50%;
    padding: 20px;
    flex: 1;
    background-color: white;
    border-radius: 4px;
  }
}

.cards-container {
  display: flex;
  flex-direction: column;
}

.title-container {
  margin-bottom: 10px;
}

.cards-wrapper {
  display: flex;
  flex: 1;
}

.custom-card {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card-content {
  flex: 1;
  overflow: auto;

  /* Allow content to scroll if it exceeds the card height */
}

.copytrad {
  // border: 0!important;

  // border: 1px solid var(--bordercolor);
  .ant-input-group-addon {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .ant-input {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .input_amount {
    border: 0 !important;
    border-radius: 10px;
  }

  .ant-input-wrapper.ant-input-group,
  .ant-input {
    height: 38px !important;
  }

  .ant-select {
    width: 150px !important;
  }
}

.copytradingmodal {
  .ant-modal-content {
    padding: 17px 38px 32px 26px !important;
  }

  .advancedOption {
    color: var(--smalp);
  }
}

.editButton {
  color: $baseblue !important;
  vertical-align: middle;
}

.buyOne {
  width: 34px;
  height: 24px;
  text-align: center;
  color: $baseblue;
  background: #1ea0ab33 0% 0% no-repeat padding-box;
}

.sellOne {
  width: 34px;
  height: 24px;
  text-align: center;
  color: $redFF593D;
  background: #ff593d33 0% 0% no-repeat padding-box;
}

.selling {
  color: $redFF593D !important;
}

.runningOrder {
  .ant-tabs-tab {
    font-size: 14px !important;
    font-weight: 600;
    background: #f1eff5 0% 0% no-repeat padding-box;
    border-radius: 12px;
    color: $darktext !important;
  }

  .ant-tabs-nav-wrap {
    border-radius: 4px;
  }

  .ant-tabs-nav {
    font-size: 14px !important;
    font-weight: 600;

    &::before {
      border: none !important;
    }
  }

  .Completed {
    background: var(--gridCard) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 24px 36px;
    margin: 16px 0;
    // table{
    //   width: 100%;
    //   tr{
    //     :first-child{
    //       text-align: left;
    //       color:$text-80838E;
    //       :nth-child(1){
    //         @include fluid-type(320px, 1920px, 14px, 16px);
    //         color: $darktext;
    //       }
    //     }
    //     :last-child{
    //       text-align: right;
    //       color: $darktext;
    //     }
    //   }
    // }
  }

  .ant-tabs-tab-btn {
    font-weight: 600;
  }

  .ant-tabs-nav-list {
    font-size: 14px !important;
    font-weight: 600;
    width: 100%;
    background: var(--setingCardBg);
    box-shadow: 0px 3px 16px #00000017;
    border-radius: 4px;
    padding: 13px 16px;
    border: none;
    gap: 16px;

    .ant-tabs-ink-bar {
      background: none !important;
    }

    .ant-tabs-tab-active {
      background: transparent linear-gradient(157deg, $darktext 0%, #00bef2 100%) 0% 0% no-repeat padding-box;
      border-radius: 12px;
      font-size: 14px;
      border-bottom: none;

      .ant-tabs-tab-btn {
        font-weight: 600;
        color: $white !important;
      }
    }
  }
}

.tradetab.exchangePagetabs {
  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2px solid var(--mainColor);
    text-align: center;
  }

  .wallettabs {
    .ant-tabs-tab.ant-tabs-tab-active {
      border-bottom: 0 !important;
    }
  }
}

.accountActivityTab {
  margin-top: 20px !important;

  .ant-tabs-nav {
    padding: 15px 17px;
    background: var(--setingCardBg);
    border-radius: 4px;

    &-list {
      background: var(--tabsBg);
      border-radius: 4px !important;
    }
  }

  .ant-tabs-nav-wrap {
    .ant-tabs-tab {
      padding: 4px !important;
      gap: 10px;

      &-btn {
        @include fluid-type(320px, 1920px, 12px, 14px);
        color: var(--smalp);
        font-weight: 600;
        padding: 9px 15px;
        border-radius: 4px;
      }
    }
  }

  .ant-tabs-nav-list {
    .ant-tabs-tab {
      border: 0 !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        padding: 9px 15px;
        color: var(--mainColor) !important;
        background: var(--tabBtnBg);
      }
    }
  }

  .ant-tabs-nav::before {
    border: 0 !important;
  }
}

.nextmodal_tabcss {

  .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-top .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none !important;
  }

  .ant-tabs-nav-list {
    border-radius: 10px;
    overflow: hidden;

    .ant-tabs-tab.ant-tabs-tab-active {
      background: transparent linear-gradient(113deg, #00bef2 0%, #008db4 47%, #005360 100%) 0% 0% no-repeat padding-box;

      .ant-tabs-tab-btn {
        color: #fff !important;
      }
    }

    .ant-tabs-tab-btn {
      user-select: none;
      text-align: center;
      width: 100%;
    }

    .ant-tabs-tab {
      background: #18384e 0% 0% no-repeat padding-box;
    }
  }

  .ant-tabs-nav {
    &:before {
      border-bottom: 0 !important;
    }
  }

  h1 {
    @include fluid-type(320px, 1920px, 20px, 24px);
    font-weight: 600;
    letter-spacing: 0px;
    margin-top: 50px;
    color: var(--headerText);
  }

  .tab_ownAcc {
    p {
      @include fluid-type(320px, 1920px, 14px, 16px);
      color: var(--headerText);
      text-align: left;
      margin-top: 20px;
    }

    h1 {
      color: var(--headerText);
      text-align: left;
      margin-top: 20px;
    }

    .modalBtn {
      display: flex;
      gap: 20px;
      margin-top: 30px;

      >button {
        flex: 0 48%;
      }
    }

    .ant-slider-with-marks {
      margin-bottom: 55px;
    }
  }

  .ant-tabs-tab {
    min-width: 175px;
    width: 100%;

    @media (max-width: 480px) {
      min-width: 125px;
    }

    @media (max-width: 350px) {
      min-width: 100px;
    }
  }

  .confirmbtn {
    button {
      width: 100%;
    }
  }
}

.allNotification .ant-collapse {
  .ant-collapse-item {
    background-color: var(--accordBg) !important;
  }
}

.ant-collapse {
  background-color: unset !important;
  border: none !important;
  border-radius: 20px !important;

  .ant-collapse-header-text {
    color: var(--headerText) !important;
  }

  .ant-collapse-content {
    background-color: var(--gridCard) !important;
    border-top: 0;
  }

  .ant-collapse-header {
    // padding: 0 !important;
  }

  .ant-collapse-expand-icon {
    position: absolute;
    right: 0;
    color: var(--btntext);

    .ant-collapse-arrow {
      margin: 0 !important;

      svg {
        transform: rotate(90deg);
      }
    }
  }

  .ant-collapse-item-active {
    border-radius: 0 !important;

    .ant-collapse-expand-icon {
      .ant-collapse-arrow {
        svg {
          transform: rotate(270deg) !important;
        }
      }
    }

    // .ant-collapse-expand-icon {
    //   svg{
    //     transform: rotate(170deg);      }
    //   }
  }

  .ant-collapse-item {
    border-radius: 17px !important;
    border: none !important;
    // background-color: var(--accordBg) !important;
  }

  .ant-collapse-content-box {
    background-color: var(--gridCard) !important;
    // padding: 0 !important;

    tr {
      background-color: var(--collpasedata);

      :first-child {
        text-align: left;
        color: $text-80838E;

        :nth-child(1) {
          @include fluid-type(320px, 1920px, 14px, 16px);
          color: $darktext;
        }
      }

      :last-child {
        color: var(--btntext);
      }
    }
  }
}

.underLine {
  border-bottom: 1px dashed #000;
  @include fluid-type(320px, 1920px, 12px, 14px);
  color: $text-80838E;
}

.orderBookTab {

  // background-color: red;
  .ant-tabs-nav {
    position: absolute !important;
    top: 4px;
    left: 5%;

    &::before {
      display: none;
    }
  }

  .ant-tabs-nav-list {
    display: flex;
    gap: 12px;

    .ant-tabs-tab {
      opacity: 0.5;
    }

    .ant-tabs-tab-active {
      opacity: initial;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab {
    padding: 0 !important;
  }

  // .ant-tabs-content-holder {
  //   .ant-tabs-tabpane-active {
  //     height: 507px;
  //   }
  // }
  .asks {
    .askHeight {
      height: 416px;
      overflow-y: auto;
    }
  }
}

.profileinputs {
  .input_imgcontent {
    top: 70% !important;
    cursor: pointer;
  }

  button {
    @media (max-width: 767px) {
      margin-top: 25px;
    }
  }
}

.devicemanagement {
  padding: 30px 38px 30px 50px;
}

.deleteModal .ant-modal-content {
  border-radius: 4px;
  text-align: center;
  background: var(--modalBg);

  p {
    color: var(--headerText);
  }

  .ant-btn {
    background: transparent;
    color: var(--mainColor);
    border-radius: 8px;
    border: 1px solid var(--mainColor);
    padding: 0 40px;
  }

  .ant-btn-primary {
    background: var(--mainColor) !important;
    color: var(--btnColor);
  }

  .ant-modal-footer {
    padding: 20px 16px;
    text-align: center;
    border-top: 1px solid var(--sidebarborder);
  }
}

.delButton {
  padding-left: 0 !important;
}

.ant-empty-description {
  color: var(--smalp);
}

.disableLink {
  pointer-events: none;
  color: #938a8a !important;
}

.transak_widget {
  .transak_modal-overlay {
    background-color: #000000d6;
    z-index: 1001;
  }

  .transak_modal {
    z-index: 1002 !important;
  }
}

.buySellError {
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
}

.themeIcon {
  margin-left: 24px;
  cursor: pointer;
}

.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: unset !important;
}

.exchange-table {
  table {
    thead {
      tr {
        th {
          color: var(--smalp);
          background-color: transparent;
          padding: 20px 21px;
        }
      }
    }
  }
}

.sellSelectedTab {
  // .ant-tabs-tab-active,
  // .redBtn {
  //   background: rgb(252, 79, 82) !important;
  // }
}

.buySellInnerMargin {
  margin-top: 90px;

  @media (max-width: 1199px) {
    margin-top: 0;
  }
}

.tableheight {
  @media (max-width: 767px) {
    .ant-table-body {
      max-height: calc(100vh - 240px) !important;
      height: 100% !important;
    }
  }
}

// ====================TradeBalanceBar-main===============
.TradeBalanceBar-main {
  margin-bottom: 24px;

  div.ant-card.cardBlock {
    padding: 36px;
    border-radius: 4px;
    box-shadow: #235f94;
    // background-color: linear-gradient(180deg, #fdfcff 0%, #ffffffba 100%) 0% 0%;
    background-color: #122740 !important;

    .ant-card-head-title,
    .ant-card-head {
      padding: unset;
      border-bottom: unset;
      color: var(--headerText);
    }

    .ant-card-head-title {
      margin-bottom: 23px;

      p {
        @include fluid-type(320px, 1920px, 12px, 14px);
      }
    }
  }

  div.ant-card.titleValue-Card {
    border-radius: 4px;
    background: #18384e;
    padding: 21px 24px;
    height: 100%;
    box-shadow: 0px 3px 16px #00000017;

    p {
      font-weight: 500;
      color: #1ea0ab;
      @include fluid-type(320px, 1920px, 16px, 22px);

      span {
        font-weight: 600;
        @include fluid-type(320px, 1920px, 12px, 16px);
        color: var(--headerText);
      }
    }
  }
}

.OpenOrderHeight {
  .ant-table {
    height: 260px;
    background-color: var(--exchngtitle) !important;

    td {
      font-size: 12px !important;
      padding: 5px 21px;

      >div {
        @include fluid-type(320px, 1920px, 12px, 12px);
      }

      p {
        @include fluid-type(320px, 1920px, 12px, 12px);
      }
    }
  }

  .ant-table-content {
    @media (min-width: 767px) {
      max-height: 332px;
    }

    thead {
      th {
        border-bottom: 1px solid var(--sidebarbrdr) !important;
      }
    }
  }
}

.favorites {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 5px;

  &--active {
    svg path {
      fill: $CommonButton;
    }
  }
}

.highLowPrice {
  .anticon-arrow-up {
    svg {
      height: 12px;
      fill: var(--dashtoggle);
    }
  }

  .anticon-arrow-down {
    svg {
      height: 12px;
      fill: #fc4f52;
    }
  }
}

.slick-track {
  margin-left: unset !important;
}

.slick-slide {
  >div {
    margin: 0px 10px !important;
  }
}

.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  &::before {
    // background: var(--dark-background)!important;
    background: #235f94 !important;
    border-radius: 30px;
  }
}

.trading_rule {
  .ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
    border-radius: 12px;
    overflow: hidden;
    padding: 0px;

    // background:var(--light-text);
    >.ant-dropdown-menu-item {
      padding: 0 !important;
    }

    >.ant-dropdown-menu-title-content {
      padding: 0;
    }

    .ant-dropdown {
      ul {
        background-color: transparent !important;

        li {
          background-color: transparent !important;
        }
      }
    }
  }
}

.step_refferal {
  .ant-steps-vertical>.ant-steps-item .ant-steps-item-description {
    padding-bottom: 50px !important;
    color: var(--smalp) !important;
    @include fluid-type(320px, 1920px, 12px, 14px);
  }

  .ant-steps-icon {
    background: var(--imgBg);
    padding: 13px;
    border-radius: 4px;
    left: -10px !important;
  }

  .ant-steps-item-title {
    font-weight: 500;
    color: var(--headerText) !important;
    @include fluid-type(320px, 1920px, 14px, 16px);
  }

  .ant-steps-item-title {
    padding-bottom: 6px !important;
    font-family: "F37 Incise";
    word-spacing: normal !important;
  }

  .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: var(--mainColor);
  }

  .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: var(--mainColor);
  }

  .ant-steps-icon {
    svg {
      path {
        fill: var(--mainColor);
      }
    }
  }
}

.rewardHisTable {
  .ant-table {
    background: transparent !important;
    background-color: transparent !important;
    height: 271px;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.0901960784) !important;
  }

  .ant-table-thead {
    background-color: transparent !important;
  }

  .ant-table.ant-table-empty {
    overflow: hidden !important;
  }

  .ant-table-tbody {
    max-height: 225px !important;
    height: 225px !important;
    overflow: auto;
  }
}

.totalEarning {
  button {
    margin-top: 50px;
  }
}

.btn_space {
  margin-top: 40px;
}

.rewardHistory {
  .ant-select-selection-placeholder {
    line-height: 30px !important;
  }

  .ant-select-item {
    line-height: 33px !important;
  }

  .ant-picker,
  .ant-select {
    background-color: var(--datePickerBg) !important;
  }

  button {
    &:first-child {
      color: var(--btnColor) !important;

      &:focus {
        color: var(--btnColor) !important;
      }
    }

    &:last-child {
      &:focus {
        color: var(--headerText) !important;
      }
    }
  }
}

.theme-dark {
  .ant-menu-item.ant-menu-item-selected {
    .ant-menu-item-icon {
      svg {
        path {
          fill: var(--mainColor) !important;
        }

        rect {
          fill: var(--mainColor) !important;
        }
      }
    }
  }
}

// .ant-tooltip-inner {
//   background: transparent
//     linear-gradient(20deg, rgba(57, 39, 101, 0.7098039216) 0%, #1ea0ab 100%) 0%
//     0% no-repeat padding-box !important;
// }
.cryptotable {
  .posNegImg {
    width: 9px !important;
  }

  .positive {
    color: #329879;
  }

  .negative {
    color: #ff4d4f;
  }
}

// .theme-dark {
.rules {
  background: var(--tradebg) !important;
}

.ant-picker-panel-container {
  background: var(--datePickerBg) !important;

  >button {
    color: #fff !important;
  }

  .ant-picker-content {
    th {
      color: var(--btnColor) !important;
    }
  }

  .ant-picker-cell {
    color: var(--smalp) !important;
  }

  .ant-picker-cell-in-view {
    color: var(--headerText) !important;

    &:hover {
      color: #000 !important;
    }
  }

  .ant-picker-month-btn,
  .ant-picker-year-btn {
    color: #fff;
  }

  .ant-picker-header-super-prev-btn,
  .ant-picker-header-prev-btn,
  .ant-picker-header-next-btn,
  .ant-picker-header-super-next-btn {
    color: grey !important;
  }

  td {
    color: #fff !important;
  }
}

// }

.theme-light {
  .dropoptiondesign {

    .ant-dropdown-menu,
    .ant-dropdown-menu-item {
      background-color: #fff !important;

      span {
        background-color: #fff !important;
      }
    }
  }
}

.dropoptiondesign {

  .ant-dropdown-menu,
  .ant-dropdown-menu-item {
    background-color: #18384e !important;
  }
}

.withoutParent {
  .tab_ownAcc {
    p {
      @include fluid-type(320px, 1920px, 12px, 14px);
      color: var(--headerText);
      text-align: center;
      margin-top: 10px;
    }

    h1 {
      @include fluid-type(320px, 1920px, 16px, 18px);
      font-weight: 600;
      text-align: center;
      letter-spacing: 0px;
      margin-top: 10px;
      color: var(--headerText);
    }

    .modalBtn {
      display: flex;
      gap: 20px;
      margin-top: 30px;

      >button {
        flex: 0 48%;
      }
    }
  }
}

// ===========TradersBlock==================

.custom-CardTraders {
  .ant-card {
    padding: 24px 24px 16px;
    box-shadow: 0px 3px 16px #00000017;

    .ant-card-head {
      padding: 0 0 20px 0;
      border-bottom: unset;

      .ant-card-head-title,
      .ant-card-extra {
        padding: unset;

        span {
          strong {
            color: var(--textColor-80839E);
            @include fluid-type(320px, 1920px, 12px, 16px);
          }
        }

        img {
          height: 38px;
          width: 38px;
        }
      }

      .ant-card-extra {
        .isWatchListed {
          background: linear-gradient(20deg,
              rgba(57, 39, 101, 0.7098039216) 0%,
              #1ea0ab 100%) 0% 0% no-repeat;

          span.anticon-heart {
            color: white !important;
          }
        }

        button.ant-btn.heart-btn {
          border: none;
          background-color: #e6ecf1;

          span.anticon-heart {
            color: #a8c1d5;

            &:hover {
              color: white;
            }
          }

          &:hover {
            background: linear-gradient(20deg,
                rgba(57, 39, 101, 0.7098039216) 0%,
                #1ea0ab 100%) 0% 0% no-repeat;

            span.anticon-heart {
              color: white;
            }
          }
        }
      }
    }
  }

  &_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 28px normal;

    .ant-divider-horizontal {
      margin: 16px 0;
    }

    p {
      flex: 1 1 33.3%;
      max-width: 33.3%;
      @include fluid-type(320px, 1920px, 12px, 14px);
      font-weight: 600;
      color: var(--textColor-80839E);

      @media (max-width: 575px) {
        flex: 1 1 48%;
        max-width: 48%;
      }

      @media (max-width: 991px) and (min-width: 767px) {
        flex: 1 1 48%;
        max-width: 48%;
      }

      span {
        color: var(--textColor-80839E);
      }

      &:first-child {
        span {
          color: #1ea0ab;
        }
      }

      // &:nth-child(4),
      // &:nth-child(6) {}

      &:nth-child(2),
      &:nth-child(5) {
        text-align: center;
      }

      &:nth-child(3),
      &:nth-child(6) {
        text-align: right;
      }

      &:nth-child(odd) {
        @media (max-width: 575px) {
          text-align: left;
        }

        @media (max-width: 991px) and (min-width: 767px) {
          text-align: left;
        }
      }

      &:nth-child(even) {
        @media (max-width: 575px) {
          text-align: right;
        }

        @media (max-width: 991px) and (min-width: 767px) {
          text-align: right;
        }
      }
    }
  }

  .ant-statistic {
    .ant-statistic-content {
      span {
        @include fluid-type(320px, 1920px, 14px, 16px);
        font-weight: 600;
        color: var(--textColor-80839E);
      }
    }
  }
}

.tradeGroup-Btn {
  .isWatchListed {
    background: linear-gradient(20deg,
        rgba(57, 39, 101, 0.7098039216) 0%,
        #1ea0ab 100%) 0% 0% no-repeat !important;

    span.anticon-heart {
      color: white !important;
    }
  }

  button.ant-btn.heart-btn {
    border: none;
    background-color: #e6ecf1;

    span.anticon-heart {
      color: #a8c1d5;

      &:hover {
        color: white;
      }
    }

    &:hover {
      background: linear-gradient(20deg,
          rgba(57, 39, 101, 0.7098039216) 0%,
          #1ea0ab 100%) 0% 0% no-repeat;

      span.anticon-heart {
        color: white;
      }
    }
  }
}

// ===========TradersBlockEnd==================
.TradersBlock {
  .custom-Segmented {
    overflow-x: auto;
  }
}

.custom-Segmented {
  .ant-segmented-thumb-motion-appear {
    border-radius: 8px;
    background: transparent linear-gradient(93deg, #2a688b 0%, #1ea0ab 100%) 0% 0%;
  }

  .ant-segmented:not(.ant-segmented-disabled) {
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  .ant-segmented-item {
    div.ant-segmented-item-label {
      line-height: unset;
      padding: 9px 24px;
      @include fluid-type(320px, 1920px, 12px, 14px);
      font-weight: 600;
      color: var(--textColor-80839E);
    }

    &-selected,
    .ant-segmented-thumb-motion-appear {
      background: transparent linear-gradient(93deg, #2a688b 0%, #1ea0ab 100%) 0% 0%;
      border-radius: 8px;

      div.ant-segmented-item-label {
        color: white;

        >button {
          flex: 0 48%;
        }
      }
    }
  }
}

.gridSelect {
  height: 40px;

  input {
    border: 0;
    height: 40px;
  }

  .ant-input-group-addon {
    border: 0 !important;
  }

  .ant-select-focused.ant-select-open {
    box-shadow: none !important;
  }

  .ant-select-selector {
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
  }
}

.orderdHistoryTrans {
  display: flex;
  gap: 20px;
  margin-left: 10px;
}

.orderdHistoryTrans .label {
  font-size: 14px;
}

.grid_tab {
  .ant-tabs-nav {
    margin-bottom: 10px !important;
  }
}

.exchange-table.history-table {
  .ant-table {
    th {
      @include fluid-type(320px, 1920px, 12px, 12px);
    }

    .ant-table-tbody {
      td {
        padding: 5px 15px !important;
        @include fluid-type(320px, 1920px, 12px, 12px);
      }
    }
  }
}

// working on issues
.exchange-table.history-table {
  height: 332px;
  overflow: hidden;

  @media (max-width: 767px) {
    max-width: 767px;
    width: 767px;
    height: 472px;
    overflow-x: scroll !important;

    th {
      @include fluid-type(320px, 1920px, 12px, 12px);
    }

    td {
      padding: 5px 15px !important;
      @include fluid-type(320px, 1920px, 12px, 12px);
    }
  }
}

.OpenOrderHeight {
  .ant-empty.ant-empty-normal {
    height: 214px;
    padding-top: 70px;
  }
}

.tableheight.custom-table.exchange-table.history-table {
  .ant-empty.ant-empty-normal {
    padding-top: 60px;
  }

  th {
    border-bottom: 1px solid var(--sidebarbrdr) !important;
  }
}

.greenTheme {
  color: var(--mainColor) !important;
}

.tradeOrderTable {
  .ant-table-content {
    height: 332px;
    overflow: auto;
  }

  .ant-table-tbody>tr>td {
    padding: 5px 22px;
    font-size: 12px;
  }

  .ant-table-thead {
    background-color: var(--exchngtitle);
    border-bottom: 1px solid var(--sidebarbrdr) !important;

    th {
      border-bottom: 1px solid var(--sidebarbrdr) !important;
    }
  }

  .ant-empty.ant-empty-normal {
    height: 196px;
    padding-top: 80px;
  }

  .green {
    font-size: 12px;
  }
}

.fc-widget-small {
  bottom: 63px !important;
}

.landingHeaderLeft {
  margin-left: 15px;

  .toggleIcon {
    cursor: pointer;

    svg {
      fill: var(--mainColor);
    }
  }
}

.registerTrade {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  color: var(--headerText);

  a {
    color: var(--mainColor);

    &:hover {
      color: var(--mainColor);
      opacity: 0.8;
    }
  }
}

.notificationStyle {
  // img {
  //   height: 48px;
  //   width: 48px;
  //   @media (max-width: 991px) {
  //     height: 40px;
  //     width: 40px;
  //   }
  // }
  // @media (min-width: 767px) {
  //   height: 40px;
  //   width: 40px;
  // }

  .ant-avatar-string {
    line-height: 35px !important;
    position: static !important;
  }

  .settingDropDown.notify {
    background: transparent !important;
  }

  .ant-avatar {
    overflow: unset !important;
  }

  a {
    height: 40px;
    width: 40px !important;
    // background: var(--themebgbtn);
    border-radius: 40px !important;
    margin-left: 0 !important;

    &:hover,
    &:active,
    &:focus-visible,
    &:focus {
      // background: var(--themebgbtn) !important;
    }

    img {
      @media (max-width: 380px) {
        height: 30px;
        width: 30px;
      }
    }

    .ant-badge-count {
      top: -8px;
      right: -8px;

      @media (max-width: 380px) {
        top: -7px;
        right: -2px;
      }
    }
  }

  .notify {
    a {
      background-color: transparent !important;
    }
  }
}

// .OpenOrderHeight {
//   .ant-table-tbody {
//     td {
//       padding: 5px !important;
//     }
//   }
// }

.mobTabs {
  // .ant-tabs-nav-wrap {
  //   overflow: unset !important;
  // }
  background-color: var(--exchngtitle) !important;

  .ant-tabs-nav {
    @media (max-width: 460px) {
      gap: 5px;
      flex-direction: column;

      .ant-tabs-nav-list {
        width: 100% !important;
        padding-right: 0px;
      }
    }
  }

  .ant-tabs-extra-content {
    @media (max-width: 460px) {
      width: 100%;
    }
  }

  .ant-tabs-nav-list {
    @media (max-width: 350px) {
      padding: 0px 20px;
    }
  }

  .open_AllOrderTab {
    .ant-tabs-content-holder {
      @media (max-width: 767px) {
        overflow-x: auto !important;
      }
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    .cstmMob {
      color: #1ea0ab;
    }
  }

  .cstmMob {
    color: #897ea3;
  }
}

.notlogged_leang {
  height: 40px;
}

.ant-btn.cstmbg {
  background: transparent linear-gradient(20deg, #00bef2 0%, rgba(57, 39, 101, 0.7098039216) 100%) 0% 0% no-repeat padding-box !important;
  display: block !important;
  margin-bottom: 10px !important;
}

.ant-btn.cstmbg.nologinBtn-exe {
  background: transparent !important;
  color: #fff !important;
  padding: 7px 0px;
}

.settingset {
  min-width: 300px;
  max-width: 410px;
  width: 100%;
  // background: transparent linear-gradient(120deg, #CFF2D6 0%, #FFFFFFEB 20%, #FFFFFF 29%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;

  .ant-dropdown-menu {
    width: 100% !important;
    max-width: 300px !important;
    // min-width: 410px;

    @media (max-width: 420px) {
      min-width: 300px;
    }
  }

  ul {
    padding-top: 0px !important;
    padding-bottom: 10px !important;

    .notificationdrop {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      padding: 15px !important;
      text-align: center;
      color: var(--light-text);
      border-radius: 10px 10px 0 0;
      cursor: pointer;

      a {
        color: var(--mainColor);
      }
    }

    .ant-dropdown-menu-item {
      padding: 15px !important;
    }

    .unread {
      p {
        color: #00bef2 !important;
      }
    }

    li {
      border-bottom: 1px solid #d9d9d9 !important;

      &:hover {
        border-radius: 0;
      }

      &:last-child {
        border: 0 !important;
      }
    }
  }
}

.transactionHistory {
  .ant-tabs-nav {
    background-color: var(--tabMainBg);
    border-radius: 4px !important;
    padding: 17px 15px;

    &::before {
      border-bottom: none !important;
    }
  }

  .ant-tabs-nav-wrap {
    border-radius: 4px !important;
  }

  .ant-tabs-nav-more {
    display: none;
  }

  .ant-tabs-tab {
    padding: 0 !important;

    @media (max-width: 575px) {
      &:last-child {
        // margin-right: 25px;
        padding-right: 40px !important;
      }
    }
  }

  .ant-tabs-nav-list {
    background-color: var(--daysBg);
    padding: 4px;
    border-radius: 4px !important;

    @media (max-width: 575px) {
      overflow-x: auto;
      width: 520px;
    }
  }

  .ant-tabs-tab-btn {
    padding: 9px 40px;
    line-height: 100% !important;
    @include fluid-type(320px, 1920px, 12px, 16px);
    font-weight: 500 !important;
    font-family: "F37 Incise", sans-serif;
    word-spacing: normal !important;

    @media (max-width: 767px) {
      padding: 9px 12px;
    }
  }

  .ant-tabs-tab-active {
    background-color: var(--tabsBgActive);
    color: var(--mainColor) !important;
  }
}

.blueTabs.portTab {
  .fundingtable {
    .portfolioTable {
      .ant-table-content {
        // height: calc(100vh - 470px);
        overflow: auto !important;
      }
    }
  }
}

.securityInnerDiv {
  .ant-table-content {
    max-height: calc(100vh - 415px);
    overflow-y: scroll !important;
  }
}

.scrollportfolio {
  height: 470px;
  overflow-y: auto;
  overflow-x: hidden;
}

.darkthemeicon {
  display: none;
}

.exchangePage-withoutLogin {
  padding: 0 30px;

  .container {
    max-width: 100%;

    padding: 0;
  }

  .lighttheme {
    display: none;
  }

  .darkthemeicon {
    display: block !important;
    width: 100px;
  }

  .mainClass {
    @media (max-width: 767px) {
      padding-top: 44px !important;
    }
  }
}

.ant-btn {
  transition: none !important;
}

.greenText {
  color: var(--mainColor);
  font-weight: 600;
}

.redText {
  color: red;
  font-weight: 600;
}

.scrollabel_View {
  .ant-table-content {
    height: 405px;
    overflow-y: auto;
  }
}

.ant-picker-dropdown {
  .ant-picker-header-view {
    color: var(--headerText);
  }

  .ant-picker-header button {
    color: var(--headerText);
  }

  .ant-picker-header {
    border-bottom: 1px solid var(--borderclr);
  }

  // .ant-picker-cell-inner {
  //   color: #fff;
  // }

  .ant-picker-cell {
    color: var(--headerText);
  }

  .ant-picker-cell-disabled {
    opacity: 0.3;
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: var(--mainColor);
    color: var(--btnColor);
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: var(--mainColor);
  }

  .ant-picker-today-btn {
    color: var(--mainColor);

    &:hover {
      color: var(--mainColor);
    }
  }

  .ant-picker-panel .ant-picker-footer {
    border-top: 1px solid var(--borderclr);
    background: var(--datePickerBg) !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid var(--borderclr);
  }

  .ant-picker-content {
    thead tr th {
      background-color: var(--mainColor);
      color: var(--btnColor);
    }
  }
}

.ant-picker-panel-container {
  .ant-picker-panel {
    background: var(--datePickerBg) !important;
    border: 0;
  }
}

//===================tabs-outer ,Custom-tabs ==================
.tabs-outer {
  @media (max-width: 575px) {
    .ant-tabs-nav {
      flex-direction: column;

      .ant-tabs-extra-content {
        width: 100%;
      }
    }
  }

  .ant-tabs-nav-list {
    .ant-tabs-tab {
      border: 0 !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        padding: 9px 15px;
        color: var(--mainColor) !important;
        background: var(--tabBtnBg);
      }
    }
  }
}

.Custom-tabs {
  .ant-tabs {
    &.greenBorder {
      .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 0 0 24px;
      }

      .ant-tabs-nav {
        padding: 15px 17px;
        background: var(--setingCardBg);
        border-radius: 4px;

        &-list {
          background: var(--tabsBg);
          border-radius: 4px !important;
        }

        /*sdhahsd*/
        &::before {
          border-bottom: unset;
        }

        div[class$="active"] {
          .ant-tabs-tab-btn {
            color: $baseblue;
          }
        }

        .ant-tabs-nav-wrap {
          .ant-tabs-tab {
            padding: 4px !important;
            gap: 10px;

            &-btn {
              @include fluid-type(320px, 1920px, 12px, 14px);
              color: var(--smalp);
              font-weight: 600;
              padding: 9px 15px;
              border-radius: 4px;
            }
          }
        }
      }

      .ant-tabs-ink-bar {
        background: $baseblue;
      }
    }

    &.btnMain {
      .ant-tabs-nav {
        div[class$="active"] {
          background: linear-gradient(92deg, #2a688b 0%, #1ea0ab 100%) 0% 0%;

          .ant-tabs-tab-btn {
            color: white;
          }
        }

        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            gap: 12px;
          }

          .ant-tabs-tab {
            padding: 12px 25px;
            height: 44px;
            border-radius: 8px;

            &-btn {
              @include fluid-type(320px, 1920px, 12px, 14px);
              color: #897ea3;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  // =========================tabs typecard========
  .ant-tabs-card {
    .ant-tabs-nav {
      &::before {
        border-bottom: none;
      }
    }

    .ant-tabs-nav .ant-tabs-tab,
    div>.ant-tabs-nav .ant-tabs-tab {
      border-radius: 8px;

      &-active {
        background: linear-gradient(92deg, #2a688b 0%, #1ea0ab 100%) 0% 0%;

        .ant-tabs-tab-btn {
          color: white;
        }
      }
    }

    &-small {
      .ant-tabs-tab {
        height: 40px;
      }
    }
  }
}

.seprate-tabs {
  .Custom-tabs {
    .ant-tabs.greenBorder {
      .ant-tabs-nav {
        // background: var(cardBackground);
        background-color: var(--trade-Bg);
        padding: unset unset 28px;
        box-shadow: 0px 3px 16px #00000017;
        border-radius: 4px;
        // height: 58px;
      }
    }
  }

  .ant-tabs-nav {
    padding: unset;
  }
}

.seprate-tabs-card {
  margin-top: 24px;
  border-radius: 4px;
  overflow: hidden;

  .ant-tabs.ant-tabs-card.greenBorder.btnMain {
    .ant-tabs-nav {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;

      .ant-picker-panel-container {
        .ant-picker-panel {
          background: var(--datePickerBg) !important;
          border: 0;
        }
      }
    }
  }
}

.ant-modal-wrap {
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
}

.ant-tabs-tab-btn {
  &:focus {
    color: var(--light-text);
  }
}

.textDecoration {
  .ant-tabs-tab-btn {
    &:focus {
      color: var(--light-text);
    }
  }
}

.blueesback {
  .ant-tabs-tab-btn {
    &:focus {
      color: #fff;
    }
  }
}

.noNotification {
  font-weight: 500 !important;
}

.theme-dark {
  .modalapp {
    .ant-modal-body {
      background: #131b2a;
    }
  }
}

.theme-light {
  .modalapp {
    .ant-modal-body {
      background: var(--main-bg);
    }
  }
}

.modalapp {
  .anticon {
    svg {
      color: var(--light-text);
    }
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-body {
    border-radius: 10px;
  }

  .ant-modal-content {
    border-radius: 4px;
    // background-color: var(--tradebg) !important;
    color: var(--light-text);

    p {
      font-size: 25px;
    }

    .modalbtnSet {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 150px;
        color: var(--btnColor);
        background: var(--mainColor) !important;
      }
    }
  }
}

.deviceManaTable {
  .ant-table-cell {
    color: var(--portfolioText) !important;
  }
}

html.theme-dark .group-wWM3zP_M- {
  background-color: #0f2237;
}

// .depth-chart-module_canvas__PB4Cf {
//   background: #0f223787;
// }

.sumsub-kyc {
  .ant-layout-content {
    background-color: #20252c;
  }
}

.using-crypto li {
  color: #fff;
}

.grey-sec label {
  color: #fff;
}

.grey-sec_base {
  display: flex;
  color: #fff;
}

.error_msg_outer .error_msg {
  color: red !important;
}

.CustomCard {
  background-color: red;
}

.transferAmountInput {
  border-radius: 25px;

  input {
    border: none;

    &:focus,
    :focus-visible,
    :focus-within,
    :visited,
    :target {
      outline: none;
    }
  }

  input {
    border: none !important;
  }
}

.copySearchInput {
  .ant-input.ant-input-disabled {
    border: none !important;
  }

  .ant-input-disabled {
    background-color: #18384e !important;
  }

  .ant-input {
    background-color: #18384e !important;
  }
}

.comman-innerpage-padding-copy {
  padding: 30px 0px 30px 0px;

  .noRecordFound {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.botDetailDate-sec {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  h4 {
    padding-bottom: 0;
    @include fluid-type(320px, 1920px, 14px, 16px);
    font-weight: 500 !important;
    color: #b0b0b2 !important;
  }
}

.userAvatars {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;

  img {
    cursor: pointer;
    margin: 0 5px;
  }

  .avatar.active {
    border: 4px solid #00e396;
    border-radius: 50%;
  }

  span {
    color: var(--headerText);
  }

  div {
    display: flex;
    gap: 5px;
  }
}

.green_icn {
  svg {
    fill: #1ea0ab !important;
    color: #1ea0ab !important;
  }
}

.red_icn {
  svg {
    fill: #ff593d !important;
    color: #ff593d !important;
  }
}

.toolCustom_wdt {
  max-width: 300px !important;

  span {
    color: var(--headerText);
  }
}

.text_AreaCustom {
  margin-top: 10px;

  label {
    color: var(--headerText);
  }

  textarea {
    background-color: transparent;
    border-radius: 4px;
    color: var(--headerText);
    margin-top: 7px;
  }
}

.span_plus {
  color: var(--headerText) !important;
  // padding-bottom: 10px;
  font-size: 30px;
}

.cstm_modl_Copy {
  padding: 20px;

  img {
    display: block;
    margin: auto;
    height: 64px;
    margin-top: 30px;
  }

  h1 {
    font-size: 20px;

    padding: 10px 0px 40px 0px;
    color: var(--headerText);
  }

  button {
    width: 100% !important;
    // width: 100%;
    display: block !important;
    margin: auto !important;
  }
}

.tabGrid {
  .tradetab.exchangePagetabs {
    .ant-tabs-nav-list {
      &:first-child {
        width: 100% !important;
        overflow-x: auto;

        .ant-tabs-tab {
          width: 100px;
          display: flex;
          justify-content: center;
        }
      }
    }

    .tabsgrid.tabsinerGrid {
      .ant-tabs-tab.ant-tabs-tab-active {
        color: #fff;
      }

      .ant-tabs-nav-list {
        width: 100% !important;

        .ant-tabs-tab {
          width: 50% !important;
          // background-color: #18384e !important;
        }
      }

      .ant-tabs-tab {
        width: 50% !important;
        // background-color: #18384e !important;
      }
    }
  }
}

.modalapp {
  .anticon {
    svg {
      color: var(--light-text);
    }
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-body {
    border-radius: 10px;
  }

  .ant-modal-content {
    border-radius: 4px;
    color: var(--light-text);
    background-color: var(--modalBg);

    p {
      font-size: 25px;
    }

    .modalbtnSet {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 150px;
      }
    }
  }
}

.unfollowModal {
  h3 {
    margin-top: 20px;
  }
}

.registerdCard {
  .ant-card-head-wrapper {
    @media (max-width: 466px) {
      flex-direction: column;
      justify-content: center;

      .ant-card-head-title {
        margin-bottom: 0 !important;
      }

      .ant-card-extra {
        margin-left: 0;
      }
    }
  }

  .ant-card-extra {
    .custom-btn {
      .ant-btn-primary[disabled] {
        background: linear-gradient(149deg, #2a688b 0%, #1ea0ab 100%) 0% 0% !important;
      }

      button {
        color: #fff;
      }
    }
  }
}

.workingTable {
  .ant-table {
    height: 400px;
  }
}

.tradeHistory {
  .ant-table-content {
    scrollbar-width: none;

    table {
      height: 100%;
    }
  }
}

.modal_trader {
  padding: 20px;

  h3 {
    font-size: 17px;
    color: var(--headerText);
    margin-bottom: 10px;
  }

  .listing {
    li {
      list-style: disc;
      color: var(--headerText);
      padding-bottom: 10px;

      span {
        font-weight: 600;
      }
    }
  }

  p {
    color: var(--headerText);
  }

  .ant-checkbox-wrapper {
    span {
      color: var(--headerText);
    }
  }

  button {
    display: block;
    margin: auto;
  }
}

.detailsBot {
  .ant-tabs-tab.ant-tabs-tab-active {
    border: 0;

    .ant-tabs-tab-btn {
      color: #fff !important;
    }
  }
}

.cstmWidth {
  width: 100% !important;
}

// ===================inputDesign=========
input.ant-input[type="normalInput"],
span.ant-input-affix-wrapper.ant-input-password {
  padding: 6px 15px;
}

.overlapTabs {
  // margin-top: -65px;

  div[class$="card"] {
    margin-top: 30px;
  }
}

input:autofill {
  background: transparent !important;
  /* or any other */
}

.mt-2 {
  margin-top: 20px;
  @include fluid-type(320px, 1920px, 14px, 16px);
}

.fs-16 {
  @include fluid-type(320px, 1920px, 14px, 16px);
}

.fs-18 {
  @include fluid-type(320px, 1920px, 16px, 18px);
}

.tabsgrid.tabsinerGrid {
  div.ant-tabs-nav {
    div.ant-tabs-nav-wrap {
      div.ant-tabs-nav-list {
        div.ant-tabs-tab {
          // background: white;

          div.ant-tabs-tab-btn {
            color: black;
          }
        }
      }
    }
  }
}

.ant-input[disabled] {
  opacity: 0.6;
}

.notiColaps {
  margin: 30px 38px 30px 50px;
  padding: 0 !important;

  .not_title {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .ant-collapse {
    border-radius: 4px;
  }

  .ant-collapse-item {
    box-shadow: 2px 3px 16px rgba(233, 211, 211, 0.09) !important;
    margin-bottom: 20px;
    border-radius: 10px !important;
    overflow: hidden;
  }

  .ant-collapse-arrow {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      fill: var(--headerText) !important;
      font-size: 12px;
    }
  }

  .ant-collapse-content-box {
    p {
      color: var(--headerText);
    }
  }

  .ant-collapse-header {
    height: 70px !important;
    padding: 22px 15px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: var(--tradebg) !important;
    overflow: hidden;
    position: relative !important;

    h5 {
      color: var(--headerText);
    }
  }

  .ant-collapse-content {
    border-top: 1px solid transparent !important;

    .ant-collapse-content-box {
      padding: 22px 16px !important;
    }
  }

  .ant-collapse-content-box {
    // background-color: var(--accordcontent) !important;
    border-radius: 0 0 5px 5px;
  }

  .notificationCollaps {
    align-items: center;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    padding-right: 32px;

    h5 {
      font-size: 14px;
    }
  }

  .ant-collapse-expand-icon {
    position: absolute;
    left: 0;
    color: var(--btntext);

    .ant-collapse-arrow {
      margin: 0 !important;

      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.portfolioTable.tabletext-right {
  .orange {
    @include fluid-type(320px, 1920px, 12px, 12px);
  }

  .green {
    @include fluid-type(320px, 1920px, 12px, 12px);
  }

  .grey {
    @include fluid-type(320px, 1920px, 12px, 12px);
  }
}

.completedPannel {
  .portfolioTable {
    min-height: unset;
    margin-top: 10px;
  }
}

.selectCoinModal {
  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-body {
    background: #181a20;
    border-radius: 4px;
    overflow: hidden;
  }
}

.walletInfo {
  margin-top: 10px;

  .black14 {
    font-weight: 600;
    margin-bottom: 5px !important;

    .grey.twelve {
      padding-bottom: 3px;
    }
  }
}

.address_clr {
  margin-top: 30px;
}

.copyiconflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  p {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.dataDesc {
  &_logo {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }
}

// .theme-dark {
.rg-cutsomSearch {
  .ant-input {
    color: var(--headerText);

    &::placeholder {
      color: var(--smalp) !important;
    }

    &:hover {
      border-color: var(--sidebarborder);
    }
  }
}

// }

.rg-cutsomSearch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;

  .spotCheckbox {
    position: unset;
  }

  .ant-input {
    max-width: 300px !important;
    width: 100%;
    margin-bottom: 0 !important;
    height: 40px;
    border-radius: 10px;
    background: var(--collpasedata) !important;
  }
}

.spotTableShow,
.fundingTableShow {
  margin-top: 23px !important;
}

.NoIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.NoIcon .coin_icon_placeholder {
  margin-right: 15px;
}

.hasIcon {
  display: flex;
  align-items: center;
}

.priceAlertModal {
  .priceAlertM {
    overflow: auto;
    margin: 20px 0;
  }

  .repeate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    p {
      color: var(--whiteblack);
    }

    .ant-switch {
      background: var(--hoverWhite);
    }

    .ant-switch-checked {
      background: transparent linear-gradient(20deg, rgba(57, 39, 101, 0.7098039216) 0%, #1ea0ab 100%) 0% 0% no-repeat padding-box !important;
    }
  }
}

.priceModal {
  .yourPrice {
    background-color: var(--cardBackground);
    border-radius: 4px;
    margin-top: 20px;
    padding: 15px;
    max-height: 374px;
    width: 100%;
    overflow: auto;

    h3 {
      margin-bottom: 10px !important;
    }

    .flex_innerItems {
      margin-bottom: 20px;

      p {
        color: var(--whiteblack);
      }

      h4 {
        color: var(--headerText);
      }

      .anticon-bar-chart {
        svg {
          width: 40px;
          height: 40px;
          fill: var(--whiteblack);
        }
      }

      &:last-child {
        border: none;
        padding-top: 10px;
      }
    }
  }
}

.open_AllOrderTab .ant-tabs-extra-content img {
  cursor: pointer;
  height: 30px !important;
  width: 30px !important;
}

.checkboxTxt {
  color: var(--smalp) !important;
  font-weight: 600;
}

.ant-checkbox-inner {
  background-color: transparent !important;
  border: 1px solid var(--sidebarbrdr) !important;
}

.fundingTableShow,
.spotTableShow {
  margin-top: 23px !important;
}

.d-flex {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}

.sub-item-text {
  font-size: 13px;
}

.testClass {
  margin-bottom: 20px !important;
}

.userTabs.userTabsArbic {
  .ant-tabs-tab {
    max-width: 33% !important;
    width: 100%;
  }

  .ant-tabs-content {
    position: relative;
  }
}

.confirmationModal {
  .ant-modal-header {
    background: var(--homeCard);
  }
}

.ant-modal-content {
  .ant-modal-header {
    // background: var(--light-E5EDF4);
    border-color: #1ea0ab;
  }

  .modalHeading {
    text-align: center;
    font-size: 24px;
    color: var(--headerText);
  }
}

.innerModelContent {
  padding: 25px 40px;

  .commonText {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    color: var(--headerText);

    .ellipsOverFlow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
      display: inline-block;
    }
  }

  .modalBtn {
    display: flex;
    gap: 20px;
    margin-top: 30px;

    >button {
      flex: 0 48%;
    }
  }
}

.LaunchpadCard {
  display: flex;
  width: 100%;
  gap: 25px;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  .block {
    flex: 1;

    .title {
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-style: normal;
      line-height: normal;
      font-weight: 400;
      color: #fff;
    }

    &.imageblock {
      max-width: 220px;

      @media (max-width: 767px) {
        max-width: 100%;
      }

      .iconOuter {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        min-height: 180px;

        img {
          width: 100%;
          max-width: 200px;
          max-height: 200px;
        }
      }
    }

    .Durationblock {
      align-items: flex-end;

      @media (max-width: 575px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    // padding: 16px;
  }
}

.subscriptionSec {
  .ant-typography {
    color: #fff;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.84px;
    line-height: 40px;
    padding-bottom: 50px;
  }
}

.customStep {
  .ant-steps-item-title {
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.54px;
    font-size: calc(15.6px + 0.125vw);
    color: #fff !important;
  }

  .ant-steps-item-description {
    color: #ffffff73 !important;

    p {
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.42px;
      line-height: 20px;
      padding-bottom: 28px;
      font-size: calc(11.6px + 0.125vw);
    }
  }

  .ant-steps-item-tail::after {
    background-color: #00a3d0 !important;
  }

  .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background: #00a3d0 !important;
  }
}

.modalBackBtn {
  font-weight: 600;
  margin-bottom: 10px;
  padding-left: 0;
  font-size: calc(15.6px + 0.125vw);
  align-items: center;
  display: flex !important;

  span {
    color: $baseblue;
    margin-left: 5px;
  }

  svg {
    path {
      fill: $baseblue;
    }
  }
}

.textAreaDescription {
  background-color: var(--innerCard) !important;
  border-radius: 8px !important;
  border: 0 !important;
  padding: 6px 15px !important;
  color: #fff !important;
}

.launchpadHis {
  margin-top: 100px;
  flex: 1 1;
}

.listingSec {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.uploadCmnSec {
  max-width: 485px;
  width: 100%;

  .ant-upload.ant-upload-drag {
    background: #ffffff !important;
    border: 1px dashed #bcbcbc !important;
    border-radius: 30px !important;
    opacity: 1;
    // max-width: 485px;
    // width: 100%;
    height: 211px;
  }

  .ant-upload {
    .ant-upload-drag-container {
      background-image: url("./Components/Assets/Images/bgUpload.svg");
      background-repeat: no-repeat;
      background-position: center;
    }

    #launcher {
      width: 100% !important;
      bottom: -9px !important;
      border: 0px;
      left: -58px !important;

      @media (max-width: 475px) {
        left: -30px !important;
      }
    }

    .cbnSms {
      height: 30px !important;
      width: 30px !important;
    }
  }

  label {
    color: $darktext;
    @include fluid-type(320px, 1920px, 14px, 16px);
    padding-bottom: 8px;
    display: flex;
  }
}

.signInBtnExes.lists span {
  color: #fff !important;
}

.signInBtnExes {
  span {
    color: var(--headerText);
  }
}

.checkboxTxt {
  @include fluid-type(320px, 1920px, 12px, 14px);
  color: $checkboxparagraph;
  padding-left: 0px;

  p {
    @include fluid-type(320px, 1920px, 12px, 14px);
  }

  &:hover {
    border-color: $CommonButton;
  }
}

.launcModal .ant-modal-content {
  background: #000;
  color: #fff;
  border-radius: 4px;
}

.errorMessage {
  color: red;
}

.ant-btn[disabled]:hover,
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  background: var(--mainColor) !important;
  color: var(--btnColor) !important;
}

.tokenDetailForm {
  .ant-select-selection-placeholder {
    line-height: 30px !important;
  }
}

.NoIcon {
  display: flex;
  gap: 10px;
  align-items: center;
}

.contactFormMess {
  border: none !important;
  background: var(--innerCard) !important;
}

.transactionHistory .ant-tabs-tab-active {
  border: none !important;
}

.fundsection {
  min-height: 100px;
  height: 300px;
  overflow: auto;
  background-color: var(--exchngtitle);

  .currentpair {
    color: #666;
    padding: 15px;
  }
}

.fundsMain {
  border-bottom: 1px solid var(--bordercolor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  margin: 10px;
  margin-top: 0;
  padding-top: 0;

  &_left {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      height: 25px;
      width: 25px;
      border-radius: 50%;
    }

    h5 {
      font-size: 14px;
      color: var(--tabletext);
    }

    p {
      font-size: 12px;
      color: $baseblue;
    }
  }

  &_right {
    p {
      font-size: 12px;
      color: $baseblue;
      text-align: right;
    }

    h6 {
      text-align: right;
      font-size: 14px;
      color: var(--tabletext);
    }
  }
}

.kycModal {
  .modalBtn {
    display: flex;
    gap: 15px;
    margin-top: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.portfolioTable.tableBorderNone {
  min-height: 165px !important;

  td p {
    margin-bottom: 0 !important;
  }
}

.lockedFlag {
  border-radius: 7px !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  height: 16px !important;
  display: flex !important;
  align-items: center;
  border: none !important;
}

.countNoti {
  color: rgb(226, 107, 107);
  font-size: 14px;
  position: relative;
  right: 5px;
}

.countNoti {
  color: rgb(226, 107, 107);
  font-size: 14px;
  position: relative;
  right: 5px;
}

// start p2p css
.comman-innerpage-padding .mainLayoutTab .pyamentmethod .ant-tabs-nav {
  border-radius: 5px 5px 0 0px !important;
}

// .pyamentmethod.p2pMethod {
//   padding-top: 90px !important;
// }

.comman-innerpage-padding .mainLayoutTab .pyamentmethod.p2pMethod {
  .ant-tabs {
    border-radius: 20px !important;
    background: var(--seetingCardBg);
    padding: 13px;
  }
}

.comman-innerpage-padding .mainLayoutTab .pyamentmethod {
  position: relative;
  // padding: 35px 50px;
  // padding-top: 95px;

  // @media (max-width: 1400px) {
  //   padding-top: 0;
  // }

  .container {
    padding: 0;
  }

  .ant-tabs {
    background: var(--seetingCardBg);
    padding: 13px;
    // box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.0901960784);
    border-radius: 5px 5px 0 0px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    .headingtabs {
      margin: 0px;
      color: $btnBaseColor;
    }
  }

  .ant-tabs-nav {
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    padding: 0;
    margin: 0 !important;

    .ant-tabs-tab+.ant-tabs-tab {
      margin: 0 0 0 32px !important;
    }

    .ant-tabs-tab {
      background: transparent;
      padding: 0;
      border-radius: 0;
      padding-bottom: 10px;

      .ant-tabs-tab-btn {
        color: var(--smalp);
      }
    }

    .ant-tabs-tab-active {
      border-bottom: 1px solid #00a79e;

      .ant-tabs-tab-btn {
        color: #00a79e;
        font-weight: 500;
      }
    }
  }

  .headingtabs {
    margin: 0px;
    color: $greytabitme !important;
    @include fluid-type(320px, 1920px, 14px, 18px);
  }

  &__firstTab {
    margin-top: 30px;

    &__innerItems {
      h4 {
        color: var(--headerText);
        @include fluid-type(320px, 1920px, 14px, 18px);
      }

      &__addpayment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;

        @media (max-width: 767px) {
          flex-wrap: wrap;
        }

        p {
          color: var(--smalp);
          width: 50%;
          line-height: 21px;
          @include fluid-type(320px, 1920px, 12px, 16px);

          @media (max-width: 767px) {
            width: 100%;
          }
        }

        button {
          max-width: 280px;
        }
      }
    }
  }
}

.allorderAndProfit {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  >div {
    flex: 1;

    .dateSelect {
      min-width: 140px;

      .ant-picker {
        background-color: var(--accordBg);
        color: var(--headerText) !important;
        border: 0;
        border-radius: 8px;
        color: var(--headerText) !important;
        z-index: 1;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

// .parentPop__div .ant-select-item-option-content {
//   text-align: right;
// }

.parentPop__div {
  p {
    // text-align: right;
  }

  .text_darktheme {
    color: var(--headerText);
    margin-bottom: 10px !important;
  }
}

.ordercompleted__orderballamy {
  display: flex;
  gap: 51px;
  max-width: 100%;
  padding-top: 43px;
}

.ant-tabs.ant-tabs-top.innerTab {
  .ant-tabs-nav {
    // margin-bottom: 20px;
  }

  .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 24px !important;
  }
}

.ant-tabs.ant-tabs-top.innerTab {
  .ant-tabs-nav {
    margin-bottom: 20px;
  }

  .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 24px !important;
  }
}

.orderPage {
  padding: 20px 50px;

  .container {
    background: var(--seetingCardBg);
    border-radius: 4px;
    box-shadow: 0 6px 33px #00000012;
    max-width: 100%;
    padding: 20px 50px;
  }
}

.parentPop__div {
  h3 {
    color: var(--headerText);
  }

  ol {
    li {
      &::marker {
        color: var(--headerText);
      }
    }
  }

  .notifybuttons {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding-top: 30px;

    button {
      width: 50%;
      height: 45px;
      font-weight: 600;
      padding: 0px 20px;
    }
  }
}

.stepnotification {
  display: flex;
  margin: 0px 0px 30px 8px !important;
  align-items: self-start;
  @include fluid-type(320px, 1920px, 14px, 16px);
  color: var(--headerText);

  span {
    color: var(--headerText);
  }

  img {
    margin-top: 5px;
    margin-right: 5px;
  }

  svg {
    margin-right: 5px;
  }
}

.extraUserInfo button {
  margin-top: 10px;
  max-width: 200px !important;
  width: 150px !important;
}

.beforeTips {
  .beforeAppealing {
    &__Tips {
      padding: 0px;
      list-style: none;

      li {
        border: 1px solid $borderinputcl;
        margin: 10px 0px;
        cursor: pointer;
        padding: 10px;

        .imgdecApeal {
          display: flex;
          align-items: center;

          svg {
            margin-right: 10px;
            color: var(--light-text);
            stroke: var(--light-text);
          }

          p {
            margin: 0px;
            color: var(--headerText);
          }
        }

        .linkcancelapeal {
          display: flex;
          justify-content: flex-end;
          gap: 30px;

          a {
            &:hover {
              color: $yellowtbdark;
            }

            &:last-child {
              color: $yellowtbdark;
            }

            color: #000;
          }
        }
      }
    }
  }
}

.userInfoModal {
  width: 100%;

  td {
    color: #848e9c;
  }
}

.reportModal__modal__upload {
  align-items: center;
  background-color: var(--homeCard);
  border: 1px solid #1ea0ab;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 70px;
  justify-content: flex-start;
  margin-top: 10px;
  padding: 20px;
  position: relative;
  width: 100%;

  .inputupload {
    cursor: pointer;
    font-size: 13px;
    height: 70px;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  label {
    padding-left: 10px;
  }
}

.qrCode {
  display: flex;
  gap: 20px;
  margin: 30px 0;

  .input_textimg {
    margin-top: 30px;
  }

  .key_copy {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    margin: 0px;
    background-color: var(--homeCard);
    border-radius: 8px;
    align-items: center;
    color: var(--headerText);
    border: 1px solid var(--borderclr);

    span {
      // width: 90%;
      text-align: right;
    }

    svg {
      cursor: pointer;
    }
  }

  .iconQr {
    img {
      height: 300px !important;
      width: 300px !important;
      max-width: 300px;
    }
  }

  .quiticnDesc {
    display: flex;
    gap: 5px;
    margin-top: 15px;
    color: var(--smalp);

    svg {
      width: 30px;
      margin-top: 5px;
    }
  }
}

// .ant-input.input_amount {

//   &::placeholder {
//     color: var(--inputplceholder) !important;
//   }
// }

.modalDetail h2 {
  color: var(--headerText);
  margin-bottom: 10px;
}

.reportModal__modal__report__malicious {
  align-items: center;
  background-color: #e4e4e1;
  border-radius: 5px;
  display: flex;
  padding: 10px 10px 10px 16px;
  width: 100%;
  margin-top: 13px;

  p {
    color: var(--dark-text);

    img {
      margin-right: 7px;
    }
  }
}

.bgcormain {
  padding: 20px 50px;

  .container {
    background: var(--seetingCardBg);
    border-radius: 4px;
    box-shadow: 0 6px 33px #00000012;
    max-width: 100%;
    padding: 20px 50px;
  }
}

.parentPop__div {
  .GreyText {
    color: var(--smalp) !important;
  }
}

.ant-select-multiple {
  .ant-select-selection-item {
    background-color: var(--hoverWhite) !important;
    color: var(--headerText);
    border: 0 !important;

    .anticon-close {
      color: var(--headerText) !important;
    }
  }
}

.ant-select-open .ant-select-arrow::after {
  transform: rotate(180deg) !important;
}

.ant-select-arrow::after {
  // background-image: url("./Components/Assets/Images/DownArrowIcon.svg");
  background-repeat: no-repeat;
  content: "";
  height: 7px;
  object-fit: cover;
  position: absolute;
  right: 5px;
  top: 5px;
  visibility: visible;
  width: 10px;
  z-index: 9;
}

.ant-tabs-tab-btn {
  &:focus {
    color: unset !important;
  }
}

.noteForFile {
  color: var(--light-text);
}

.mainLayout {

  .ant-menu-item,
  .ant-menu-inline.ant-menu-item {
    &::after {
      border-right: 0px !important;
      display: none !important;
    }
  }
}

.multiSelect {
  .anticon.anticon-down.ant-select-suffix {
    display: none !important;
  }
}

.p2pMethod {
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {

      &:focus,
      &:focus-visible,
      &:active {
        color: #00a79e !important;
      }
    }
  }
}

.exchagetitle .customSelect {
  .ant-select-selector {
    background: transparent !important;
    background-color: transparent !important;
  }
}

.exchagetitle {
  .customSelect.ant-select {
    background: transparent !important;
    background-color: transparent !important;
    // padding: 0px 15px !important;
  }

  .anticon.anticon-down {
    display: none !important;
  }
}

.exchagetitle {
  .customSelect.ant-select .ant-select-selector {
    background-color: transparent !important;
  }
}

.clr__green {
  color: green;
}

.clr__red {
  color: #f6465d;
}

.tooltip {
  i {
    margin-left: 5px;
  }
}

.editName {
  .ant-select-item-option-content {
    gap: 0px;
  }
}

.p2p {
  .ant-tabs-top>.ant-tabs-nav {
    // margin: 0 0 16px !important;
  }

  .dateSelect {
    position: relative;

    .ant-picker {
      background-color: var(--accordBg);
      border-radius: 8px;
      z-index: 1;
      border: 0;
      color: var(--headerText) !important;

      input[readonly] {
        opacity: 1;
        color: var(--headerText);
      }

      &:hover {
        border-color: #00a79e;
      }
    }

    .ant-picker-focused {
      box-shadow: unset;
      outline: 0;
    }

    .anticon.anticon-calendar {
      color: var(--whiteblack);
    }
  }

  .input_textimg {
    .input_amount {
      margin: 10px 0 10px 0;
    }
  }

  .addRowmain__WanttoPay .errorfiat {
    top: 112px !important;
  }

  input[type="text"] {
    // padding: 4px 11px;
  }

  .buysellModal {
    .input_textimg {
      display: flex;
      align-items: center;
    }
  }

  .buySellTabs.p2pLanding {
    height: auto;
  }

  .ant-input {
    color: #fff;
    background-color: transparent;
    background-image: none;
  }

  .inputLayout .inputsearch_ryt {
    padding-right: 0px;
  }

  .input_textimg input:disabled {
    border: unset;
  }

  .buySellTabs {
    &__btns {
      &:first-child {
        border-radius: 8px 0 0 8px;
        font-size: 14px;
        color: $text-80838E;
        font-weight: 600;
        background-color: var(--settingCard);
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
        font-size: 14px;
        color: $text-80838E;
        font-weight: 600;
        background-color: var(--settingCard);
      }
    }
  }

  .orderInfoConfirm h4 {
    margin-bottom: 10px !important;
  }

  .orderInfo__parentHeading h4 {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .react-tel-input {

    // margin-bottom: 12px;
    input {
      background: var(--innerCard) !important;
    }
  }

  .modalDetail h3 {
    margin-bottom: 1em;
  }

  .initialName .firstletter.alphabet {
    margin-bottom: 10px;
  }

  .upperSecTab .ant-tabs-tab {
    padding: 12px 18px !important;
  }

  .deleteadd_text {
    color: #fff;
    margin-bottom: 15px;
  }
}

// end p2p css
.blockreportFlex {
  display: flex;

  @media (max-width: 415px) {
    margin-top: 15px;
  }
}

.overviewPageIcon {
  position: relative;
  bottom: 3px;
}

.totalWithEyeIcon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.firstMod .GreyText {
  margin-bottom: 15px;
}

.singleOrderPage {
  margin-top: 20px;
}

.open_AllOrderTab.removeRadius {
  width: 70%;
  background-color: var(--exchngtitle);

  @media (max-width: 991px) {
    width: 100%;
  }
}

.open_AllOrderTab.removeRadius.reverse {
  >.ant-tabs-nav {
    margin-bottom: 0px !important;
    flex-direction: row-reverse !important;
    padding: 15px 26px;

    .ant-tabs-tab.ant-tabs-tab-active {
      p {
        color: $baseblue;
      }
    }

    .ant-tabs-tab {
      padding: 0px !important;

      p {
        font-weight: 400;
        // font-size: 12px;
        @include fluid-type(320px, 1920px, 12px, 12px);
      }
    }

    .ant-tabs-nav-list {
      margin-left: auto;
      padding: 0px !important;
      gap: 16px !important;

      .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        display: none;
      }
    }

    .ant-tabs-extra-content {
      color: var(--smalp);
    }
  }
}

.theme-dark {
  .mybalance {
    background-image: url("./Components/Assets/Images/balanceBg.png");
    background-size: cover;
    background-position: center;
  }
}

.theme-light {
  .mybalance {
    background-size: cover;
    background-position: center;
    background-image: url("./Components/Assets/Images/balanceBgLight.png");
  }
}

.unfiedSec {
  background-color: var(--exchngtitle);
  border-radius: 4px;

  h5 {
    display: flex;
    align-items: center;
    padding: 19px 18px;
    font-size: 12px;
    font-weight: 500;
    color: var(--colirbtndiff);
    border-bottom: 1px solid var(--sidebarbrdr);

    svg {
      margin-left: 5px;
      margin-top: 3px;
      color: var(--headerText) !important;

      path {
        fill: var(--headerText) !important;
      }
    }
  }

  ul {
    padding: 0px;
    margin: 0px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    padding: 20px 16px;

    li {
      display: flex;
      justify-content: space-between;

      span {
        color: var(--smalp);
        font-size: 12px;
        font-weight: 400;
      }

      p {
        color: var(--colirbtndiff);
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .buttoncstm {
    background: var(--btndiffrent) !important;
    width: 85%;
    height: 38px;
    color: var(--colirbtndiff) !important;
    margin: 0px 16px 20px 16px;
    font-size: 12px !important;

    @media (max-width: 1359px) {
      width: 70%;
      margin: auto;
      display: block;
    }

    span {
      color: var(--colirbtndiff) !important;
    }
  }
}

.bottomchekanddrop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .checkboxTxt {
    font-size: 11px !important;
  }

  .SelectOuter .customSelect.ant-select {
    border: none;
  }

  .cstm_selctbg.ant-select {
    background-color: transparent !important;
    height: 32px;

    &::after {
      right: 3px !important;
    }

    .ant-select-selector {
      padding: 0px !important;
    }

    .ant-select-selection-placeholder {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px !important;
    }
  }
}

.progress {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 6px 10px 10px 10px;

  &_container {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 2px;
  }

  h5 {
    border: 1px solid var(--mainColor);
    padding: 1px 7px;
    border-radius: 4px;
    color: var(--mainColor) !important;

    &:last-child {
      color: #fc4f52 !important;
      border: 1px solid #fc4f52 !important;
    }
  }

  .progressBar {
    h4 {
      padding-left: 5px;
      @include fluid-type(320px, 1920px, 12px, 14px);

      &:last-child {
        text-align: right;
        padding-right: 5px;
      }
    }
  }

  .progress_first {
    background: rgba(50, 152, 121, 0.2) !important;
    min-width: 14%;
  }

  .progress_second {
    background: rgba(252, 79, 82, 0.2) !important;
    min-width: 14%;

    h4 {
      text-align: right;
      padding-right: 5px;
      width: 100%;
    }
  }

  .progressBar {
    height: 18px;
  }

  h4,
  h5 {
    @include fluid-type(320px, 1920px, 10px, 12px);

    &_first {
      // border: 1px solid $baseblue;
      width: 50%;
      display: flex;
      align-items: center;

      >div {
        background: rgba(48, 241, 182, 0.2);
        width: 97%;
        height: 18px;
        padding-left: 10px;

        h2 {
          color: $baseblue !important;
          font-size: 12px;
        }
      }

      h1 {
        border: 1px solid $baseblue;
        color: $baseblue;
      }
    }

    &_secod {
      width: 50%;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      >div {
        background: rgba(252, 79, 82, 0.2);
        width: 97%;
        height: 18px;
        padding-right: 10px;

        h2 {
          color: rgba(252, 79, 82, 1) !important;
          text-align: right;
          font-size: 10px !important;
        }
      }

      h1 {
        color: rgba(252, 79, 82, 1);
        border: 1px solid rgba(252, 79, 82, 1);
      }
    }

    h5 {
      color: var(--btntext) !important;
    }

    h4 {
      margin: 0px 2px;
    }
  }
}

.sellingamoutsec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  span {
    &:last-child {
      color: var(--btntext) !important;
    }

    &:first-child {
      color: var(--smalp) !important;
    }
  }
}

.ant-tabs.ant-tabs-top.open_AllOrderTab.removeRadius.reverse {
  // margin-bottom: 7px !important;
}

.flex_itmsorder {
  display: flex;
  gap: 6px;
}

//Onboard layout  style//

.dropdownbit {
  img {
    display: none;
  }
}

.selectMarketSec {
  display: flex;
  align-items: center;
  margin: 0 !important;
  padding-right: 30px !important;
  gap: 10px;
  border-right: 2px solid var(--brderleft);

  .scnd_span {
    height: 40px !important;
    width: 40px !important;
  }

  span {
    display: inline-block;
    height: 20px;
    width: 20px;

    img {
      display: inline-block !important;
      width: 40px;
      aspect-ratio: 1;
      border-radius: 40px;
      // margin-right: 10px;
    }
  }

  .hambrgerManuIcon {
    width: 20px !important;
  }

  svg {
    margin-right: 10px;
    cursor: pointer;

    path {
      fill: var(--headerText);

      // &:hover {
      //   fill: var(--dashtoggle);
      // }
    }

    &:hover {
      fill: var(--dashtoggle) !important;

      path {
        fill: var(--dashtoggle);
      }
    }
  }

  @media screen {}
}

.selectmrkt {
  position: relative;

  // &::after {
  //   content: "" !important;
  //   height: 30px;
  //   width: 30px;
  //   z-index: 1;
  //   left: -35px;
  //   top: -8px;
  //   position: absolute;
  //   background-image: url("./Components/Assets/Images/hambrger.svg");
  //   background-repeat: no-repeat;
  // }

  img {
    display: none;
  }
}

.orderBookSelect {
  .ant-select-dropdow {
    background-color: var(--exchngtitle) !important;
  }
}

.bannerStyle {
  display: flex;
  min-height: 100vh;

  >div {
    @media (min-width: 991px) {
      width: 50%;
    }
  }

  >div:last-child {
    min-height: 100vh;
    background-color: var(--tradebg);
  }

  &_banner {
    position: relative;

    &_videos {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      overflow: hidden;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bannerVideoText {
      position: absolute;
      // inset: 0;
      z-index: 1;
      display: flex;
      flex-direction: column;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: fit-content;
      width: fit-content;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        max-width: 170px;
      }

      .subTextBanner {
        color: var(--textsubTitle);
        font-family: "Helvetica Now Display", sans-serif;
        line-height: 28px;
        margin: 20px 0px 30px 0px;

        font-weight: 400;
        @include fluid-type(320px, 1920px, 14px, 16px);

        @media (max-width: 767px) {
          word-spacing: 3px;
        }
      }
    }
  }
}

.theme-dark {
  .tabPortHeading {
    background-image: url("./Components/Assets/Images/tabPortHeadingDark.png");
    background-size: cover;
  }

  .convenience-dark {
    display: block;
  }

  .convenience-light {
    display: none;
  }
}

.theme-light {
  .tabPortHeading {
    background-image: url("./Components/Assets/Images/tabPortHeadingLight.png");
    background-size: cover;
  }

  .convenience-dark {
    display: none;
  }

  .convenience-light {
    display: block;
  }

  .ServicesCardImg {
    svg {
      path {
        fill: #329879;
      }
    }
  }
}

.spotArbic {
  span {
    color: var(--headerText);
  }
}

.theme-light {
  .inputLayout {
    .input_custum .ant-input-password-icon.anticon svg path {
      fill: #77868b;
    }
  }
}

.marketSelect {
  width: 110px;
}

.exportBtn {
  background-color: var(--datePickerBg);
  padding: 15px;
  max-width: 125px;
  width: 100%;
  border-radius: 4px;
  color: var(--headerText);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  cursor: pointer;

  span {
    display: flex;
    align-items: center;

    svg {
      path {
        fill: var(--headerText);
      }
    }
  }
}

.subtract {
  background-color: var(--datePickerBg);
  padding: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;

  svg {
    path {
      fill: var(--mainColor);
    }
  }
}

.datepickerSignup {
  .ant-picker {
    background-color: var(--innerCard) !important;
    color: var(--inputtext);
    font-weight: 400;
    height: 60px !important;

    input {
      @include fluid-type(320px, 1920px, 12px, 14px);
    }
  }

  .ant-picker-header {
    button {
      width: fit-content !important;
    }
  }
}

.ant-checkbox-wrapper-checked {
  .ant-checkbox {
    background: var(--primaryGreen);
    border-radius: 4px;
  }
}

.ant-checkbox .ant-checkbox-inner {
  border-radius: 4px !important;
}

.headerHeading {
  color: var(--headerText) !important;
  @include fluid-type(320px, 1920px, 11px, 28px);
  font-weight: 500;
  line-height: 37px;
  font-family: "F37 Incise", sans-serif !important;
  word-spacing: normal !important;
  word-spacing: -3px;
}

.langaugeDrop,
.notificationDrop {
  height: 48px;
  width: 48px;
  max-width: 48px;

  @media (max-width: 991px) {
    height: 30px;
    width: 30px;
  }
}

.notificationDrop2 {
  width: 28px;
  height: 28px;
}

.ant-drawer-body {
  .btngroup {
    flex-direction: column;
    align-items: flex-start;

    >div {
      width: 100%;

      .loginBtn {
        width: 100% !important;
      }

      .themebtn {
        color: var(--btnColor);
      }

      .ant-btn {
        height: 40px !important;
      }
    }
  }
}

.LinksHeader {
  margin: 0 !important;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 20px !important;

  a {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    width: auto !important;
    height: auto !important;

    &:hover {
      background-color: unset !important;
    }
  }
}

//datepciker profile page//
.inputDatePicker {
  label {
    @include fluid-type(320px, 1920px, 12px, 14px);
    font-weight: 400;
    display: block;
    color: var(--smalp);
    padding-bottom: 8px;
  }

  .ant-picker {
    height: 40px;
    box-shadow: none;
    position: relative;
    background-color: var(--innerCard) !important;
    border-radius: 4px;
    color: var(--headerText);
    width: 100%;
    border: 0;
  }
}

.ant-input-focused,
.ant-input:focus {
  border-color: var(--sidebarborder) !important;
  box-shadow: none;
}

.placeholderStyle input {
  color: var(--samp) !important;
}

.address_inputmange {
  .ant-input.input_amount {
    height: 50px !important;
    background-color: var(--bgingput) !important;

    &::placeholder {
      color: #465e65 !important;
      font-size: 13px;
    }
  }
}

.plac_clr {
  .input_textimg>input {
    &::placeholder {
      color: var(--inputplceholder) !important;
    }
  }
}

.SdkHeader[data-v-f779eea1] {
  background-color: red !important;
}

.canclAllBg.btncancel {
  height: 28px;
  text-align: center;
  align-items: center;
  width: 70px !important;
  line-height: 25px;
}

.canclAllBg {
  background-color: var(--referralList) !important;
  border: 0 !important;
  border-radius: 4px !important;
  color: var(--landingWhite) !important;
  @include fluid-type(320px, 1920px, 10px, 11px);
  font-weight: 500;
}

.customSelect.orderBookSelect {
  .ant-select-dropdown {
    @media (max-width: 767px) {
      left: -1px !important;
    }
  }
}

.contctinput_clr {
  .changePassClass {

    input,
    textarea {
      &::placeholder {
        color: var(--inputplceholder) !important;
      }
    }

    .text_AreaCustom label,
    label {
      color: var(--smalp) !important;
    }

    .ant-form-item-label>label {
      color: var(--smalp) !important;
    }
  }
}

.changePassClass {

  input,
  textarea {
    &::placeholder {
      color: var(--inputplceholder) !important;
    }
  }

  .text_AreaCustom label {
    color: var(--smalp) !important;
  }

  .ant-form-item-label>label {
    color: var(--smalp) !important;
  }
}

.orderbk_header_items {
  display: flex;
  align-items: center;

  .ant-select-selector {
    padding: 0px !important;
  }

  .SelectOuter {}

  .ant-select-selection-item {
    height: 20px !important;
    line-height: 10px !important;
    font-size: 12px !important;
    color: #77868b !important;
  }

  // .ant-select-selection-item {
  //   font-size: 12px !important;
  // }

  .customSelect.orderBookSelect {
    // padding: 0px !important;
    width: 105px !important;
    padding-left: 0px !important;

    &::after {
      right: 0px !important;
    }

    .ant-select-selector {
      padding: 0px !important;
    }

    .ant-select-selection-item {
      text-align: center !important;
    }

    .ant-select-selection-item {
      line-height: 15px !important;
    }

    .ant-select-dropdown {
      width: 70px !important;
      text-wrap: nowrap;
    }
  }
}

.loginTab {
  .react-tel-input {
    margin-bottom: 0;
  }
}

// * {
//   input {
//     &::placeholder {
//       text-transform: uppercase;
//     }
//   }
// }

.basicColor {
  color: var(--mainColor);
}

.customHead {
  color: var(--headerText) !important;
  font-size: var(--headingFour) !important;
  font-weight: 500 !important;
  font-family: "F37 Incise";
  word-spacing: normal !important;
}

.referHeading {
  font-family: "Helvetica Now Display", sans-serif;
}

.verifyBtn.customBtnWidth {
  width: 125px;
}

.green {
  color: var(--mainColor);
}

.red {
  color: $red;
}

.ToggelThemeBtn {
  display: flex;
  align-items: center;

  .ToggelThemeBtn_innner {
    position: relative;
    width: 80px;
    height: 40px;

    input {
      display: none;
    }

    input:checked+span {
      box-shadow: 1px 1px 5px 0 #1a6b62 inset;
    }

    input:checked+span:before {
      transform: translateX(46px);
      background-color: #1a6b62;
      box-shadow: inset -1px 1px 2px 0px #279286;
    }

    span {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 34px;
      background-color: var(--);
      transition: 0.4s;
      box-shadow: 1px 1px 5px 0 #b93c53 inset;

      &::before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 7px;
        background-color: #b93c53;
        transition: 0.4s;
        border-radius: 50%;
        box-shadow: inset 1px 1px 2px 0px #e64d69;
      }
    }
  }
}

.cltheme {
  color: var(--tabsBgActiveColor) !important;
}

.customSelect.orderBookSelect.pos_selectOrderbook {
  position: absolute;
  top: 30px;
  right: 9px;
  width: 43px !important;
  padding: 0px !important;
  z-index: 1;
  min-width: 50px !important;
  width: 50px;

  &::after {
    right: 0px !important;
  }

  >div {
    font-size: 10px !important;
  }

  .ant-select-selector {
    padding: 0px !important;
  }

  .ant-select-selection-item {
    text-align: center !important;
  }

  .ant-select-selection-item {
    line-height: 40px !important;
  }

  .ant-select-dropdown {
    width: 70px !important;
    text-wrap: nowrap;
  }
}

.apexcharts-yaxis,
.apexcharts-xaxis {
  .apexcharts-text>tspan {
    fill: var(--headerText) !important;
  }

  .line {
    stroke: var(--sidebarbrdr) !important;
  }
}

.lineGraph {
  max-width: 100px !important;
  height: 40px !important;
}

.topHEadingText {
  h3 {
    font-family: "F37 Incise", sans-serif !important;
    word-spacing: normal !important;
    @include fluid-type(320px, 1920px, 10px, 11px);
  }
}

.swapPage {
  .ant-tabs-tab {
    &:nth-child(1).ant-tabs-tab-active {
      color: var(--mainColor) !important;
      font-weight: bold;
    }

    // &:nth-child(2).ant-tabs-tab-active {
    //   color: red !important;
    //   font-weight: bold;
    // }

    &:nth-child(2).ant-tabs-tab-active .ant-tabs-tab-btn {
      color: red !important;
    }
  }
}

.announcement_heading {
  color: var(--headerText);
  font-size: 30px;
  padding-bottom: 13px;
}

.empty_div {
  position: absolute;
  width: 500px;
}

.privacyContent {
  a {
    color: var(--privacyText) !important;
  }
}

.open_AllOrderTab.actv_tpsl_tab {

  .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn,
  .ant-tabs-top .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 12px !important;
    font-family: "Helvetica Now Display", sans-serif;
  }

  .ant-tabs-nav {
    padding: 12px 20px;
  }

  .ant-tabs-tab {
    padding: 2px 10px !important;
  }

  .actv_tpsl {
    .ant-tabs {
      .ant-tabs-tab-btn {
        font-size: 12px !important;
      }

      .ant-tabs-nav-list {
        background-color: var(--tabsBg) !important;
        border-radius: 4px !important;
        padding: 4px;

        .ant-tabs-tab {
          padding: 8px 15px !important;
        }

        .ant-tabs-tab.ant-tabs-tab-active {
          background-color: var(--tabHover) !important;
          border-radius: 4px !important;
          color: var(--dashtoggle);
        }
      }
    }
  }
}

.checkandViewall {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rangecalender {
  position: relative;

  label {
    color: var(--headerText);
    @include fluid-type(320px, 1920px, 12px, 12px);
    margin-right: 5px;
    display: inline-flex
  }

  .ant-picker-input>input {
    @include fluid-type(320px, 1920px, 12px, 12px);
  }

  .anticon.anticon-swap-right {
    svg {
      display: none;
    }

    &::after {
      background: var(--arrowright);
      background-size: cover;
      background-repeat: no-repeat;
      content: "";
      position: absolute;
      height: 16px;
      width: 15px;
      top: 0px;
    }
  }
}

*[style*="font-family: F37 Incise"] {
  word-spacing: -8px;
}

td.ant-table-column-sort {
  background-color: transparent !important;
}

.monthdrop {
  .ant-select-dropdown {
    min-width: 88px !important;
  }

  .ant-select-selection-item {
    padding: 0 18px 0 0 !important;
  }

  .ant-select-item-option-content {
    white-space: nowrap !important;
  }
}

.orderPreviewCon {
  inset: unset;
  top: 0;
  left: 0;
  height: 100%;
  overflow: auto;
}

.ant-table-wrapper {
  .ant-spin-blur {
    opacity: 0.3;
  }

  .ant-spin-container::after {
    backdrop-filter: blur(100px);
    background: transparent;
  }
}

.ant-picker {
  background: var(--referralList) !important;
  border: 0 !important;
  height: 40px;

  input {
    color: var(--headerText) !important;
  }

  .ant-picker-suffix {
    svg {
      path {
        fill: var(--headerText);
      }
    }
  }
}



//ConfermationModal//
.orderConfModalStyle {
  width: 100%;
  padding-top: 20px;
  margin-top: 10px;
  border-top: 1px solid var(--borderclr);

  &_values {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      color: var(--landingWhite) !important;
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-weight: 600;
      margin: 0;
    }

    span {
      background: var(--itemselected) !important;
      padding: 5px 8px;
      @include fluid-type(320px, 1920px, 12px, 12px);
      font-weight: 400;
      border-radius: 6px;
      color: var(--headerText);
    }
  }

  &_flexedValaues {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 20px 0;

    &_items {
      h6 {
        color: var(--smalp) !important;
        @include fluid-type(320px, 1920px, 12px, 12px);
        font-weight: 400;
        margin: 0 0 2px 0;
      }

      p {
        color: var(--landingWhite) !important;
        @include fluid-type(320px, 1920px, 14px, 16px);
        font-weight: 500;
        margin: 0;
      }
    }
  }

  .flexedbtns {
    display: flex;
    gap: 20px;
  }

  &_checkboxItem {
    margin-bottom: 30px;

    label {
      flex-direction: row !important;
    }
  }
}

.open_AllOrderTab.actv_tpsl_tab.mobTab_transfromNone .ant-tabs-nav-list {
  @media (max-width: 767px) {
    transform: none !important;
  }
}

.ant-dropdown.languageDrop.rightFixedInRespo.ant-dropdown-placement-bottomLeft {
  right: 0;
}