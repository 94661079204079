@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.btnCustom.ant-btn {
  width: 100%;
  font-family: $baseFontBold;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 14px;
  // margin: 16px 0px;
  @include fluid-type(320px, 1920px, 14px, 14px);
  // color: $checkboxparagraph;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.25s;
  border: 0px;
  box-shadow: none;

  &:hover {
    opacity: 0.9;
  }

  &.lessPadding {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &.garyBtn {
    background-color: $bgderkgrey;
    border: 0px !important;
  }

  &.black {
    background-color: $darktext;
    border: 0px !important;
  }

  &.transBtn {
    background-color: transparent !important;
    color: var(--itemselectedBorder);
    border: 1px solid var(--itemselectedBorder);
    height: 40px;
    padding: 10px 20px;
    border-radius: 4px;

    &:hover {
      background: var(--mainColor) !important;
      color: var(--btnColor);
      border: 0;
    }
  }

  &.whitebackRed {
    background-color: #ffeff3;
    color: $btnred;
    height: 40px;
    border: none;
    padding: 20px;
    border-radius: 8px;
  }

  &.custmgren {
    color: #fff;
    border: 0px !important;
  }

  &.greenbtn {
    &:hover {
      color: #fff !important;
    }
  }

  &.redbtn {
    &:hover {
      color: #fff !important;
    }
  }

  &.notstylebtn {
    background-color: #fff;
    padding: 20px;
  }

  &.yelloborderbgnone {
    background-color: #fff;
  }

  &.lytgarydffr {
    color: $borderdarkgrey;
  }

  &.medium {
    height: 29px;
  }

  &.large {
    height: 45px;
  }
}

.p2p .btnCustom.ant-btn {
  width: 100%;
  font-family: $baseFont;
  padding: 4px 10px;
  font-size: 14px;
  color: white;
  background: transparent linear-gradient(100deg, #2995a1 0%, #3fbcc5 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  @include fluid-type(320px, 1920px, 12px, 14px);
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.25s;
  border: 0px;
  box-shadow: none;
  height: 40px;

  &:hover {
    opacity: 0.8;
    background: transparent linear-gradient(100deg, #2995a1 0%, #3fbcc5 100%) 0% 0% no-repeat padding-box;
    color: white;
  }

  &.lessPadding {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &.garyBtn {
    background-color: $btnBaseColor;
    color: $CommonButton;
    border: 0px !important;
  }

  &.black {
    background-color: $black;
    color: $btnTextLight;
    border: 0px !important;
  }

  &.lytgaryBtn {
    background-color: var(--themebgbtn);
    color: $btnBaseColor;
    // border: 0px !important;
  }

  &.orderBtn {
    background-color: $hoveableHeaderBg;
    color: $darktext;
  }

  &.lightBtn {
    background-color: $lightBtn;
    color: $btnBaseColor;
    border: 0px !important;
  }

  &.cancelBtn {
    background: var(--tabBgLayout);
    color: var(--whiteblack);
  }

  &.yellowBtn {
    background: transparent linear-gradient(92deg, #2a688b 0%, #1ea0ab 100%) 0% 0% no-repeat padding-box;
    color: #fff !important;
    border: 0px !important;
    padding: 20px;
    border-radius: 8px;

    img {
      -webkit-filter: invert(100%);
      /* Safari/Chrome */
      filter: invert(100%);
    }
  }

  &.anchortype {
    color: $yellowtbdark;
    background-color: transparent;
    border: 0;
  }

  &.custmgren {
    color: #fff;
    border: 0px !important;
    height: 60px;
    border-radius: 8px;
  }

  &.custmred {
    background-color: $redTxt;
    color: #fff;
    border: 0px !important;
  }

  &.greenbtn {
    &:hover {
      background-color: $baseblue;
      color: #fff !important;
      border: 1px solid $baseblue;
      opacity: 1;
    }
  }

  &.redbtn {
    &:hover {
      background-color: $baseblue;
      color: #fff !important;
      border: 1px solid $baseblue !important;
      opacity: 1;
    }
  }

  &.notstylebtn {
    color: white !important;
    background-color: #014cec;
    padding: 20px;
    border-radius: 50px;
  }

  &.yelloborderbgnone {
    background: var(--resetBtn) 0% 0% no-repeat padding-box !important;
    border: 1px solid var(--resetBtnBorder);
    border-radius: 8px;
    opacity: 1;
    color: #1ea0ab;
    transition: none;
  }

  &.lytgarydffr {
    background-color: transparent;
    border: 1px solid #00a79e;
    padding: 20px;
    color: $white !important;
  }

  &.medium {
    height: 29px;
  }

  &.large {
    height: 45px;
  }

  // }
}



.addRowmain__WanttoPay {
  .yelloborderbgnone {
    background: var(--resetBtn) 0% 0% no-repeat padding-box !important;
    border: 1px solid var(--resetBtnBorder);
    border-radius: 8px;
    opacity: 1;
    color: #1ea0ab;
    transition: none;
  }

  .input_imgcontent {
    // bottom: 18px;
  }
}

.sellRed {
  background-color: var(--btnredact) !important;
  color: var(--clrbtnredWhite) !important;
}