@import "../../../../../Assets/Styles/_mixins.scss";
@import "../../../../../Assets/Styles/_vars.scss";

.collapsecomponent {
  .container {
    width: 100%;
    margin: 0px;
    padding: 0px;
    max-width: 100%;

    .ant-collapse {
      background-color: transparent !important;

      .ant-collapse-item {
        .ant-collapse-expand-icon {
          position: absolute;
          right: 40px;
          width: 25px;
          height: 25px;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          padding: 4px;

          @media (max-width: 991px) {
            right: 20px;
          }

          &::after {
            content: "\f107";
            font: normal normal normal 16px/0 FontAwesome;
            transform: rotate(-90deg);
            transition: all 0.3s linear;
            font-size: 25px;
          }

          span {
            display: none;
          }
        }
      }

      .ant-collapse-item-active {
        .ant-collapse-expand-icon {
          &::after {
            transform: rotate(0deg);
          }

          span {
            display: none;
          }
        }
      }
    }

    .ant-collapse-item {
      background: var(--accordBg) !important;
      border-radius: 18px !important;
      box-shadow: none;
      margin: 15px 0px;
      border-bottom: 0;

      .paraAccordion {
        @include fluid-type(320px, 1920px, 12px, 16px);
        color: $lightgreytext;
        padding-left: 30px;

        @media (max-width: 767px) {
          padding-left: 20px;
        }
      }

      .ant-collapse-header {
        @include fluid-type(320px, 1920px, 14px, 16px);
        color: var(headerText);
        font-weight: bold;
        padding: 30px 33px;
        @media (max-width: 767px) {
          padding: 15px;
        }
        .ant-collapse-header-text {
          padding-right: 30px;
          
        }
      }
    }
  }
}
