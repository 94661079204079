@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

// .imageblock {}

.cardBlock {
  * {
    font-family: sans-serif;
  }

  &_img {
    border-radius: 15px;
    position: relative;

    &_tag {
      position: absolute;
      top: 0;
      left: 0;
      border-top-left-radius: 14px;
      border-bottom-right-radius: 15px;
      font-weight: 600;
      letter-spacing: 0.42px;
      padding: 5px 18px;
      color: #0ac116 !important;
    }

    &_icon {
      img {
        width: 95px;
      }
    }
  }

  &_Content {
    width: 100%;

    &_flex,
    &_days {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 15px;
      color: #fff;

      p {
        @include fluid-type(320px, 1920px, 14px, 16px);
        font-style: normal;
        font-weight: 500;
        color: #616161;
        letter-spacing: 0.48px;
        line-height: normal;
      }

      &_EndTime {
        @include fluid-type(320px, 1920px, 14px, 16px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.48px;
        text-transform: capitalize;
        color: #00a79e;
      }
    }

    &_contentText {
      color: #fff;
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.84px;
      line-height: normal;

      span {
        color: #00a3d0;
        padding-bottom: 6px;
        display: inline-block;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.42px;
      }
    }

    &_Duration {
      span {
        margin-left: 18px;
        color: $baseblue;
        @include fluid-type(320px, 1920px, 14px, 16px);

        strong {
          @include fluid-type(320px, 1920px, 18px, 24px);
          font-weight: 500;
        }

        &:first-child {
          margin-left: unset;
        }
      }
    }
  }
}

.LaunchpadViewAllPage {
  &_list {
    margin-bottom: 50px;
  }
}

.launchpadHistory {
  margin-top: 60px;
  float: right;

  svg {
    margin-left: 5px;
  }
}

.TimeLine {
  max-width: 860px;
}

.gradientborderCard {
  padding: 36px;
  border: 1px solid #00a3d0;
  border-radius: 10px;
  background-color: var(--setingCardBg);

  h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.48px;

    span {
      color: #00a3d0;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.48px;
    }
  }

  h2 {
    color: #fff;
    @include fluid-type(320px, 1920px, 20px, 28px);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.84px;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #616161;
      letter-spacing: 0.42px;
    }
  }

  &_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 480px) {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}

.LaunchpadDetail {
  max-width: 860px;

  .stepTimeline {
    margin-top: 60px;

    .ant-typography {
      color: #fff;
    }
  }

  .blockchainAI {
    margin-top: 80px;

    h6 {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.54px;
      padding-bottom: 24px;
      color: #fff;
    }

    p {
      color: #616161;
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.48px;
      padding-bottom: 28px;
    }

    p+h6 {
      padding-top: 8px;
    }
  }
}

.blockchainAI {
  h6 {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  p {
    color: #fff;
    font-size: 14px;
  }
}

.noRecordFound {
  color: #fff;
  text-align: center;
}