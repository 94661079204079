@import "../../../../../Assets/Styles/_mixins.scss";
@import "../../../../../Assets/Styles/_vars.scss";

.tablevalues {
  table {
    box-shadow: 0px 0px 2px var(--borderclr);

    thead {
      tr {
        th {
          text-align: center;
          color: var(--smalp);
          background-color: var(--tabBgLayout);
          text-align: left;

          &::before {
            border: 0px;
            display: none;
          }

          p {
            margin: 0px;
          }

          span {
            border-radius: 18px;
            padding: 3px 10px;
          }

          .btnfree {
            background: rgba(14, 203, 129, 0.1);
            color: var(--greenTxt);
          }

          .btnlowestheghtest {
            color: $yellowtbdark;
            background: #014cec09;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          text-align: center;
          border-bottom: 1px solid var(--borderclr) !important;
          text-align: left;

          .tablevalues__advertissec {
            display: flex;
            align-items: center;

            img {
              border-radius: 25px;
              color: var(--headerText) !important;
            }

            svg {
              margin-right: 10px;
            }

            .trustretail {
              margin-left: 10px;

              p {
                margin: 0px;

                &:first-child {
                  @include fluid-type(320px, 1920px, 12px, 14px);
                  font-weight: 600;
                  color: var(--headerText);
                }

                &:last-child {
                  @include fluid-type(320px, 1920px, 12px, 12px);
                }
              }
            }
          }

          .price_tble {
            @include fluid-type(320px, 1920px, 12px, 14px);
            font-weight: 600;
            color: var(--headerText);
            margin: 0px;

            span {
              @include fluid-type(320px, 1920px, 12px, 12px);
              font-weight: 400;
              color: $baseblue;
            }
          }

          .custmwidth {
            button {
              max-width: 138px;
              border-radius: 8px;
              color: $white;

              font-weight: 600;
              border: 1px solid $baseblue;

              &:hover {
                opacity: 0.8 !important;
              }
            }
          }
        }
      }
    }
  }

  .ant-spin-container {
    .ant-table-pagination-right {
      justify-content: center !important;

      li {
        border: 0px;
        color: var(--colorpagination);

        a {
          color: var(--colorpagination);
          font-weight: 600;
          @include fluid-type(320px, 1920px, 12px, 14px);
        }

        &:first-child {
          button {
            border-radius: 18px;
            background: var(--backlipagiantion);
            width: 69px;
            height: 34px;
            background: #f4f4f4;
            border-radius: 18px;

            &:after {
              content: "Prev.";
              position: absolute;
              font-size: 16px;
              line-height: 2px;
              margin-left: -18px;
              font-weight: 600;
            }

            span {
              display: none;
            }
          }
        }

        &:last-child {
          button {
            border-radius: 18px;
            background: var(--backlipagiantion);
            width: 69px;
            height: 34px;
            background: #f4f4f4;
            border-radius: 18px;

            &:before {
              content: "Next.";
              position: absolute;
              font-size: 16px;
              line-height: 2px;
              margin-left: -23px;
              border-radius: 24px;
              font-weight: 600;
            }

            span {
              display: none;
            }
          }
        }
      }
    }
  }

  .lightblue {
    color: var(--smalp);
    margin: 0px;
    font-weight: 600;
  }

  .greenTxt {
    color: var(--greenTxt);
    margin: 0px;
    font-weight: 600;

    > div {
      text-wrap: wrap;
    }
  }

  .yelloetext {
    color: var(--yellowtbdark);
    margin: 0px;
    font-weight: 600;
  }
}

.greenTxt.some-space {
  display: flex;
  flex-flow: wrap;
  gap: 10px;
  max-width: 315px;
  width: 100%;

  > div {
    background: var(--resetBtn);
    border-radius: 8px;
    color: #1ea0ab;
    font-size: 12px;
    font-weight: 400;
    opacity: 1;
    padding: 10px 15px;
  }
}
