@import "../../../../../Assets/Styles/_mixins.scss";
@import "../../../../../Assets/Styles/_vars.scss";

.howItWorks {
  .howworks {
    padding: 23px 30px 33px;

    @media (max-width: 991px) {
      padding: 50px 20px;
    }

    @media (max-width: 767px) {
      padding: 20px 20px;
    }

    .works {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;

      @media (max-width: 991px) {
        flex-direction: column;
        padding-bottom: 16px;
      }

      h3 {
        margin-bottom: 10px;
        text-align: left;
        color: var(--headerText);
        margin-bottom: 0.5em !important;
      }

      p {
        text-align: left;
        @include fluid-type(320px, 1920px, 12px, 16px);
        color: var(--smalp);
        margin-bottom: 1em !important;
      }
    }

    &__lefthowworks {
      @media (max-width: 991px) {
        text-align: center;
      }

      img {
        @media (max-width: 1199px) {
          width: 100%;
          max-width: 603px;
        }

        @media (max-width: 991px) {
          width: 100%;
          max-width: 500px;
        }
      }
    }

    &__texthowworks {
      .tabbuysell {
        .texttab {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;

          h3 {
            @include fluid-type(320px, 1920px, 15px, 25px);
            color: $darktext;
            margin-bottom: 0;
            font-weight: 600;
            width: fit-content;
            margin: auto;
            transform: translateX(50%);
          }
        }

        p {
          color: var(--lightgreytext);
          margin: 0px;
          line-height: 26px;
          font-weight: 500;
          @include fluid-type(320px, 1920px, 12px, 14px);
          text-align: center;
        }
      }
    }
  }
}

.buySellItem__inner {
  .ant-row {
    .ant-col {
      display: flex;
      justify-content: center;

      @media (max-width: 991px) {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.buySellTabs {
  &__btns {
    // margin-right: 20px;
  }
}

.c-p2p {
  border-radius: 28px;
  box-shadow: 0px 6px 33px rgba(0, 0, 0, 0.07);
  background: var(--seetingCardBg);
}
