@import "../../../../../Assets/Styles/_vars.scss";

.table-indentity-row {
  table {
    tbody {
      tr:nth-child(4n + 1) td,
      tr:nth-child(4n + 2) td {
        background: var(--seetingCardBg);
      }

      tr:nth-child(4n + 3) td,
      tr:nth-child(4n + 4) td {
        background-color: transparent;
      }
    }
  }
}

.tablefiate {
  .ant-table-tbody {
    tr {
      &:nth-child(odd) {
        // background: $headerDropdownBg;
        font-size: 20px;
        color: #ffffff;
      }
    }
  }

  p {
    margin: 0px;
  }

  a {
    color: #000;
  }

  .copytextsvg {
    color: var(--headerText);

    svg {
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .buysell {
    .greenText {
      color: #129d26;
    }

    .redText {
      color: #f6465d;
    }
  }

  .datetimer {
    margin-left: 10px;
    color: var(--smalp);
  }

  .currencyimg {
    display: flex;
    align-items: center;

    img {
      border-radius: 25px;
    }
  }

  .yellowlight {
    color: $yellowtbdark;
  }

  svg {
    margin-right: 5px;
  }

  .order-filters {
    background: var(--seetingCardBg);
    padding: 13px;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.0901960784);
    border-radius: 4px;
    // position: absolute;
    // top: -145px;
    // z-index: 9;
    // left: 0;
    width: 100%;

    @media (max-width: 1200px) {
      position: initial;
      box-shadow: unset;
    }

    .ant-select-item-option-content {
      img {
        border-radius: 25px;
      }
    }

    .sectsresetBtn {
      .ant-select-item-option-content {
        img {
          border-radius: 25px;
        }
      }

      // display: flex;
      // flex-wrap: wrap;
      // gap: 20px;

      // @media (max-width: 1200px) {
      //   flex-wrap: wrap;
      // }

      // .ant-row {
      //   width: 100%;
      // }

      // .fiattableselects {
      //   // display: flex;
      //   // flex-wrap: wrap;
      //   // max-width: 70%;
      //   // width: 100%;
      //   // gap: 20px;

      //   @media (max-width: 1200px) {
      //     max-width: 100%;
      //   }

      //   .SelectOuter {
      //     // max-width: 165px;
      //     width: 100%;

      //     @media (max-width: 767px) {
      //       max-width: 100%;
      //     }
      //   }

      //   .ant-picker {
      //     height: 45px;

      //     @media (max-width: 767px) {
      //       max-width: 100%;
      //       width: 100%;
      //     }

      //     .ant-picker-input {
      //       input {
      //         font-weight: bold;
      //         opacity: 9;
      //       }
      //     }
      //   }
      // }

      // .filterfiatreset {
      //   display: flex;
      //   // max-width: 25%;
      //   width: 100%;
      //   justify-content: flex-end;
      //   gap: 20px;

      //   @media (max-width: 1200px) {
      //     max-width: 50%;
      //   }

      //   @media (max-width: 767px) {
      //     max-width: 100%;
      //   }

      //   button {
      //     color: $white;
      //     width: 100%;
      //   }
      // }
      .ant-picker {
        width: 100%;
        height: 40px;
      }

      button.ant-btn[disabled] {
        color: #fff !important;
        // background: transparent linear-gradient(92deg, #2a688b 0%, #1ea0ab 100%)
        //   0% 0% no-repeat padding-box;
        background-color: transparent;
      }
    }

    .ant-btn:not([disabled]):active {
      background-color: $baseblue;
      color: $white;
      opacity: 0.7;
    }

    .ant-btn:focus {
      background-color: $baseblue;
      color: $white;
      opacity: 0.7;
    }

    .ant-btn:active > span,
    .ant-btn:focus > span {
      position: relative;
      color: $white;
    }
  }

  .viewDetail {
    display: flex;
    gap: 20px;

    p {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      color: $baseblue;
      padding: 4px 20px;
      border: 1px solid $baseblue;
      border-radius: 8px;
      font-weight: 500;
      color: $baseblue;

      &:hover {
        color: $white;
        background-color: $baseblue;
      }
    }
  }

  .ant-table-thead > tr > th {
    color: var(--smalp);
    background-color: var(--tabBgLayout);
    transition: none;
  }

  .ant-table-tbody > tr > td {
    background: var(--seetingCardBg);
  }
}

.table-indentity-row {
  .ant-table-tbody > tr > td {
    border-top: unset;
  }

  .ant-table-tbody > tr:nth-child(even) {
    td {
      border-bottom: 1px solid var(--borderclr) !important;

      p {
        color: var(--headerText);
      }
    }
  }
}

.listIcon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  object-fit: contain;
}

.profit__loss__table {
  td {
    text-transform: capitalize;
    color: #000;

    .clr__green,
    .clr__red {
      font-weight: 600;
    }
  }

  .ant-table-cell {
    color: var(--whiteblack);
  }

  .currency__icon {
    img {
      border-radius: 25px;
      max-width: 30px;
      margin-right: 8px;
    }
  }
}

// .allorderAndProfit {
//   .ant-picker {
//     height: 43px !important;
//   }
// }

.pyamentmethod.p2pMethod .container {
  padding: 0;
}
