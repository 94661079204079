@import "../../../../Assets/Styles/_mixins.scss";
@import "../../../../Assets/Styles/_vars.scss";

.headingtabs {
  color: var(--headerText);
}

.pyamentmethod {
  position: relative;
  // padding-top: 80px;

  @media (max-width: 1400px) {
    padding-top: 0;
  }

  .ant-tabs {
    background: var(--seetingCardBg);
    padding: 13px;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.0901960784);
    border-radius: 20px !important;
  }

  .ant-tabs-ink-bar {
    background-color: $CommonButton;
    height: 5px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    .headingtabs {
      margin: 0px;
      color: $btnBaseColor;
    }
  }

  .ant-tabs-nav {
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    padding: 0;
    margin: 0 !important;

    .ant-tabs-tab {
      background: transparent;
      padding: 0;
      border-radius: 0;
      padding-bottom: 14px;

      .ant-tabs-tab-btn {
        color: var(--smalp);
      }
    }

    .ant-tabs-tab+.ant-tabs-tab {
      margin: 0 0 0 32px !important;
    }

    .ant-tabs-tab-active {
      border-bottom: 4px solid #00a79e;

      .ant-tabs-tab-btn {
        color: #00a79e;
      }
    }
  }

  .headingtabs {
    margin: 0px;
    color: $greytabitme !important;
    @include fluid-type(320px, 1920px, 14px, 18px);
  }

  &__firstTab {
    margin-top: 30px;

    &__innerItems {
      h4 {
        color: $btnBaseColor;
        @include fluid-type(320px, 1920px, 14px, 18px);
      }

      &__addpayment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;

        @media (max-width: 767px) {
          flex-wrap: wrap;
        }

        p {
          color: var(--smalp);
          width: 50%;
          line-height: 21px;
          @include fluid-type(320px, 1920px, 12px, 16px);

          @media (max-width: 767px) {
            width: 100%;
          }
        }

        button {
          max-width: 280px;
        }
      }
    }
  }
}