@import "../../../../../Assets/Styles/_mixins.scss";
@import "../../../../../Assets/Styles/_vars.scss";

.chatSec {
  display: flex;
  gap: 50px;
  padding-top: 50px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  .steps-action {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 30px;
  }

  .progress__Second {
    display: flex;
    gap: 20px;

    button {
      max-width: 200px;
      width: 100%;
    }

    .apeal_timer {
      display: flex;
      gap: 10px;
    }

    color: var(--headerText);
  }

  .progressBar {
    padding-top: 0px;
    max-width: 891px;
    width: 100%;
    padding: 30px 60px;

    &__steps {
      border-bottom: 1px solid #fafafa;
      padding-bottom: 20px;
    }

    .ant-timeline-item-head-blue {
      border: 4px solid $CommonButton;
      height: 16px;
      width: 16px;
    }

    .ant-timeline-item-tail {
      left: 7px;
      border: 1px dotted var(--borderclr);
    }

    .ant-timeline {
      margin-left: 8px;
    }

    .ant-steps-item-title {
      @include fluid-type(320px, 1920px, 11px, 12px);
    }

    .ant-steps-item-title {
      &::after {
        left: 47px;
      }
    }
  }

  &__progress {
    max-width: 425px;
    width: 100%;
    margin-bottom: 20px;

    @media (max-width: 1490px) {
      max-width: 70%;
      width: 100%;
      margin: auto;
    }

    @media (max-width: 991px) {
      width: 100%;
      max-width: 100%;
    }
  }

  .steps-action {
    text-align: left;
    margin-left: 52px;
    color: #1e252d;

    @media (max-width: 575px) {
      margin-left: 0;
    }

    button {
      height: 45px;
      @include fluid-type(320px, 1920px, 12px, 14px);
      font-weight: 300 !important;
    }
  }

  .ant-timeline-item-content {
    margin: 0 0 0 44px;
  }
}

.ant-timeline-item:last-child .ant-timeline-item-tail {
  display: none !important;
}

.timerProgress {
  .chatsecTimeline {
    .ant-timeline-item {
      &:last-child {
        padding-bottom: 0px;
      }
    }

    .ant-timeline-item-content {
      p {
        margin: 0px;

        &:first-child {
          color: var(--headerText);
          font-weight: 600;
          @include fluid-type(320px, 1920px, 12px, 16px);
          padding-bottom: 16px;

          svg {
            margin-left: 5px;
          }
        }
      }

      .maymnetCoinchck {
        color: var(--smalp);
        @include fluid-type(320px, 1920px, 12px, 14px);
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        svg {
          width: 15px;
          margin-right: 2px;

          path {
            fill: var(--smalp);
          }
        }
      }
    }

    .paymentMethod {
      border: 1px solid var(--borderclr);
      display: flex;
      border-radius: 8px;

      @media (max-width: 767px) {
        flex-direction: column;
      }

      .rdioupipayment {
        max-width: 291px;
        width: 100%;

        @media (max-width: 767px) {
          width: 100%;
          max-width: unset;
        }

        .radioCustom {
          width: 100%;
          height: 100%;

          .ant-radio-wrapper {
            span {
              .leftborderf {
                padding-left: 5px;
                border-left: 4px solid #67686d !important;
                // line-height: 14px;
              }

              .leftborders {
                padding-left: 5px;
                border-left: 4px solid #f57505 !important;
                // line-height: 14px;
              }

              .leftbordert {
                padding-left: 5px;
                border-left: 4px solid $baseblue;
                // line-height: 14px;
              }
            }
          }

          .ant-radio-group {
            display: flex;
            flex-direction: column;
            height: 100%;
          }

          .ant-radio-group {
            label {
              border-right: 1px solid var(--borderclr);
              width: 100%;
              margin: 0px !important;
              padding: 15px;
              flex: 1 auto;
              align-items: center;

              span {
                &:nth-child(2) {
                  padding-top: 5px;
                }
              }

              p {
                padding-bottom: 0;
              }
            }
          }
        }
      }

      .upiInfo {
        width: 100%;
        padding: 20px;

        ul {
          list-style: none;
          padding: 0px;
        }

        P {
          color: var(--smalp);
          @include fluid-type(320px, 1920px, 12px, 12px);
          padding-bottom: 0;
        }

        .balemyicon {
          display: flex;
          align-items: center;

          span {
            padding: 0px 5px;
            cursor: pointer;
          }

          svg {
            height: 10px;
            width: 10px;
          }
        }

        h4 {
          @include fluid-type(320px, 1920px, 12px, 14px);
          color: var(--smalp);
          margin-right: 10px;
        }
      }
    }
  }
}

// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<modal-Confirmation->>>>>>>>>>>>>>>>>>>>
.parentPop__div {
  margin: 2px;

  @media (max-width: 767px) {
    margin: 0px;
  }

  .GreyText {
    color: var(--smalp);
  }

  .modalTopHeading {
    h2 {
      color: var(--headerText);
      margin-bottom: 10px;
    }

    p {
      color: var(--smalp);
    }
  }

  .paymentConformation {
    p {
      line-height: 25px;
      color: var(--smalp);
      @include fluid-type(320px, 1920px, 12px, 14px);
      margin-bottom: 20px;
      text-align: left;
      margin-bottom: 10px !important;
    }

    &__Upi {
      background-color: var(--themegraybtn);
      border-radius: 6px;
      padding: 20px 20px 20px 30px;
      margin-bottom: 20px;

      &__currency {
        @include fluid-type(320px, 1920px, 12px, 14px);
        border-left: 3px solid #67686d;
        color: var(--headerText) !important;
        padding-left: 10px;
        // height: 10px;
        // line-height: 10px !important;
        font-weight: 600;
      }

      &__idname {
        list-style: none;
        padding: 0px;
        margin: 0px;

        li {
          display: flex;
          justify-content: space-between;

          p {
            color: var(--smalp);
            @include fluid-type(320px, 1920px, 12px, 12px);
            margin: 0px;
          }

          h5 {
            @include fluid-type(320px, 1920px, 12px, 14px);
            margin: 0px;
            color: var(--headerText);
          }
        }
      }
    }
  }

  .ant-select-item-option-content {
    word-break: break-word !important;
    text-overflow: unset !important;
    overflow: auto;
    white-space: inherit;
  }
}

.showBigImage {
  cursor: pointer;
  height: 30px;
  object-fit: cover;
  width: 100%;
  max-width: 40px;
}

@keyframes myAnim {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

.paymentChat {
  box-shadow: unset;
  padding: 0;
}

.paymentSteps {
  .ant-steps-item-tail {
    display: block !important;
    margin-left: 100px;
    padding: 3.5px 24px;

    @media (max-width: 575px) {
      margin: 8px 0 0 42px;
    }
  }

  .ant-steps-item-content {
    display: block;
    width: 116px;
    margin-top: 8px;
    text-align: center;
  }

  .ant-steps-item-icon {
    display: inline-block;
    margin-left: 42px;
  }

  .ant-steps-item:last-child
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    display: none !important;
  }
}

.orderInfoConfirm {
  background-color: var(--homeCard);
  box-shadow: 0px 0px 12px #0000001a;
  border-radius: 8px;
  opacity: 1;

  .amtpricequa {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    list-style: none;
    padding-left: 18px !important;
    padding: 14px;
    padding-left: 14px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1019607843);
    border-radius: 8px;

    @media (max-width: 574px) {
      flex-wrap: wrap;
      gap: 8px;
    }

    &__amount {
      max-width: 416px;
      width: 100%;
      text-align: center;
      border-right: 1px solid;
      border-right-color: currentcolor;
      border-color: var(--borderclr);
      padding-right: 70px;

      @media (max-width: 1600px) {
        padding-right: 30px;
      }

      @media (max-width: 574px) {
        border-right: 0;
        padding-right: 0;
      }

      &:last-child {
        border-right: 0;
        padding-right: 0;
      }

      &:last-child {
        h5 {
          border: 0;
          padding-bottom: 0;
        }
      }

      p {
        border-bottom: 1px solid;
        padding-bottom: 16px;
        border-color: var(--borderclr);
        color: var(--smalp) !important;
        margin-bottom: 0;

        @media (max-width: 574px) {
          border: 0;
          padding-bottom: 0 !important;
        }
      }

      h4 {
        padding-top: 16px;
        margin-bottom: 0;
        color: #1ea0ab;
        @include fluid-type(320px, 1920px, 14px, 16px);

        @media (max-width: 574px) {
          padding-top: 3px;
          border-bottom: 1px solid;
          padding-bottom: 10px;
          border-color: var(--borderclr);
        }
      }

      h5 {
        padding-top: 16px;
        @include fluid-type(320px, 1920px, 14px, 16px);
        margin-bottom: 0;
        color: var(--headerText);

        @media (max-width: 574px) {
          padding-top: 3px;
          border-bottom: 1px solid;
          padding-bottom: 10px;
          border-color: var(--borderclr);
        }
      }
    }
  }
}

.progressBar__steps {
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: var(--headerText) !important;

    &::after {
      background-color: $text-80838E;
      height: 1px;
      left: 23%;
    }
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #1ea0ab !important;
  }
}
