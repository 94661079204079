@import "../../../Assets/Styles/_vars.scss";
@import "../../../Assets/Styles/_mixins.scss";

.reportModal {
  max-width: 502px;
  width: 100%;
  margin: 0 auto;
  background-color: $white;
  border-radius: 5px;
  opacity: 1;

  &__modal {
    p {
      @include fluid-type(320px, 1920px, 12px, 14px);
    }

    &__select {
      margin: 10px 0px;
    }

    &__report {
      &__malicious {
        display: flex;
        align-items: center;
        background-color: #e4e4e1;
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        padding-left: 16px;

        p {
          margin: 0px;
          color: #000;

          img {
            margin-right: 10.57px;
          }
        }
      }
    }

    &__textarea {
      .text_AreaCustom {
        margin-top: 0px !important;

        label {
          color: var(--whiteblack);
        }

        .ant-input {
          background: var(--homeCard);
          color: var(--whiteblack);
        }
      }

      p {
        text-align: right;
        color: $textareaBottom;
      }

      .ant-input:hover {
        border-color: $CommonButton;
        border-right-width: 1px;
      }
    }

    &__uploadProof {
      .prooflabel {
        color: var(--headerText);
        font-weight: 401;
        @include fluid-type(320px, 1920px, 12px, 14px);
      }

      .description {
        color: var(--headerText);
      }
    }

    &__buttons {
      display: flex;
      margin-top: 28px;
      gap: 34px;

      >div {
        max-width: 46%;
        width: 100%;
      }
    }

    &__upload {
      height: 70px;
      margin-top: 10px;
      background-color: var(--homeCard);
      display: flex;
      border-radius: 10px;
      width: 100%;
      padding: 20px;
      border: 1px solid $baseblue;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      position: relative;

      label {
        color: var(--headerText);
        @include fluid-type(320px, 1920px, 14px, 16px);
        padding-left: 10px;
      }

      .inputupload {
        font-size: 13px;
        height: 70px;
        opacity: 0;
        position: absolute;
        cursor: pointer;
        left: 0;
        width: 100%;
      }
    }
  }
}

.uploadMain_sec {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding-top: 20px;

  .uploadMain {
    background-color: var(--homeCard);
    display: flex;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    border: 1px solid $baseblue;
    display: flex;
    align-items: center;
    gap: 20px;

    cursor: pointer;

    .imgName {
      display: inline-block;
      word-break: break-all;

      @media (max-width: 480px) {
        font-size: 13px !important;
      }
    }

    .deleticon {
      height: 40px;
      width: 40px;
    }

    h4 {
      color: var(--headerText);
    }

    .uploadImg {
      width: 70px;
      height: 70px;
      object-fit: cover;

      @media (max-width: 480px) {
        width: 30px;
        height: 30px;
      }
    }

    button {
      background: transparent;
      border: 0;
      cursor: pointer;
    }
  }
}

.img-cls {
  .anticon.anticon-close {
    height: 20px;
    width: 20px;
    top: 0px;
    right: 0px;
    position: absolute;
    background-color: #d07d7d;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: white;
    }
  }
}

.dropsec {
  .ant-select-item {
    text-overflow: clip !important;
    white-space: normal !important;
  }
}

.reportModal__modal__select.rgPhn {
  display: flex;

  .ant-select-arrow {
    height: 14px;
  }

  @media (max-width: 485px) {
    flex-wrap: wrap;
  }

  gap: 10px;
  align-items: center;

  .ant-select-selection-search {
    top: 4px !important;
    margin-inline-start: 0 !important;
  }

  input {
    &:first-child {
      margin: auto;
      padding-top: 10px;
      padding-left: 5px;
    }
  }

  .input_textimg {
    max-width: 374px;
    width: 100%;

    @media (max-width: 480px) {
      max-width: 100%;
    }

    input {
      padding-top: 3px !important;
      padding-left: 10px;
    }
  }

  #rc_select_9 {
    padding-top: 12px;
  }
}

.ant-select-item-option-content {
  word-break: break-word !important;
  text-overflow: unset !important;
  overflow: auto !important;
  white-space: inherit !important;
}

.anticon.anticon-delete {
  background: #cf304a;
  border-radius: 100%;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  padding: 10px;
  transition: all 0.3s;

  @media (max-width: 480px) {
    font-size: 15px;
  }

  &:hover {
    background: #fff;
    color: #cf304a;
  }
}

.ordercompleted__orderballamy__chatbox {
  .uploadMain {
    padding: 0;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .reportModal__modal__upload {
    padding: 0;
    border: 0;
  }
}

.itme_selected {
  min-width: 140px;

  @media (max-width: 480px) {
    min-width: 100%;
  }
}

.label {
  color: var(--headerText);
  padding-bottom: 5px;
}

.phoneNum {
  margin-bottom: 10px;
}

.react-tel-input {
  margin-bottom: 12px;
}