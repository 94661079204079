@import "../../../../Assets/Styles/_mixins.scss";
@import "../../../../Assets/Styles/_vars.scss";

.marketupdate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  z-index: 9;
  background-color: var(--cardBackground);
  padding-bottom: 20px;

  @media (max-width: 1199px) {
    flex-direction: column;
  }

  @media (max-width: 767px) {
    padding-bottom: 0px;
  }

  h3 {
    @include fluid-type(320px, 1920px, 20px, 25px);
    font-weight: 600;
    margin: 0px;
    color: var(--headerText);
  }

  .inputLayout {
    min-width: 320px;
  }
}

.buySellTabsItem {
  .marketupdatetabs {
    margin-top: 15px;

    .ant-tabs-top {
      .ant-tabs-nav {
        z-index: 9;
        background: rgba(57, 39, 101, 0.059);
        margin: 0 !important;
        padding: 10px 40px !important;
        border-bottom: 1px solid var(--borderbotmcolor);
        box-shadow: 0px 0px 2px var(--borderclr);
        border-radius: 0;

        @media (max-width: 767px) {
          padding: 15px !important;
        }

        .ant-tabs-tab {
          background-color: transparent;
          padding: 0;

          &:hover {
            background-color: transparent !important;
            color: #1ea0ab !important;
          }
        }
      }

      .ant-tabs-tab-active {
        background: transparent !important;
        color: #1ea0ab !important;
        border-bottom: 1px solid #1ea0ab;
        border-radius: 0;
      }
    }

    .input_fields {
      background-color: var(--cardBackground);
      z-index: 9;
      box-shadow: 0px 0px 2px var(--borderclr);
      padding: 12px 40px;
      border-bottom: 1px solid #ffffff33;

      @media (max-width: 767px) {
        padding: 0 20px 20px 0;
        padding-top: 20px;
        gap: 3px;
      }

      .custom_inputfileds {
        .ant-row {
          align-items: end;
        }

        .bgcolorchnge {
          background-color: (--kycModalBg);
        }

        .input_textimg {
          max-width: 180px;
          width: 100%;

          input {
            border-radius: 24px !important;
            background-color: (--kycModalBg) !important;
            font-size: 12px;
            color: #000;
            font-weight: 600;
            border: 0px;

            &::placeholder {
              color: var(--textinputgrey);
              font-weight: 600;
            }
          }
        }

        .SelectOuter {
          .ant-select-selector {
            border-radius: 8px !important;
          }
        }

        .ant-select-selector {
          border: 0;
        }
      }

      .checkwithbtn {
        display: flex;
        gap: 20px;
        align-items: center;
        flex-wrap: wrap;

        .checkboxTxt {
          white-space: nowrap;
          font-size: 10px;
          font-weight: 600;
        }

        .custm_refresh {
          @media (max-width: 1199px) {
            margin-top: 20px;
          }

          // .buySellTabs {
          //   .buySellTabs__btns {
          //     background-color: transparent !important;
          //   }
          // }

          button {
            border-radius: 18px;
            max-width: 97px;
            align-items: center;
            padding: 5px 30px;
            margin: 0 4px;
            height: 40px;

            img {
              margin-bottom: 3px;
              padding-right: 5px;
            }
          }
        }
      }
    }
  }
}

.addRowmain {
  display: flex;
  max-width: 1366px;

  @media (max-width: 990px) {
    flex-wrap: wrap;
  }

  .topPaymentSec {
    background: var(--cardBackground);
    box-shadow: 0px 0px 12px #00000012;
    border-radius: 4px;
    opacity: 1;
    padding: 30px;

    @media (max-width: 767px) {
      padding: 15px;
    }
  }

  &__Innewarrior {
    padding: 15px;
    max-width: 70%;
    flex: 1 1 70%;

    @media (max-width: 991px) {
      width: 100%;
      max-width: 100%;
      flex: 1 1 100%;
      border: 0;
    }

    &__CryprtoWarrior {
      background-color: transparent;
      display: flex;
      align-items: center;

      p {
        @include fluid-type(320px, 1920px, 12px, 14px);
        color: var(--headerText);
        margin: 0px;
      }

      .orderCompletion {
        display: flex;
        margin: 0 0 0 6px;
        gap: 50px;
      }
    }

    &__AvialablePayment {
      display: flex;
      justify-content: space-between;

      align-items: baseline;
      gap: 15px;
      margin-top: 24px;

      @media (max-width: 767px) {
        flex-direction: column;
        margin-top: 20px;
        padding-left: 0;
      }

      .pymentLimit {
        p {
          color: var(--smalp);
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          gap: 10px;
          width: 100%;
          margin: 0;

          b {
            color: var(--headerText);
          }

          span {
            &.Buy {
              color: var(--custmgren);
            }

            &.Sell {
              color: #ff0000;
            }

            &:first-child {
              @include fluid-type(320px, 1920px, 14px, 16px);
            }
          }
        }
      }

      div {
        display: flex;
        width: 100%;

        @media (max-width: 767px) {
          flex: 1 1 100%;
          max-width: 100%;
          width: 100%;
        }

        @media (max-width: 575px) {
          flex-direction: column;
        }
      }

      .availableBTC {
        p {
          color: var(--greylightstext);
        }

        .payWithgoogle {
          display: flex;
          width: 100%;
          max-width: 100%;

          div {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            padding-left: 12px;

            button {
              height: 25px;
              max-width: fit-content;
              background-color: var(--selectFillBg);
              border: 0 !important;
              transition: 0.3s all;

              &:hover {
                background-color: $CommonButton;

                span {
                  color: var(--black) !important;
                }
              }

              span {
                color: var(--borderGray) !important;
                @include fluid-type(320px, 1920px, 10px, 12px);
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }

    .terms__Conditions {
      margin-top: 30px;

      @media (max-width: 767px) {
        padding: 0px;
        margin-top: 30px;
        margin-bottom: 20px;
      }

      p {
        @include fluid-type(320px, 1920px, 14px, 16px);
        font-weight: normal;
        margin-bottom: 8px;
        color: var(--smalp);
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

        &:first-child {
          color: var(--headerText);
          margin-bottom: 16px;
          font-weight: 600;
        }
      }
    }

    .payment_space {
      display: flex;
      gap: 10px;
      flex-flow: wrap;
      width: 100%;

      div {
        font-weight: normal;
        font-size: 14px;
        color: $baseblue;
        background: var(--resetBtn);
        border-radius: 8px;
        padding: 14px 24px;
        opacity: 1;
        text-wrap: wrap;
      }
    }
  }

  &__WanttoPay {
    max-width: 40%;
    flex: 1 1 40%;
    padding: 30px 24px;
    position: relative;
    background: var(--cardBackground);
    box-shadow: 0px 0px 12px #00000012;
    border-radius: 4px;
    opacity: 1;
    height: 509px;

    @media (max-width: 990px) {
      width: 100%;
      max-width: 100%;
      flex: 1 1 100%;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin: auto;
      padding: 20px;
    }

    .errormsg {
      position: absolute;
      font-size: 12px;
      top: 50px;
      text-align: end;
      color: red !important;
    }

    .errorfiat {
      top: 95px !important;
      color: #ff0000 !important;
    }

    .SelectOuter {
      margin: 8px 0px;
    }

    input {
      ::placeholder {
        @include fluid-type(320px, 1920px, 12px, 14px);
      }
    }

    p {
      margin: 0px;
      margin-bottom: 0px;
      color: var(--smalp) !important;
      @include fluid-type(320px, 1920px, 14px, 16px);
    }

    button {
      @include fluid-type(320px, 1920px, 10px, 12px);
    }

    .input_textimg {
      margin-bottom: 10px;
    }

    .input_textimg {
      padding-bottom: 10px;

      .input_amount {
        border: 1px solid #d6d6d6;

        &:hover,
        &:active {
          border: 1px solid $baseblue;
        }
      }
    }

    .customSelect.ant-select.option_select {
      border: 1px solid var(--lightgreytext) !important;
      border-radius: 30px !important;
    }
  }

  .warrior__Buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 40px;

    button {
      height: 45px;
      font-weight: 600;
    }

    btnCustom.ant-btn.custmgren {
      background-color: #ce261a;
      color: #fff;
      border: 0px !important;
    }
  }

  .cryWarrior {
    display: flex;
    align-items: center;
    margin-right: 20px;

    img {
      border-radius: 25px;
    }

    p {
      color: var(--textcolrlightblue);
    }
  }
}

.commonClass {
  p {
    color: var(--borderGray);
    font-size: 14px;
    font-weight: normal;
  }

  span {
    color: var(--custmgren);
    @include fluid-type(320px, 1920px, 20px, 24px);

    &:last-child {
      @include fluid-type(320px, 1920px, 12px, 14px);
    }
  }

  &__darkTxt {
    color: var(--black) !important;
    @include fluid-type(320px, 1920px, 14px, 16px);
    font-weight: normal;
    margin-left: 8px;
  }
}

.anchorBank {
  text-align: right;
  padding-top: 5px;

  a {
    color: var(--headerText);
  }
}

.method_selectpayment {
  margin-bottom: 10px !important;
}

.method_payment {
  color: var(--confirmmationtext);
  @include fluid-type(320px, 1920px, 12px, 16px);
  font-weight: 600;
}

.sell_button {
  color: red;
}

.buy_button {
  color: #28a745;
}

.extraUserInfo {
  margin-top: 10px !important;
}
