@import "../../Assets/Styles/_mixins.scss";

.languageDrop {
  top: 92px !important;
  z-index: 9 !important;

  position: fixed;

  @media (max-width: 767px) {
    position: relative;
    right: 30px;
  }

  .setting {
    ul {
      flex-direction: column !important;
      gap: 0px;
      align-items: flex-start !important;

      @media (max-width: 767px) {
        margin-left: auto;
        right: 10px;
      }

      li {
        margin-bottom: 12px;
        width: 100%;

        &:first-child {
          margin: auto;
          padding-bottom: 15px;
          margin-bottom: 15px;
          border-bottom: 1px solid var(--bordercolor);
        }

        &:last-child {
          margin-bottom: 0px;
          color: #FF060A;
        }
      }
    }

    .ant-avatar {
      background-color: var(--bguseravitr) !important;
      background: var(--bguseravitr) !important;
    }

  }
}

.languageMenu {
  ul {
    background-color: var(--profileBG);
    padding: 10px 0;
    list-style: none;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1607843137);
    border-radius: 10px;

    li {
      padding: 7px 20px;
      cursor: pointer;
      width: 150px;
      cursor: pointer;
      color: var(--headerText);

      &:hover {
        background-color: var(--hoverWhite);
      }
    }
  }
}

.drawerLinks {
  padding-left: 0;
  margin-top: 20px;

  @media (max-width:767px) {
    margin: 0;
    .paddingUpdated {
      padding: 10px 0;
      height: auto;
    }
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
}

.langaugeDrop {
  // background-color: transparent !important;
  // padding: 8px;
  // border-radius: 4px;
  // width: 40px;

  // @media (max-width:767px) {
  //   width: 30px !important;
  //   height: 30px !important;
  // }

  .ant-avatar-string {
    position: static !important;
  }
}

.settingDropDown {
  cursor: pointer;
  transition: 0.3s all;
  background: var(--cardBackground);
}

.ant-avatar {
  background: var(--mainColor) !important;

  @media (max-width: 767px) {
    font-size: 12px !important;
  }

  span {
    color: var(--btnColor);
  }
}

.notiListDiv {
  .notiList {
    color: var(--light-text);
    display: flex;
    gap: 12px;
    align-items: center;

    h4 {
      color: var(--light-text);
      font-weight: 600;
      @include fluid-type(320px, 1920px, 14px, 16px);
      text-align: left;
    }

    p {
      font-size: 14px;
      text-align: left;
      display: block;
      color: #897ea3;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }

  .viewAll {
    .themebtn {
      color: #fff;
      border-color: #40a9ff;
      background: var(--themebgbtn) !important;
      border-radius: 8px !important;
      transition: all 0.25s;
      box-shadow: unset;
      padding: 0px 40px;
      font-weight: 600;
      height: 40px !important;
    }

    text-align: center;
    margin: 20px 0px;
  }
}