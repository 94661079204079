@import '../../Assets/Styles/_mixins.scss';
@import '../../Assets/Styles/_vars.scss';

.footer {
    padding: 20px 10px;
    text-align: center;
    box-shadow: 0px -3px 12px #0000000A;
    margin-top: auto;
    border-top: 1px solid var(--sidebarbrdr);

    @media (max-width:767px) {
        display: none;
    }

    &__text {
        color: var(--headerText);
        font-size: 14px;
        font-weight: normal;

    }
}