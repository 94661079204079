.paymentmethod {
  .back {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    gap: 10px;
    align-items: center;

    p {
      margin-bottom: 0;
    }
  }

  .initialName {
    .alphabet {
      margin-bottom: 0;

      .joined {
        margin-bottom: 0;
      }
    }

    .blocks {
      align-items: center;
      display: flex;
      font-size: 12px;
      margin-left: 6px;
      padding: 4px 11px;
      height: 20px;
      border-radius: 2px;
      color: #cc9470;
      cursor: pointer;
    }

    .emailsmskyc {
      padding-left: 42px;
      color: var(--whiteblack);

      @media (max-width: 575px) {
        padding-top: 12px;
        padding-left: 0;
      }
    }

    .feedbackPos {
      p {
        color: gray;
      }
    }
  }

  .feedbackPos {
    max-width: 374px;
    width: 100%;

    p {
      color: #7e7878;
      margin-bottom: 10px;
    }

    background-color: var(--chartCardBG);
    border-radius: 5px;
    padding: 20px;

    .percentPosNeg {
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;
      grid-area: 10px;

      h4 {
        margin-bottom: 0;
        color: var(--whiteblack);
      }

      p {
        margin-bottom: 0;
      }

      .positandNeg {
        display: flex;
        gap: 20px;

        .datapos {
          display: flex;
          gap: 10px;

          span {
            background-color: green;
            width: 20px;
            border-radius: 5px;
            color: #fff;
            text-align: center;
          }
        }

        .dataneg {
          display: flex;
          gap: 20px;

          span {
            background-color: red;
            width: 20px;
            color: #fff;
            border-radius: 5px;
            text-align: center;
          }
        }

        .positiv {
          span {
            background-color: #3ded97;
            color: #ffffff;
            max-width: 20px !important;
            width: 100% !important;
            padding: 2px;
            border-radius: 4px;
            padding: 2px;
          }
        }

        .negativ {
          span {
            background-color: red;
            color: #fff;

            border-radius: 4px;
            max-width: 20px !important;
            width: 100% !important;
            padding: 2px;
          }
        }
      }
    }
  }

  .tradeInnerdata {
    border-top: none;

    .tradewithdata {
      display: flex;
      width: 100%;
      gap: 20px;

      @media (max-width: 991px) {
        flex-wrap: wrap;
      }

      > div {
        padding: 25px;
        border: 1px solid var(--borderclr);
        max-width: 250px;
        width: 100%;
        border-radius: 5px;

        @media (max-width: 589px) {
          max-width: 100%;
        }
      }

      .bysel {
        display: flex;
        gap: 4px;

        p {
          color: #3ded97;
        }

        span {
          color: red;
        }
      }
    }

    p {
      color: #a19c9c;
    }

    h5 {
      font-size: 18px;
      color: var(--whiteblack);
    }
  }
}

.pagination.tabLanding {
  margin-top: 30px;
  text-align: center;
}

.blockMessageDiv .paymentConformation__Upi {
  padding: 10px 20px 10px 30px;
  background: #e4e4e1;

  p {
    color: #1e252d !important;
  }

  .paymentConformation__Upi__idname p img {
    padding-right: 10px;

    .ant-radio-wrapper {
      width: 100%;
    }
  }
}

.reasonDropdowns .ant-radio-wrapper {
  width: 100%;
}

.back {
  cursor: pointer;
}
