@import '../../Assets/Styles/_mixins.scss';

.controlHeight {
  .ant-input-affix-wrapper>.ant-input {

    @include fluid-type(320px, 1920px, 12px, 14px);
  }

  .input_amount {
    background-color: var(--innerCard) !important;
  }

  input {
    height: 60px !important;
    margin-bottom: 0 !important;
    background-color: var(--innerCard);
    color: var(--inputtext);
    @include fluid-type(320px, 1920px, 12px, 14px);
    font-weight: 400;

    &::placeholder {
      color: var(--inputColor) !important;
    }
  }

  .input_custum {
    height: 60px !important;
    background-color: var(--innerCard);

    input {
      height: initial !important;
    }
  }
}

.spacbtncstm {
  margin-top: 15px;
}

.captcha {
  width: 100%;

  @media(max-width:575px) {
    transform: scale(0.7);
    display: flex;
    align-content: center;
    justify-content: center;
  }
}