@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

//Toggle Css//
.navbarStyle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toogleSwitchIcons {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  gap: 13px;
}

.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;

  .switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 30px;

    input {
      display: none;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      border-radius: 30px;
      transition: 0.4s;
      height: 30px;
      width: 55px;

      &:before {
        content: "";
        position: absolute;
        height: 22px;
        width: 22px;
        left: 4px;
        bottom: 4px;
        background-color: #02171d;
        border-radius: 50%;
        transition: 0.4s;
        box-shadow: 0px 0px 10px 0px #02171d99;
      }

      .icon {
        position: absolute;
        font-size: 14px;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        transition: color 0.4s;
        width: 100%;

        &:after {
          content: attr(data-icon);
        }
      }
    }

    input:checked+.slider {
      background-color: #35393a;

      &:before {
        transform: translateX(24px);
        background-color: #d1dbdf;
      }

      .icon {
        // left: 34px;
      }
    }
  }
}

.landingPage {
  display: flex;
  align-items: center;

  .responsive {
    gap: 15px;
  }
}

.landingExtraTabs ul {
  display: flex;
  gap: 20px;
  list-style: none;
  align-items: center;
  margin: 0;
  padding: 0 0 0px 10px;
}

.textBtn {
  border: none !important;
  color: var(--headerText) !important;
  margin: 0 !important;
  padding: 0;
  background: none !important;
  width: auto !important;
}

.textBtn:hover {
  font-weight: normal !important;
}

.drawerLogo {
  img {
    max-width: 100px;

    @media (max-width: 767px) {
      width: 75px;
    }
  }
}

.spanVal {
  color: #fff;
  font-size: 16px;
}

h2.ant-typography.title {
  color: var(--headerText);
  @include fluid-type(320px, 1920px, 20px, 41px);
  margin-bottom: 0 !important;
  word-spacing: normal;
  font-family: "F37 Incise", sans-serif;
    word-spacing: normal !important;
  font-weight: 600;

  span {
    color: #00bef2;
  }
}

.customCurrencyCard {
  .ant-card {
    background-image: linear-gradient(90deg, #00bef233 0%, #ffffff00 100%);
    box-shadow: 0px 3px 16px #00000017;
    border-radius: 4px;
    background-color: transparent;
    padding: 30px;

    .main {
      display: flex;
      align-items: center;
      justify-content: space-between;

      div.ant-space {
        h6 {
          @include fluid-type(320px, 1920px, 14px, 20px);

          span {
            @include fluid-type(320px, 1920px, 12px, 16px);
          }
        }

        * {
          color: white;
        }

        img {
          width: 80px;

          @media (max-width: 575px) {
            width: 50px;
          }
        }
      }

      div.ant-statistic-content {
        @include fluid-type(320px, 1920px, 12px, 16px);
      }
    }
  }
}

.provideHeading {
  max-width: 900px;
  width: 100%;
  margin: 0 auto !important;
}

.sidebarHeader {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;

  .textBtn {
    padding: 0 !important;
    line-height: 14px !important;
    height: auto !important;
  }

  .loginBtn,
  .registerBtn {
    min-width: 115px;
  }
}

.WithEase {
  padding-bottom: 20px;

  @media (max-width: 767px) {
    padding-bottom: 15px;
  }
}

.bannerSection {
  height: 100%;

  @media (max-width:991px) {
    max-width: 550px;
    width: 100%;
  }

  .slick-slider,
  .slick-list {
    height: 100%;
  }

  img {
    // height: 100%;
    // width: 100%;
    height: 100%;
    width: 100%;
    margin: 0px auto;
    object-fit: cover;

    @media (max-width:991px) {
      height: auto;
      width: 100%;
    }
  }

  .slick-dots li button {
    &::before {
      color: var(--dashtoggle) !important;
    }
  }

  .slick-dots li.slick-active button {
    &::before {
      color: var(--dashtoggle) !important;
    }
  }
}


.bannerSection {
  .slick-arrow.slick-prev {
    // position: absolute !important;
    // left: 10px !important;
    // z-index: 1;

    &::before {
      background-color: #30f1b6 !important;
    }
  }

  .slick-arrow.slick-next {

    // position: absolute !important;
    // right: 10px !important;
    // z-index: 1;
    &::before {
      background-color: #30f1b6 !important;
    }
  }

  .slick-slide {
    >div {
      height: 100% !important;

      >div {
        height: 100% !important;
      }
    }

    img {
      // height: 100%;
      // margin-top: 60px;/
    }
  }

  .slick-track {
    height: 100% !important;
  }
}
.landingAnimations{
  width: 58px;
  height: 58px;
}

.phoneLottie {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
}
.tokenJson {
  max-width: 530px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}