.marketupdatetabs,
.pyamentmethod,
.postWithTable,
.allNotification {
  .ant-select-item-option-content {
    overflow: visible;

    &:hover {
      color: var(--light-text);
    }
  }
}

.allNotification {
  .ant-select-arrow {
    right: -33px;
  }

  .rc-virtual-list-holder {
    overflow-y: unset !important;
  }
}

.ant-modal-title {
  text-transform: capitalize;
}

.loader_spinner {
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.srchBtn {
  padding: 9px 12px;
  border-left: 1px solid;
  border-color: var(--borderclr);
}

.dataField {
  box-shadow: 0px 3px 6px #00000014;

  border-radius: 4px;
  opacity: 1;
  padding-left: 40px !important;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    padding-left: 20px !important;
  }

  .input_custum {
    height: 40px !important;
  }

  .srchBtn {
    padding: 2px 15px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 2.1px 16px !important;
    height: auto !important;
  }

  .btnCustom.ant-btn.yellowBtn {
    border-radius: 4px;
    margin-bottom: 0px;
    max-width: 80px;

    img {
      margin-bottom: 4px;
    }
  }
}

.lightblue.handIcon {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.input-height-issue {
  .customSelect {
    padding: 3px 5px;
  }
}

.tabsMarket {
  .ant-tabs-tab-active {
    border-radius: 0px !important;
  }

  .ant-tabs-nav {
    // flex-direction: row-reverse;
    gap: 30px;

    @media (max-width: 575px) {
      gap: 10px;
    }

    .ant-tabs-nav-operations {
      button {
        @media (max-width: 575px) {
          padding: 0;
        }
      }
    }
  }

  .buySellTabs {
    // background-color: var(--homeCard);
    border-radius: 4px;
    padding: 3px;

    .buySellTabs__btns {
      margin-right: 0;
    }

    .buySellTabs__btns--active {
      border-radius: 4px !important;

      .sellred {
        background-color: var(--btnredact) !important;
      }
    }
  }
}

.verifiedUser {
  color: #cc9470;
}

.completedBar {
  width: 100%;
  min-width: 150px;
  padding-right: 40px;

  .ant-progress-text {
    color: var(--light-text);
  }
}