@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.cointdetailPage {
  padding: 44px 39px 50px 51px;

  @media (max-width: 1366px) {
    padding: 20px;
  }

  &_headBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 18px 40px 0px 50px;

    // @media (max-width: 1199px) {
    //   padding: 18px 40px 0px 20px;
    // }

    // @media (max-width: 767px) {
    //   padding: 15px;
    // }

    h3 {
      img {
        margin-right: 20px;
      }

      span {
        color: var(--headerText);
        @include fluid-type(320px, 1920px, 14px, 16px);
        // padding-left: 5px;
      }
    }

    button {
      border-radius: 25px;
      box-shadow: 0px 0px 6px #0000000f;
      height: 40px;
    }
  }

  &_tabs {
    // background-image: url(../../Assets/Images/bgImage.png),
    //   linear-gradient(
    //     120deg,
    //     rgba(0, 150, 189, 0.6) 25%,
    //     rgba(1, 53, 64, 0.6964986678265056) 100%
    //   );
    // background-repeat: no-repeat;
    // border-radius: 4px;
    // background-size: cover;
    // position: relative;
    // height: 140px;

    @media (max-width: 767px) {
      // padding-right: 25px;
    }

    // &:after {
    //   content: "";
    //   position: absolute;
    //   border-top: 1px solid $border-light-gray-F3F3F3;
    //   width: 100%;
    //   top: 62px;
    //   left: 0;
    //   @media (max-width: 1366px) {
    //     top: 59px;
    //   }
    //   @media (max-width: 768px) {
    //     top: 57px;
    //   }
    // }

    .mt {
      margin-top: 30px;
    }

    &_overView {
      color: red;

      &_graphsec {
        &_duration {
          align-items: center;
          justify-content: space-between;
          margin-bottom: 45px;
          padding: 36px 40px 0 40px;

          @media (max-width: 991px) {
            margin-bottom: 0;
          }

          @media (max-width: 768px) {
            padding: 10px 20px 0 20px;
          }

          h3 {
            @include fluid-type(320px, 1920px, 20px, 36px);
            color: var(--headerText);

            span {
              @include fluid-type(320px, 1920px, 22px, 28px);
            }

            sup {
              @include fluid-type(320px, 1920px, 16px, 20px); 
            }
          }

          .btngraph {
            display: flex;
            background-color: var(--daysBg);
            padding: 3px;
            border-radius: 4px;

            div {
              display: flex;
              // border: 1px solid $bordergrey;
              overflow: hidden;
              color: var(--mainColor);

              &:first-child {
                border-radius: 4px 0 0 4px !important;
              }

              &:last-child {
                border-radius: 0px 4px 4px 0px !important;
              }
            }

            .timeBtn {

              &:hover {
                background: var(--tabHover);
                color: $white;
                border: 0;
              }
            }

            .timeActive {
              color: var(--mainColor);

              background: var(--tabHover);
            }

            button {
              padding: 8px 12px;
              width: 100%;
              height: 100%;
              background-color: #1ea0ab05;
              color: var(--smalp);
              border: 0;

              &:active,
              &:hover {
                color: #1db5e2;
              }
            }
          }
        }

        &_graph {
          height: 221px;
          width: 100%;
        }

        h4 {
          @include fluid-type(320px, 1920px, 16px, 20px);
          font-weight: bold;
          color: var(--headerText);
          margin-bottom: 20px;
        }

        &_points {
          // border-bottom: 0.5px solid $border-light-gray-F3F3F3;
          // border-top: 0.5px solid $border-light-gray-F3F3F3;
          padding: 20px 40px;

          @media (max-width: 1366px) {
            padding: 20px;
          }

          @media (max-width: 767px) {
            padding: 20px;
          }
        }

        &_textline {
          padding: 20px 40px;

          @media (max-width: 767px) {
            padding: 20px;
          }

          h5 {
            color: var(--headerText);
            @include fluid-type(320px, 1920px, 12px, 16px);
          }

          p {
            padding-bottom: 30px;
            color: #939FA3;
            @include fluid-type(320px, 1920px, 12px, 14px);

            @media (max-width: 767px) {
              padding-bottom: 15px;
            }
          }

          a {
            display: block;
            max-width: 150px;
            margin: 16px 0;
            color: $baseblue;

            &:hover,
            &:focus {
              color: var(--textColor-80839E);
            }

            img {
              margin-right: 14px;
            }

            span {
              vertical-align: middle;
            }
          }
        }
      }
    }

    &_balance {
      h4 {
        @include fluid-type(320px, 1920px, 16px, 20px);
        font-weight: 600;
        color: var(--headerText) !important;

        span {
          color: var(--mainColor);
        }
      }

      &_flex {
        align-items: center;
        justify-content: space-between;
        padding: 16px 40px;
        border-bottom: 1px solid $border-light-gray-F3F3F3;

        &:last-child {
          border-bottom: none;
        }

        h5 {
          font-weight: 600;
          @include fluid-type(320px, 1920px, 12px, 16px);

          span {
            color: var(--textColor-80839E);
            @include fluid-type(320px, 1920px, 12px, 14px);
            font-weight: 400;
          }
        }

        &_main {
          display: flex;
          align-items: center;
          justify-content: space-between;

          img {
            padding: 0 20px;
          }

          @media (max-width: 768px) {
            // flex-direction: column;
          }
        }
      }
    }

    &_cardtab {
      @media (max-width: 1200px) {
        margin-top: 40px;
      }

      // @media (max-width: 767px) {
      //   margin-left: 15px;
      // }
    }
  }

  .ant-tabs {
    .ant-tabs-tab-btn {
      &:focus {
        color: #fff !important;
      }
    }
  }
}

.coin_bls {
  text-align: right !important;
}

.graph__section {
  padding: 0;

  text {
    fill: var(--headerText);
  }
}

.point {
  background-color: var(--createAccount);
  // margin: 20px 0;
  padding: 5px 0 6px 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 96px;
  border-radius: 4px;

  @media (max-width: 767px) {
    margin: 10px 0;
  }

  h6 {
    @include fluid-type(320px, 1920px, 12px, 14px);
    color: var(--headerText);
    white-space: nowrap;

    img {
      margin-left: 8px;
    }
  }

  &_mainText {
    @include fluid-type(320px, 1920px, 12px, 16px);
    font-weight: 500;
    color: var(--headerText);
    padding: 4px 0 4px 0;
  }
}

.paddingLeft {
  @media (max-width: 767px) {
    padding-left: 15px;
  }
}

.scrolledEleMob {
  overflow: auto;

  >div {
    min-width: 500px;
  }
}

#rc-tabs-1-tab-primaryBalance {
  color: red !important;
}

.primarybal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gridCard);
  padding: 19px 28px 18px 28px;
  border-radius: 7px;
  margin-top: 16px;

  p {
    color: var(--smalp);
    @include fluid-type(320px, 1920px, 12px, 14px);
    display: flex;
    font-weight: normal;
    flex-direction: column;

    span {
      @include fluid-type(320px, 1920px, 12px, 16px);
      color: var(--headerText);
      font-weight: 600;
    }
  }

  &_left {
    display: flex;
    gap: 20px;
  }

  &_right {
    .baseblue {
      color: var(--mainColor);
      @include fluid-type(320px, 1920px, 12px, 16px);
      display: flex;
      flex-direction: column;
      font-weight: 600;
    }
  }
}

.blueesback {
  .positive {
    color: #329879 !important;
    padding-left: 5px;
  }

  .negative {
    color: #ff4d4f !important;
    padding-left: 5px;
  }
}