@import "../../../../Assets/Styles/_mixins.scss";
@import "../../../../Assets/Styles/_vars.scss";

// .comman-innerpage-padding{

.ordercompleted {
  padding: 35px 50px;

  .container {
    max-width: 100%;
    padding: 20px 50px;
    box-shadow: 0 6px 33px rgba(0, 0, 0, 0.07);
    background: var(--seetingCardBg);
    border-radius: 4px;

    @media (max-width: 1280px) {
      padding: 25px 20px;
    }

    @media (max-width: 767px) {
      padding: 25px 20px;
    }
  }

  &__success {
    margin-bottom: 3px;
    opacity: 1;
  }

  &__orderballamy {
    display: flex;
    max-width: 100%;
    gap: 51px;
    padding-top: 43px;

    &__order {
      flex: calc(100% - 425px);

      @media (max-width: 420px) {
        width: 100%;
      }
    }

    &__chatbox {
      max-width: 425px;
      width: 100%;
      margin-bottom: 20px;
      @media (max-width: 991px) {
        max-width: 100%;
        width: 100%;
      }
    }

    @media (max-width: 991px) {
      flex-wrap: wrap;
    }

    &__order {
      h2 {
        @include fluid-type(320px, 1920px, 14px, 16px);
        color: var(--headerText);
        padding-left: 15px;
        margin-bottom: 10px !important;
      }
    }
  }

  .ancrType {
    color: $yellowtbdark;
    margin-top: 20px;
    cursor: pointer;
    @include fluid-type(320px, 1920px, 14px, 18px);

    &:hover {
      color: $CommonButton;
    }
  }
}

// }

.tradingExpSection {
  margin-top: 30px;
  padding: 0 15px;

  p {
    color: var(--whiteblack);
    margin-bottom: 10px;
  }

  .feedbackBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      max-width: 200px;
      width: 100%;
    }
  }

  .ant-checkbox-wrapper.checkboxCustom.undefined {
    margin-top: 10px;
  }
}

.expButtons {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  width: 300px;

  @media (max-width: 390px) {
    flex-direction: column;
    gap: 10px;
  }

  .ant-btn {
    background: transparent !important;
    color: var(--headerText);
    border: 1px solid var(--borderclr) !important;

    &:hover {
      color: var(--headerText);
    }
  }
}

#res-pos-positive {
  background: $greenTxt !important;
  color: $white;
  font-weight: 600;

  .fa {
    color: $white;
  }
}

#res-neg-negative {
  background: red !important;
  color: $white;
  font-weight: 600;

  .fa {
    color: $white;
  }
}

#res-pos-,
#res-neg- {
  color: var(--headerText) !important;
}

#res-positive {
  background: $greenTxt !important;
  color: $white;
  font-weight: 600;

  .fa {
    color: $white;
  }
}

#res-negative {
  background: red !important;
  color: $white;
  font-weight: 600;

  .fa {
    color: $white;
  }
}

#res- {
  color: var(--headerText) !important;
}

.leftIcon {
  position: relative;
  right: 6px;
}

.expButtons button {
  .ant-btn[disabled] {
    background: transparent !important;
    &:hover {
      background: transparent !important;
    }
  }

  width: 140px !important;
}
