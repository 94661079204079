@import "../../Assets/Styles/_mixins.scss";

.comingSoonNew {
  height: 100vh;
  &_content {
    background-image: url("../../Assets/Images/ComingSoon-BG.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 58px);
    padding: 15px;
    overflow-y: auto;
    img {
      @media (max-width: 767px) {
        max-width: 150px;
        width: 100%;
      }
    }
    &_data {
      text-align: center;
      h2 {
        color: #ffffff;
        @include fluid-type(320px, 1920px, 30px, 50px);
        font-family: "F37 Incise";
        text-transform: uppercase;
        font-weight: 500;
        margin: 50px 0 20px 0;
        line-height: 100%;
        @media (max-width: 767px) {
          margin: 30px 0 15px 0;
        }
      }
      h5 {
        color: #d9d9d9;
        @include fluid-type(320px, 1920px, 18px, 22px);
        font-family: "F37 Incise";
        font-weight: 500;
        line-height: 110%;
        max-width: 704px;
      }
      p {
        color: #d9d9d9;
        @include fluid-type(320px, 1920px, 16px, 20px);
        line-height: 110%;
        margin: 30px 0;
        @media (max-width: 767px) {
          margin: 15px 0;
        }
      }
      &_btnSec {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        flex-direction: column;
        .inputLayout {
          max-width: 368px;
          width: 100%;
          input {
            height: 50px;
            background-color: #0a2931;
            color: #ffffff;
            @media (max-width: 767px) {
              height: 40px;
            }
            &::placeholder {
              color: #465e65;
            }
          }
        }
        .ant-btn {
          height: 50px;
          @media (max-width: 767px) {
            height: 40px;
          }
        }
      }
    }
  }
  &_footer {
    background-color: #081519;
    border-top: 1px solid #2d4850;
    padding: 17px 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: 767px) {
      padding: 15px;
    }
    p {
      color: #939fa3;
      @include fluid-type(320px, 1920px, 12px, 14px);
      margin: 0;
      line-height: 100%;
    }
  }
  .ant-checkbox-wrapper {
    margin-top: 30px;
    .ant-checkbox-inner {
      border-color: #d1dadd !important;
    }
    span {
      color: #d9d9d9;
      @include fluid-type(320px, 1920px, 12px, 14px);
      line-height: 110%;
    }
  }
}
.comingSoonModal {
  .ant-modal-close {
    top: 10px;
    @media (max-width: 767px) {
      top: 0;
    }
  }
  .ant-modal-content {
    padding: 27px;
    max-width: 412px;
    @media (max-width: 767px) {
      padding: 16px;
    }
    h5 {
      color: #d1dadd;
      @include fluid-type(320px, 1920px, 16px, 20px);
      font-family: "F37 Incise";
      font-weight: 500;
      line-height: 120%;
    }
    .ant-modal-header {
      border-bottom: 1px solid #2b3e43;
      padding-bottom: 20px;
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 23px;
      h5 {
        margin: 20px;
      }
      .ant-btn {
        height: 50px;
        width: 100%;
        @media (max-width: 767px) {
          height: 40px;
        }
      }
    }
  }
}
