// @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../helvetica-now-display/HelveticaNowDisplay-Regular.woff") format("woff2"),
    url("../helvetica-now-display/HelveticaNowDisplay-Regular.woff") format("woff"),
    url("../helvetica-now-display/HelveticaNowDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$baseFontRegular: "Helvetica Now Display", sans-serif;
$baseFontBold: "Helvetica Now Display", sans-serif;
$baseFont: "Helvetica Now Display", sans-serif;
$baseFont2: "Helvetica Now Display", sans-serif;
// $white: #000000;
$grayLightTxt: #848e9c;
$lightbgyellow: #fff4c7;
$darktext: #329879;
$baseblue: rgba(48, 241, 182, 1);
$basecolor: rgba(48, 241, 182, 0.10);
$baseblue1: #4e85fb;
$base-hover: #40a9ff;
$bordergreen: #a5d9dd;
$bordergrey: #374a5e;
$border-FFFFFF33: #ffffff33;
$pendingtext: #e5862f;
$portfoliobg: #f7f9fd;
$borderdarkgrey: #80838e;
$bgderkgrey: #f3f3f3;
$grey-707070: #707070;
$text-80838E: #838990;
$bg-icon-80838E: rgba(128, 131, 142, 0.1);
$greenbuy: #40bdc6;
$redsell: #ef4c30;
$btnred: #ef4539;
$green-098551: #098551;
$tableborders: #00000012;
$bg-light-FBFCFF: #fbfcff;
$bg-EDF4F5: #edf4f5;
$bgrewardata: #f8f8f8;
$bg-DEEBF0: #deebf0;
$bgblue-ffffff4a: #ffffff4a;
$bg-light-gray-F3F3F3: #f3f3f3;
$dissableColor: #f3f3f3c9;
$xValueColor: #00bef2;
$xValueBorder: #e1f3f4;
$bg-light-blue-E9F5FF: #e9f5ff;
$bg-totalearning: #bdcef0;
$bg-FFFFFF9E: #ffffff;
$bg-totalearning1: #fafcff00;
$border-light-gray-F3F3F3: #f3f3f3; // Dual Theme control mode start
$staticBorder: $bordergrey;
$CommonButton: $baseblue;
$checkboxparagraph: black;
$portfolioHead: #3927650f;
$btnTextLight: $text-80838E;
$link: #014cec;
$lightblue-F5F8FF: #eefeff;
$white: white;
$white-FFFFFFCC: #ffffffcc;
$red: #cf0038;
$red-CF304A: #ff593d;
$tabletext: #1b1c1e;
$text00bef2: #00bef2;
$bg-DCEAEF: #dceaef;
$green: #12b764;
$tabletext: #1b1c1e;
$tabletext: #1b1c1e;
$bg-F3F2F6: #f3f2f6;
$blue-F1F5FF: #f1f5ff;
$blue-F3F7FF: #f3f7ff;
$greengradbtn: linear-gradient(104deg, #00bef2 0%, #008db4 47%, #005360 100%);
$redgradbtn: linear-gradient(100deg, #ed1e59 0%, #ef4b31 100%);
$bgblue: #30f1b6;
$invertbg: linear-gradient(122deg,
    rgba(0, 190, 242, 1) 0%,
    rgba(0, 141, 180, 1) 47%,
    rgba(0, 83, 96, 1) 100%);
$parameterbg: linear-gradient(180deg, #00bef233 0%, #ffffff00 100%);
$copytradebg: #e5edf4;
$redFF593D: #ff593d;
$gradientbtn: linear-gradient(113deg, #00bef2 0%, #008db4 47%, #005360 100%);
$blueEBF9FB: #ebf9fb;
$green129D26: #129d26;
$cardBg: #0f2237;

// from p2p

$borderinputcl: #d9d9d9;
$yellowtbdark: #1ea0ab;

$lightgreen: #50af95;
$reddata: #d96070;
$btnBaseColor: #0b0e11;
$greytabitme: #8f8f8f;
$baclgrpimdf8f8f9: #f8f8f9;
$titlehead: #1e252d;
$closeButton: #474d57;
$chatbtnbckg: #014cec09;
$selectf: #e2e2e2;
$makePayment: #fbe797;
$greenTxt: green;
$borderbotmcolor: #efefef;
$bordertdcolor: #e6dede;
$buttndiv: #67686d;
$borderright: #d6d6d6;
$questionbalance: #1ea0ab;
$textareacontent: #c9cdd4;
$sidebarRightBorder: #dcdcdc;
$yellowbtn: #014cec;
$custmgren: #1ea0ab;
$texttimerinner: #848e9c;
$sidebarRightBor: #252930;

$bgupiactive: #efefef;
$bgconfirmationupi: #efefef;
$confirmmationtext: #848e9c;
$btngreydffernt: #cad3df;
$lightgreytexting: #b4b4b4;
$greylightstext: #8e8c8c;
$textareaBottom: #848484;
$uploadproof: #676e75;
$uploadimg: #eef0f2;
$textcolrlightblue: #446e98;
$colorpagination: #bcbcbc;
$backlipagiantion: #f4f4f4;
$backbysec: #f8f8f8;
$textcolrconditions: #8e8c8c;
$textinputgrey: #9e9e9e;
$texttablehead: #06152b;
$borderD4D4D4: #d4d4d4;
$inputborder: #c4cddb;
$lightgreytext: $text-80838E;
$lightBtnAdv: #eaecef;
$lightDarkBtn: #e8e8e8;
$hoverBaseColor: #014cec;
$malicious: #014cec12;
$userchatcolor: #f6f6f6;
$lightGreenBg: #124234;
$yellowDarkBg: #014cec;
$chatborderOpen: #014cec;
$redTxt: #f6314c;
$redTxt2: #f4ccd2;
$tipscolor: #f6f6f6;
$transferleft: #014cec;
$white: #fff;
$boxshadowballamy: #00000029;
$kycModalBg: #f5f5f5;
$cameraSectionBorder: #babdc1;
$borderColor: #464e5c;
$ordercompar: #848e9c;
$traditionalTypes__red: #ea0070;
$traditionalTypes__green: #014cec;
$defeciencyTypes__red: #014cec;
$defeciencyTypes__green: #1f8df9;
$hoveredYellowedTxt: #014cec;
$hoveableHeaderBg: #dfe3ec;
$selectHoverableEle: #2c3037;
$headerDropdownBg: #fafafa;
$bordredImg: #dbdbdb;
$backgroundF8F8F8: #f8f8f8;
$listBorderBottom: #252930;
$borderGray: #b4b4b4;
$bgSection: #fafafa;
$whiteblackBase: #1e252d;
$sidebarActiveItem: #f2f3f5;
$scrollbarTrack: #0000000d;
$scrollbarThumb: #0000001a;
$black: #000;
$modalbgDark: #030303;
$modalbgLight: transparent linear-gradient(179deg, #ffffff 0%, #e6faff 100%) 0% 0%;
$lightBtnAdv: #eaecef;
$lightBtn: #e4e4e4;
$selectFillBg: #f5f5f5;
$texttimerheader: #848e9c;

// Dual Theme control mode start

// $themes: (
//   lightTheme: ("securityBtn": #dfe3ec,
//     "darkWhite": #000,
//     "darkWhiteReverse": #fff,
//   ),
//   darkTheme: ("securityBtn": #282c34,
//     "darkWhite": #fff,
//     "darkWhiteReverse": #000,
//   ),
// );